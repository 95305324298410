import {ChangeDetectorRef, Directive, Input, OnInit, Self} from '@angular/core';
import {AutoComplete} from 'primeng/primeng';
import {Observable, Subscription} from 'rxjs';
import {ChangeDetectorRefHelper} from '../helpers/change-detector-ref.helper';
import {map} from 'rxjs/operators';
import {GenericCrudService} from '../services/generic-crud.service';

@Directive({
  selector: '[appGenericAutoCompleteHandler]'
})
export class GenericAutocompleteHandlerDirective implements OnInit {

  @Input() public apiRoute: string = null;
  @Input() public labelField = 'name';
  @Input() public valueField = 'id';

  public subscriptions: Subscription[] = [];

  public autocomplete: AutoComplete = null;

  constructor(
    @Self() autocomplete: AutoComplete,
    public cdr: ChangeDetectorRef,
    protected genericCrudService: GenericCrudService
  ) {
    this.autocomplete = autocomplete;

    if (!(this.autocomplete instanceof AutoComplete)) {
      console.error('AutoCompleteDirective works only with p-autocomplete component!');
    }
  }

  public ngOnInit(): void {
    this.autocomplete.completeMethod.subscribe((event: any) => {
      this.onComplete(event);
    });
  }

  public onComplete(event): void {
    const originalEvent = event.originalEvent,
      query = event.query;

    if (this.apiRoute === null) {
      console.error('ApiRoute missing in Host component, pass it as @Input()!');
    }

    const requestParams = {search: query};

    this.subscriptions.push(
      this.genericCrudService.getEntities(this.apiRoute, '', requestParams)
        .pipe(map((paginated: {data: any[]}) => {
          const data: {label: string, value: any}[] = [];

          for (const item of paginated.data) {
            data.push({label: item[this.labelField], value: this.valueField ? item[this.valueField] : item});
          }

          return data;
      })).subscribe((data: any[]) => {
        this.autocomplete.suggestions = data;

        ChangeDetectorRefHelper.detectChanges(this);
      })
    );
  }
}
