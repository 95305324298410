<div #contentRendererContainer class="wrapper-container" *ngIf="module">
      <split (areaCollapsed)="onSplitLayoutChanged($event)" (dragEnd)="onSplitLayoutChanged($event)" *ngIf="module.moduleElements" [direction]="template.direction" [gutterSize]="template.gutterSize">
        <ng-template ngFor let-element [ngForOf]="module.moduleElements">
          <split-area class="overflow-y-hidden" [isCollapsed]="element.isCollapsed" [toggleDirection]="element.collapseDirection" [order]="element.orderPosition" [size]="element.percentageSize" [pixelSize]="element.pixelSize">
              <app-split-template
                appSeleniumDirective
                [masterElementContext]="masterElementContext" [isDialog]="isDialog" [moduleElement]="element" [entity]="entity" [isPart]="isPart" [masterEntity]="masterEntity" [masterEntityEditingField]="masterEntityEditingField" [masterField]="masterField" [masterFilterField]="masterFilterField" [masterFilterValue]="masterFilterValue" [justAFilter]="justAFilter"></app-split-template>
          </split-area>
        </ng-template>
      </split>
</div>

<ng-template [ngIf]="isPreview">
  <div class="preview-wrapper-container">
      <div class="wrapper">
        <split *ngIf="elements" [direction]="template.direction" [gutterSize]="template.gutterSize">
          <ng-template ngFor let-element [ngForOf]="elements">
            <split-area [order]="element.orderPosition" [size]="element.percentageSize" [pixelSize]="element.pixelSize">
                <app-split-template
                  appSeleniumDirective
                  [isDialog]="isDialog" [moduleElement]="element" [isPreview]="isPreview" [justAFilter]="justAFilter"></app-split-template>
            </split-area>
          </ng-template>
        </split>
      </div>
  </div>

</ng-template>
