<ng-template [ngIf]="mask">

  <div *ngIf="showIcon" class="ui-inputgroup" (click)="onClick($event)">
    <p-inputMask
      #inputMask
      (onFocus)="onFocus($event)"
      [disabled]="disabled"
      (keyup)="onMaskInputKeyUp($event)"
      [(ngModel)]="inputValue"
      [mask]="mask"
    ></p-inputMask>
    <button
      [disabled]="disabled"
      pButton
      type="button"
      icon="fa fa-calendar"
      class="ui-button-warn"
    ></button>
  </div>

  <p-inputMask
    *ngIf="!showIcon"
    #inputMask
    [disabled]="disabled"
    (onFocus)="onFocus($event)"
    (click)="onClick($event)"
    (keyup)="onMaskInputKeyUp($event)"
    [(ngModel)]="inputValue"
    [mask]="mask"
  ></p-inputMask>

</ng-template>

<ng-template [ngIf]="!mask">

  <div *ngIf="showIcon" class="ui-inputgroup" (click)="onClick($event)">
    <input
      #inputText
      (onFocus)="onFocus($event)"
      pInputText
      [disabled]="disabled"
      (keyup)="onInputKeyUp($event)"
      [(ngModel)]="inputValue"
    >
    <button
      [disabled]="disabled"
      pButton
      type="button"
      icon="fa fa-calendar"
      class="ui-button-warn"
    ></button>
  </div>

  <input
    *ngIf="!showIcon"
    #inputText
    pInputText
    [disabled]="disabled"
    (click)="onClick($event)"
    (onFocus)="onFocus($event)"
    (keyup)="onInputKeyUp($event)"
    [(ngModel)]="inputValue"
  >
</ng-template>

<p-overlayPanel
  appendTo="body"
  #overlay
>
  <p-calendar
    appCalendar
    [(ngModel)]="dateValue"
    (onInputChangeValid)="onInputChange($event)"
    (onSelect)="onDateSelect($event)"
    [dateFormat]="dateFormat"
    [showTime]="showTime"
    [timeOnly]="timeOnly"
    [placeholder]="placeholder"
    [showIcon]="showIcon"
    [disabled]="disabled"
    [yearRange]="yearRange"
    [monthNavigator]="monthNavigator"
    [yearNavigator]="yearNavigator"
    [hourFormat]="hourFormat"
    appendTo="body"
    [inline]="true"
    [view]="view"
    #inputElement
    inputStyleClass="inputStyleClass"
  >
    <p-footer>
      <div class="ui-datepicker-buttonbar ui-widget-header">
        <div class="ui-g">
          <div class="ui-g-12" style="text-align: center;">
            <button class="ui-button-secondary" pButton type="button" label="{{ 'COMMON.DATE.TODAY' | translate }}" (click)="onTodayButtonClick($event)"></button>
          </div>
        </div>
      </div>
    </p-footer>
  </p-calendar>
</p-overlayPanel>

