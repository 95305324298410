import {Component, Input, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';

import { environment } from 'environments/environment';

import { ElementButtonModule } from '../models/index';
import { ElementComponent } from './element.component';

import { FormService } from './../form.service';
import { FormViewerService } from './../form-viewer.service';
import {GenericDialogModuleService} from '../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-button-open-module',
  template: `
    <div class="button-wrapper">
      <button
        pButton
        appSeleniumDirective
        [element]="element"
        [disabled]="getIsDisabled()"
        [style.width]="getButtonWidth()"
        [iconUrl]="getButtonImageSource(element)"
        [label]="getButtonLabel(element.label)"
        (click)="onOpenModule()"
      ></button>
    </div>
  `,
  styles: [`
  :host .button-wrapper {
    padding: .5em;
  }
  `]
})
export class ElementButtonOpenModuleComponent extends ElementComponent implements OnInit, AfterViewInit {

  @Input() element: ElementButtonModule;

  iconBaseUrl: string = environment.baseUrl;

  showModuleInDialog = false;
  moduleId: number = null;
  masterEntity: any = null;
  masterEntityEditingField: any = null;
  masterFilterValue: any = null;
  masterFilterField = '';

  dialogWidth = 333;
  dialogHeight = 333;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected genericDialogModuleService: GenericDialogModuleService
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  onComponentDestroy() {

  }

  ngAfterViewInit() {
  }

  public getIsDisabled() {
    return this.element.disabled;
  }

  public getButtonWidth() {
    return this.element['width'] ? `${this.element['width']}%` : '';
  }

  public onOpenModule() {
    this.formService.getGenericCrudService().getEntities(`superadmin/modules/${this.element.openingModuleId}`).subscribe((targetModule) => {
      this.genericDialogModuleService.showDialog(targetModule,{
        height: this.element.dialogHeight,
        width: this.element.dialogWidth,
        masterEntity: this.entity,
        masterEntityEditingField: this.element.datamodelField,
        masterFilterField: this.element.masterFilterField,
        masterFilterValue: this.getFilterValue(),
        isAutocompleteModuleState: false,
        masterElementContext: this.getFormComponent() ? this.getFormComponent().getElementContext() : null
      });
    });

  }

  public getButtonImageSource(item) {
    const source = '';

    if (item.icon && item.icon.relativePath) {
      return environment.baseUrl + '/' + item.icon.relativePath;
    }

    // fallback for non-initialized icon items...
    if (item._embedded && item._embedded.icon && item._embedded.icon.relativePath) {
      return environment.baseUrl + '/' + item._embedded.icon.relativePath;
    }

    return source;
  }

  public getFilterValue() {
    return this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, this.element.staticFilterField, false, true);
  }

  public getButtonLabel(itemLabel) {
    if (itemLabel && itemLabel == '-') {
      return ' ';
    }

    return itemLabel;
  }
}
