import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ElementInputComponent } from './element-input.component';
import { ElementInputUpload } from '../models';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-upload',
  template: `
    <div class="form-element-input-upload simple-upload ui-simple-upload" *ngIf="!getFileUrl()">
      <input
        type="file"
        id="{{hashIdForUpload | genHash}}"
        (change)="onChangeFile($event)"
      />
      <label for="{{hashIdForUpload | genHash}}">
        <span class="fa fa-fw fa-upload"></span>
        <span class="upload-text">Durchsuchen</span>
      </label>
    </div>
    <div class="form-element-input-file-preview" *ngIf="getFileUrl()">
      <div class="form-element-input-file-link-container">
        <a [href]="getFileUrl()" target="_blank">{{getValue().fileName}}</a>
        <i class="fa fa-trash-o" aria-hidden="true" style="cursor: pointer;" (click)="removeFile($event)"></i>
      </div>
    </div>
  `,
  styles: [`
    ::ng-deep .form-element-input-file-preview {
      min-height: 25px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: center;
    }

    ::ng-deep .form-element-input-file-preview .form-element-input-file-link-container {
      order: 0;
      flex: 0 0 auto;
      align-self: auto;
    }
  `]
})
export class ElementInputUploadComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() element: ElementInputUpload;

  hashIdForUpload: string;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    private sanitizer: DomSanitizer,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.hashIdForUpload = `${new Date().getTime().toString(10)}fileUpload`;
    this.filterActionAndHandleIt('oninit');
  }

  onComponentDestroy() {

  }

  ngAfterViewInit() { }

  onChangeFile(event) {
    this.subscriptions.push(event.srcElement.files[0].toDataURL().subscribe((dataURL) => {
      this.formGroup.get(this.formControlName).setValue({ file: dataURL, fileName: event.srcElement.files[0].name });
      this.setValue(dataURL);
    }));
  }

  setValue(value: any, triggerChange: boolean = true, options: Object = {}, updateFormComponent = true, fromAction: string = '') {

    if (typeof (value) !== 'undefined') {
      this.formService.onFormElementValueChange({
        formControlValue: value,
        element: this.element,
        entityValue: value,
        formControlName: this.formControlName,
        formControlOptions: options,
        triggerChange: triggerChange,
        entity: this.entity,
        component: this,
        updateFormComponent: updateFormComponent,
        action: fromAction
      });
    }

    return this;
  }

  getFileUrl() {
    if (this.getValue()) {
      return this.sanitizer.bypassSecurityTrustUrl(this.getValue().file);
    }
  }

  removeFile(event) {
    event.preventDefault();
    if (this.getValue()) {
      this.formGroup.get(this.formControlName).setValue(undefined);
    }
  }
}
