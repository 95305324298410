export const enum ElementTypes {
  Grid = 2,
  Docuware = 12,
  NewGrid = 18,
  TreeGrid = 10,
  WorkHourGrid = 27,
  Chart = 34
}

export const enum ElementSubTypes {
  PieChart = 'Pie',
  LineChart = 'Line',
  BarChart = 'Bar',
  DonutChart = 'Donut'
}

export const ElementSubTypeMapping = {
  34: [
    ElementSubTypes.PieChart,
    ElementSubTypes.LineChart,
    ElementSubTypes.BarChart,
    ElementSubTypes.DonutChart
  ]
}


export class ElementType {
  id: number;
  value: number;
  name: string;
  label: string;

  constructor(id?: number, value?: number, name?: string, label?: string) {
    this.id = id;
    this.value = value;
    this.name = name;
    this.label = label;
  }
}
