<ng-container *ngIf="memoType === null">
  <div class="error-container">
    <div class="error-text">
      <div class="error-message">
        <b><span class="text-red">ERROR</span></b>
      </div>
      <div class="error-detail">
        <span>Memo Type is not defined!</span>
      </div>
    </div>
  </div>
</ng-container>

<app-generic-loading *ngIf="isLoadingData"></app-generic-loading>

<div class="ui-g" *ngIf="!isLoadingData">
  <div class="ui-g-12 ui-mg-d6 ui-lg-12 toolbar-container">
    <p-header *ngIf="element.topToolbarItems && element.topToolbarItems.length > 0" class="header">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [entity]="memo" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
    </p-header>
  </div>
</div>

<div class="main-container ui-g" *ngIf="!isLoadingData" [style.border-left]="getMemoBorderLeftColor()">

  <p-fieldset *ngIf="memoTypeFieldDefinitions && memoTypeFieldDefinitions.length > 0" legend="Zusatzfelder" class="fieldset">
    <ng-template ngFor let-memoTypeFieldDefinition [ngForOf]="memoTypeFieldDefinitions">
      <div class="label ui-g-12 ui-md-6 ui-lg-4">
        {{memoTypeFieldDefinition.name}}:
      </div>
      <div class="ui-g-12 ui-md-6 ui-lg-8" [ngSwitch]="memoTypeFieldDefinition?.dataType?.code">

        <ng-template ngSwitchCase="dropdown">
          <span *ngIf="!memoTypeFieldDefinition.lookupFetchDatamodel">
            No datamodel lookupFetchDataModel assigned...
          </span>

          <p-dropdown
            *ngIf="memoTypeFieldDefinition.lookupFetchDatamodel"
            [options]="lookupFetchDatamodelOptions[memoTypeFieldDefinition.id]"
            placeholder="Select entity"
            [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
            [showClear]="true"
            (onChange)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
            [style]="{'width':'100%'}"
          ></p-dropdown>
        </ng-template>
        <ng-template ngSwitchCase="dateTime">
          <app-generic-calendar
            (onInputChangeValid)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
            (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
            [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
            [yearRange]="'1950:2050'"
            [mask]="'99.99.9999 99:99'"
            [dateFormat]="'dd.mm.yy'"
            [showTime]="true"
            [style]="{'width':'100%'}"
          ></app-generic-calendar>
        </ng-template>
        <ng-template ngSwitchCase="checkbox">
          <p-checkbox
            [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
            (onChange)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
            binary="true"
          ></p-checkbox>
        </ng-template>
        <ng-template ngSwitchCase="text">
          <input
            [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
            (keyup)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
            type="text"
            pInputText
          />
        </ng-template>

        <ng-template ngSwitchDefault>
          No data type defined for {{memoTypeFieldDefinition.name}}!
        </ng-template>
      </div>
    </ng-template>
  </p-fieldset>

  <p-fieldset *ngIf="
    memo &&
    !isLoadingData &&
    memoType &&
    (memoType.doUseCustomerAddress ||
    memoType.doUseCustomerContactPerson ||
    memoType.doUseContactUser ||
    memoType.doUseEmployeeAddress ||
    memoType.doUseContactOffice)
  "
    legend="Sonderfelder"
  >
    <ng-template ngFor let-memoTypeFieldDefinition [ngIf]="memo && !isLoadingData">

      <div *ngIf="memoType.doUseCustomerAddress" class="label ui-g-12 ui-md-6 ui-lg-4">
        Kundenadressen:
      </div>
      <div *ngIf="memoType.doUseCustomerAddress" class="ui-g-12 ui-md-6 ui-lg-8">
        <p-autoComplete
          appAutocomplete
          appendTo="body"
          field="label"
          [dropdown]="true"
          [(ngModel)]="selectedCustomerAddress"
          [suggestions]="customerAddresses"
          (completeMethod)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'customerAddresses', 'fullAddress')"
          (onDropdownClick)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'customerAddresses', 'fullAddress')"
          (onSelect)="onMemoAutoCompleteChange($event, 'customerAddress')"
        ></p-autoComplete>
      </div>

      <div *ngIf="memoType.doUseCustomerContactPerson" class="label ui-g-12 ui-md-6 ui-lg-4">
        Ansprechpartner Kunde:
      </div>
      <div *ngIf="memoType.doUseCustomerContactPerson" class="ui-g-12 ui-md-6 ui-lg-8">
        <p-autoComplete
          appAutocomplete
          appendTo="body"
          field="label"
          [dropdown]="true"
          [(ngModel)]="selectedContactPerson"
          [suggestions]="contactPersons"
          (completeMethod)="onMemoAutoCompleteSearch($event, 'phoenix/contactpersons', 'contactPersons', 'firstName,lastName')"
          (onDropdownClick)="onMemoAutoCompleteSearch($event, 'phoenix/contactpersons', 'contactPersons', 'firstName,lastName')"
          (onSelect)="onMemoAutoCompleteChange($event, 'contactPerson')"
        ></p-autoComplete>
      </div>

      <div *ngIf="memoType.doUseContactUser" class="label ui-g-12 ui-md-6 ui-lg-4">
        Kontakt Person:
      </div>
      <div *ngIf="memoType.doUseContactUser" class="ui-g-12 ui-md-6 ui-lg-8">
        <p-autoComplete
          appAutocomplete
          appendTo="body"
          field="label"
          [dropdown]="true"
          [(ngModel)]="selectedContactUser"
          [suggestions]="contactUsers"
          (completeMethod)="onMemoAutoCompleteSearch($event, 'app/users', 'contactUsers', 'firstName,lastName')"
          (onDropdownClick)="onMemoAutoCompleteSearch($event, 'app/users', 'contactUsers', 'firstName,lastName')"
          (onSelect)="onMemoAutoCompleteChange($event, 'contactUser')"
        ></p-autoComplete>
      </div>

      <div *ngIf="memoType.doUseEmployeeAddress" class="label ui-g-12 ui-md-6 ui-lg-4">
        Personaladdresse:
      </div>
      <div *ngIf="memoType.doUseEmployeeAddress" class="ui-g-12 ui-md-6 ui-lg-8">
        <p-autoComplete
          appAutocomplete
          appendTo="body"
          field="label"
          [dropdown]="true"
          [(ngModel)]="selectedEmployeeAddress"
          [suggestions]="employeeAddresses"
          (completeMethod)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'employeeAddresses', 'fullAddress')"
          (onDropdownClick)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'employeeAddresses', 'fullAddress')"
          (onSelect)="onMemoAutoCompleteChange($event, 'employeeAddress')"
        ></p-autoComplete>
      </div>

      <div *ngIf="memoType.doUseContactOffice" class="label ui-g-12 ui-md-6 ui-lg-4">
        Kontakt-Filiale:
      </div>
      <div *ngIf="memoType.doUseContactOffice" class="ui-g-12 ui-md-6 ui-lg-8">
        <p-autoComplete
          appAutocomplete
          appendTo="body"
          field="label"
          [dropdown]="true"
          [(ngModel)]="selectedContactBranchOffice"
          [suggestions]="contactBranchOffices"
          (completeMethod)="onMemoAutoCompleteSearch($event, 'app/branchoffices', 'contactBranchOffices', 'city,street')"
          (onDropdownClick)="onMemoAutoCompleteSearch($event, 'app/branchoffices', 'contactBranchOffices', 'city,street')"
          (onSelect)="onMemoAutoCompleteChange($event, 'contactBranchOffice')"
        ></p-autoComplete>
      </div>

    </ng-template>
  </p-fieldset>

</div>
