
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from '../../../../../node_modules/rxjs';
import {AbstractEntityFileTypeService} from './abstract-entity-file-type.service';
import {
  ToolbarItemWordExport
} from '../../../shared/content-renderer/elements/generic-toolbar/items/components/word-export/toolbar-item-word-export.component';
import {GenericCrudService} from '../../../shared/services/generic-crud.service';
import {FileData} from '../file-data';

@Injectable()
export class PrintTemplateEntityFileTypeService extends AbstractEntityFileTypeService {

  public constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  public getFile(entity: any, params?: any): Observable<FileData> {
    const url = ToolbarItemWordExport.WORD_EXPORT_URL + params.datamodelId + '/file/'
      + params.customerId + '/report/' + entity.id + '/' + this.getFileType(entity);

    return this.genericCrudService.customPost(url,{'placeholders': params.placeholders}).pipe(map((response) => {
      return {
        fileName: response.fileName,
        filePath: this.getFileType(entity) === 'docx' ? response.file : response.pdf,
        fileType: this.getFileType(entity)
      };
    }));
  }

  public getFileType(entity: any): string|null {
    const reportDocumentName = entity.reportDocumentName;
    const reportDocumentNameParts = reportDocumentName.split('.');

    return reportDocumentNameParts[reportDocumentNameParts.length - 1];
  }
}
