import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';

import { ElementInputComponent } from './element-input.component';
import { ElementInputCheckbox } from '../models';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-checkbox',
  template: `
    <div [formGroup]="formGroup">
      <p-checkbox
        *ngIf="!this.element.isNullable"
        appSeleniumDirective
        [element]="element"
        binary="true"
        [formControlName]="formControlName"
        (onChange)="onElementValueChange($event)"
        [hidden]="this.element.isHidden"
      ></p-checkbox>
      <p-triStateCheckbox
        appSeleniumDirective
        [element]="element"
        *ngIf="this.element.isNullable"
        binary="true"
        [formControlName]="formControlName"
        (onChange)="onElementValueChange($event)"
        [hidden]="this.element.isHidden"
      ></p-triStateCheckbox>
    </div>
  `,
  styles: [`
    :host > div {
      display: inline-block;
    }

    :host > div > p-checkbox ::ng-deep .ui-chkbox {
      padding-top: .48em;
      padding-bottom: .48em;
    }
  `]
})
export class ElementInputCheckboxComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputCheckbox;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');

    if (this.element) {
      if (this.element.value) {
        this.onActionSelect();
      } else {
        this.onActionUnselect();
      }
    }
  }

  onComponentDestroy() {

  }

  onComponentChanges(): void {
    this.setFormControlName();
    this.setupValue();
  }

  ngAfterViewInit() {
  }

  public setupValue(): void {
    if (this.entity && typeof this.entity[this.element.datamodelField] === 'undefined') {
      this.setupDefaultValue();
    }
  }

  public onElementValueChange(event) {
    let value = event;

    if (typeof event === 'object') {
      value = event.value;
    }

    this.setValue(value);

  }

  protected setupDefaultValue(): void {
    if (this.entity && this.element.defaultValue) {
      const value = this.element.defaultValue === '1' ? true : false;

      this.setValue(value, false);
    }
  }
}
