import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ElementComponent } from './element.component';
import { ElementLayoutColumnsItem} from '../models';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-layout-column',
  template: `
    <div fxLayout="row" fxLayoutAlign="start stretch" (click)="onClickElement($event)">
      <div *ngFor="let elementChild of element.children" [fxFlex]="getColumnWidth(elementChild)">
        <app-form-element-layout-columns-item
          [(actionParams)]="actionParams"
          [(selectedElement)]="selectedElement"
          [(showDialogAddAddress)]="showDialogAddAddress"
          [form]="form"
          [formGroup]="formGroup"
          [element]="elementChild"
          [moduleElement]="moduleElement"
          [entity]="entity"
          [editMode]="editMode"
        ></app-form-element-layout-columns-item>
    </div>
  </div>
  `
})

export class ElementLayoutColumnsComponent extends ElementComponent implements OnInit, AfterViewInit {
  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  onComponentDestroy() {

  }

  ngAfterViewInit() {
  }

  getColumnWidth(elementChild: ElementLayoutColumnsItem): string {
    const columnWidth = elementChild.columnWidth;

    if (!columnWidth) {
      return undefined;
    }

    let unit = columnWidth.replace(new RegExp(/[0-9]*/g), '');
    const value = +columnWidth.replace(unit, '');

    if (!unit) {
      unit = '%';
    }
    return `${value}${unit}`;
  }
}

