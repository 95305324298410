/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./company-search-grid/company-search-grid.component.ngfactory";
import * as i3 from "./company-search-grid/company-search-grid.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../core/message/message-growl.service";
import * as i6 from "../services/generic-crud.service";
import * as i7 from "../content-renderer/services/entity-sharing.service";
import * as i8 from "./services/company-communication.service";
import * as i9 from "../services/api.builder.service";
import * as i10 from "../services/request-caching.service";
import * as i11 from "../../core/authentication/authentication.service";
import * as i12 from "./company-search.component";
var styles_CompanySearch = [i0.styles];
var RenderType_CompanySearch = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanySearch, data: {} });
export { RenderType_CompanySearch as RenderType_CompanySearch };
export function View_CompanySearch_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ui-grid ui-grid-responsive ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "company-search-grid", [], null, null, null, i2.View_CompanySearchGrid_0, i2.RenderType_CompanySearchGrid)), i1.ɵdid(4, 245760, null, 0, i3.CompanySearchGrid, [i4.TranslateService, i5.MessageGrowlService, i6.GenericCrudService, i7.EntitySharingService, i8.CompanyCommunicationService, i9.ApiBuilderService, i10.RequestCachingService, i11.AuthenticationService, i1.ChangeDetectorRef], { gridComponent: [0, "gridComponent"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridComponent; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CompanySearch_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "company-search", [], null, null, null, View_CompanySearch_0, RenderType_CompanySearch)), i1.ɵdid(1, 245760, null, 0, i12.CompanySearch, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanySearchNgFactory = i1.ɵccf("company-search", i12.CompanySearch, View_CompanySearch_Host_0, { gridComponent: "gridComponent" }, {}, []);
export { CompanySearchNgFactory as CompanySearchNgFactory };
