
import {debounceTime} from 'rxjs/operators';
import {AbstractGridGeneralColumnComponent} from '../abstract-grid-general-column.component';
import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {EntityHydrator} from '../../../../../services/entity-hydrator.service';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {NumberHelper} from '../../../../../helpers/number.helper';
import {Constants} from '../../../../../../constants';
import {ModuleElementColumn} from '../../../../../services/module/module-element-column';
import {EventHelper} from '../../../../../helpers/event.helper';

@Component({
  selector: 'app-decimal-column',
  templateUrl: './decimal-column.component.html',
  styleUrls: ['./decimal-column.component.scss']
})
export class DecimalColumnComponent extends AbstractGridGeneralColumnComponent implements OnInit {

  @Input() column: ModuleElementColumn = null;

  private _entity: any;
  @Input() public set entity(entity: any) {
    this._entity = entity;

    if (this._entity && this.column) {
      this.initValue();
    }
  }
  public get entity(): any {
    return this._entity;
  }

  public keyUp = new Subject<any>();
  public keyDown = new Subject<any>();
  public blur = new Subject<any>();
  public focus = new Subject<any>();

  public displayValue = '';
  public textMask: any;
  private decimalSymbol = ',';
  private thousandsSeparatorSymbol = '.';
  private value = 0;

  constructor(
    protected permissionService: PermissionService,
    private entityHydrator: EntityHydrator
  ) {
    super();
  }

  ngOnInit() {
    this.keyUp.pipe(
      debounceTime(500))
      .subscribe((event: any) => {
        this.onInputKeyUp(event);
      });

    this.keyDown
      .subscribe((event: any) => {
        if (event.code === 'Tab' || EventHelper.isEnterPressed(event)) {
          this.onInputValueChange(event);
        }
      });

    this.blur.pipe(
      debounceTime(10))
      .subscribe((event: any) => {
        this.onInputBlur(event);
      });

    this.focus.pipe(
      debounceTime(10))
      .subscribe((event: any) => {
        this.onInputBeforeFocus(event);
        this.onInputFocus(event);
      });

    this.textMask = this.getTextMask();

    this.initValue();
  }

  initValue() {
    const value = this.entityHydrator.getEntityPropertyValue(this.entity, this.column['property']);
    if (Number(value)) {
      this.value = Number(value);
    }
    this.displayValue = this.getNumberString(this.value);
  }

  getTextMask() {
    const inputFormat = this.getInputFormat();

    return {mask: createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: true,
      decimalLimit: inputFormat.number.decimalLimit || Constants.DEFAULT_DECIMAL_PLACES,
      requireDecimal: inputFormat.number.requireDecimal,
      allowNegative: inputFormat.number.allowNegative,
      decimalSymbol: this.decimalSymbol,
      thousandsSeparatorSymbol: this.thousandsSeparatorSymbol
    })};
  }

  onInputKeyUp(event): void {
    this.value = this.getNumberValue(event.target.value);
    // this.displayValue = this.getNumberString(this.value);
    this.onKeyUp.emit({target: {value: this.value}, originalEvent: event});
  }

  onInputBlur(event): void {
    this.value = this.getNumberValue(event.target.value);
    this.displayValue = this.getNumberString(this.value);
    this.onBlur.emit({target: {value: this.value}, originalEvent: event, changeEvent: {
      name: 'inputBlur'
    }});
  }

  onInputValueChange(event): void {
    this.value = this.getNumberValue(event.target.value);
    this.displayValue = this.getNumberString(this.value);
    this.valueChange.emit({target: {value: this.value}, originalEvent: event, changeEvent: {
      name: 'inputValueChange'
    }});
  }

  public onInputBeforeFocus(event): void {
    this.onBeforeFocus.emit({target: {value: this.value}, originalEvent: event});
  }

  onInputFocus(event): void {
    const value = this.entityHydrator.getEntityPropertyValue(this.entity, this.column['property']);
    if (Number(value)) {
      this.value = Number(value);
    }
    this.displayValue = this.getNumberString(this.value);
    this.onFocus.emit({target: {value: this.value}, originalEvent: event});
  }

  private getNumberValue(value) {
    const inputFormat = this.getInputFormat();

    return NumberHelper.getNumberFromString(
      value,
      this.decimalSymbol,
      this.thousandsSeparatorSymbol,
      inputFormat.number.suffix,
      inputFormat.number.prefix
    );
  }

  private getNumberString(value) {
    const inputFormat = this.getInputFormat();

    return NumberHelper.formatNumber(
      value,
      this.decimalSymbol,
      this.thousandsSeparatorSymbol,
      inputFormat.number.allowDecimal ? inputFormat.number.decimalLimit : Constants.DEFAULT_DECIMAL_PLACES,
      inputFormat.number.suffix,
      inputFormat.number.prefix
    );
  }

  private getInputFormat(): any {
    return this.column && this.column.field && this.column.field.inputFormat ? this.column.field.inputFormat : {
      number: {
        active: true,
        prefix: '',
        suffix: '',
        allowDecimal: false,
        decimalLimit: 2,
        integerLimit: null,
        requireDecimal: true,
        allowNegative: true
      }
    };
  }
}
