import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {GenericGridRemoteFilterService} from '../generic-grid/services/generic-grid-remote-filter.service';
import {GenericGridGlobalFilterService} from '../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridColumnBuilderService} from '../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridBulkSaveService} from '../generic-grid/services/generic-grid-bulk-save.service';
import {GenericGridEntityService} from '../../services/generic/entity/generic-grid-entity.service';
import {GenericElementInlineEditorService} from '../../services/generic/generic-element-inline-editor.service';
import {GenericTurboGridLayoutService} from './service/generic-turbo-grid-layout-service';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {EntityDirtyStoreService, StoreType} from 'app/shared/content-renderer/services/entity-dirty-store.service';
import {EntityChangedMeta, EntityData, EntityDataChangeMeta, EntityDataStoreService} from '../../services/entity-data-store.service';
import {ToolbarItemCheckService} from 'app/shared/content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {RequestCachingService} from 'app/shared/services/request-caching.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {MessageGrowlService} from 'app/core/message/message-growl.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {DoubleClickService} from '../../services/double-click.service';
import {FieldActionsService} from 'app/shared/content-renderer/services/field-actions.service';
import {LayoutService} from '../../../services/layout-service';
import {LocationService} from '../../../services/location.service';
import {ElementsStackService} from '../../services/elements-stack.service';
import {ElementsStateService} from '../../services/elements-state.service';
import {EntityFactoryService} from 'app/shared/services/entity-factory.service';
import {ConfirmationService} from 'primeng/primeng';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {HttpErrorResponseService} from 'app/shared/services/http-error-response-message.service';
import {EventHandlerService} from '../../../../core/events/event/event-handler.service';
import {EntityHydrator} from 'app/shared/services/entity-hydrator.service';
import {GenericGridInitEvent} from '../generic-grid/events/event/generic-grid-init-event';
import {GenericElementFilterService} from '../../services/generic/filter/generic-element-filter.service';
import {FieldMetadataGrid} from 'app/shared/services/module/module-element-field-metadata-grid';
import {Debounce} from 'app/shared/helpers/debounce';
import {ExecutorService} from 'app/core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator} from '../../../validators/services/entity-validator';
import {ExecutionStepBuilderService} from '../../../../core/executor/builder/execution-step-builder.service';
import {LocalStorageDataService} from '../../../services/local-storage-data.service';
import {CancelComponentChangesService} from 'app/shared/content-renderer/services/cancel-components-changes.service';
import {ExecutorActionsService} from '../../../../core/executor/service/executor-actions/executor-actions.service';
import {JobContainerService} from '../../../../core/job-runner/job-container.service';
import {ContentRendererComponent} from '../../content-renderer.component';
import {GenericDialogModuleService} from '../generic-dialog/service/generic-dialog-module.service';
import {CustomButtonCheckFactoryService} from '../generic-grid/services/custom-button-check/custom-button-check-factory.service';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {GenericElementEmbeddedService} from '../../services/generic/generic-element-embedded.service';
import {GenericElementContextMenuService} from '../../services/generic/generic-element-context-menu.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {GenericGridSingleEntitySaveService} from '../generic-grid/services/generic-grid-single-entity-save.service';
import {GenericGridLayoutService} from '../generic-grid/services/generic-grid-layout.service';
import {ElementContext, ElementType, MasterEntityConfig, PerformedAction} from '../../services/ElementContext';
import {AbstractGenericGridComponent} from '../abstract-generic-grid.component';
import {EntityStatus} from '../../../services/entity/entity-status';
import {Guid} from 'guid-typescript';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {cloneDeep} from 'lodash';
import {Observable, of as observableOf, throwError as observableThrowError} from 'rxjs';
import {EventHelper} from '../../../helpers/event.helper';
import {Constants} from '../../../../constants';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {ActionsEvent} from '../../../services/action/action';
import {RunnableEventRegistry} from '../../../../core/job-runner/type/runnable-event.registry';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {EventActionsSubscribeResponse} from '../../../../core/events/interfaces/event-actions-subscribe-response';
import {Element} from '../../../services/element/element';
import {ModuleElement} from '../../../services/module/module-element';
import {ExecutionStatus} from '../../../../core/executor/execution-status';
import {ElementState} from '../../services/element-state';
import {TableReadColumnComponent} from './column/read/table-read-column.component';
import {TableEditColumnComponent} from './column/edit/table-edit-column.component';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../../core/service/user-session.service';

@Component({
  selector: 'app-generic-turbo-grid',
  styleUrls: ['./generic-turbo-grid.component.scss'],
  templateUrl: './generic-turbo-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    GenericTurboGridLayoutService,
    ExecutorService,
    GenericGridSingleEntitySaveService,
    GenericGridLayoutService
  ]
})
export class GenericTurboGridComponent extends AbstractGenericGridComponent {

  @Input() element: Element;
  @Input() moduleElement: ModuleElement;
  @Input() parentComponent: GenericTurboGridComponent;
  @Input() isPart = false;
  @Input() isSlave = false;
  @Input() isInSubView = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  @Input() justAFilter?: any;

  @ViewChildren(TableReadColumnComponent) readColumnComponents: TableReadColumnComponent[];
  @ViewChildren(TableEditColumnComponent) editColumnComponents: TableEditColumnComponent[];

  @Input() set masterField(masterField: string) {
    this.masterEntityField = masterField;
  };

  @ViewChildren(ContentRendererComponent) set expanderContainers(expanderContainers: QueryList<any>) {
    this.genericGridLayoutService.fixScrollHeightAndWidthOfExpanders(expanderContainers);
  };

  public elementType: ElementType = ElementType.Grid;
  public showDetailedOverview = false;
  public gridFilters: { [s: string]: any; } = {};
  public totalCount = 0;

  public sortField = 'id';
  public defaultSortField = 'id';
  public sortDirection = Element.SORT_DIRECTION_ASC;
  public defaultSortDirection = 1;

  public contextMenuFake = { show: () => {}};

  public defaultPageSize = 25;
  public currentOffset = 0;
  public currentPage = 0;
  public firstEntry = 0;
  public dateRangeMinValue: number = (new Date).getFullYear() - 25;
  public dateRangeMaxValue: number = (new Date).getFullYear() + 25;
  public dateRangeValues: number[] = [];
  public moduleElementTargetElement;
  public emptyEntity: any = {};
  public emptyEntityInitDone = false;
  public gridState: ElementState;

  protected newEntityAddStarted = false;
  protected toolbarContextName = 'gridComponent';
  protected gridMessage = '';
  protected updateRequested = false;

  constructor(
      protected componentService: ComponentService,
      protected viewContainerRef: ViewContainerRef,
      protected modulesStateService: ModulesStateService,
      protected genericGridColumnBuilderService: GenericGridColumnBuilderService,
      protected genericGridBulkSaveService: GenericGridBulkSaveService,
      protected entityDirtyStore: EntityDirtyStoreService,
      protected entityDataStore: EntityDataStoreService,
      protected toolbarItemCheckService: ToolbarItemCheckService,
      protected requestCachingService: RequestCachingService,
      protected genericCrudService: GenericCrudService,
      protected messageGrowlService: MessageGrowlService,
      protected permissionService: PermissionService,
      protected genericGridRemoteFilterService: GenericGridRemoteFilterService,
      protected doubleClickService: DoubleClickService,
      protected fieldActionsService: FieldActionsService,
      protected genericGridGlobalFilterService: GenericGridGlobalFilterService,
      protected elementRef: ElementRef,
      protected renderer: Renderer2,
      protected layoutService: LayoutService,
      protected locationService: LocationService,
      protected elementsStackService: ElementsStackService,
      protected elementStateService: ElementsStateService,
      protected entityFactory: EntityFactoryService,
      protected confirmationService: ConfirmationService,
      protected translationService: TranslateService,
      protected logger: LoggerService,
      protected httpErrorResponseService: HttpErrorResponseService,
      protected eventHandlerService: EventHandlerService,
      protected genericElementEntityService: GenericGridEntityService,
      protected genericElementInlineEditorService: GenericElementInlineEditorService,
      protected genericElementFilterService: GenericElementFilterService,
      protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
      protected entityHydrator: EntityHydrator,
      protected cancelComponentChangesService: CancelComponentChangesService,
      protected executorService: ExecutorService,
      protected entityValidator: EntityValidator,
      public genericGridLayoutService: GenericTurboGridLayoutService,
      protected executionStepBuilderService: ExecutionStepBuilderService,
      protected localStorage: LocalStorageDataService,
      protected executorActionsService: ExecutorActionsService,
      protected jobContainerService: JobContainerService,
      protected genericDialogModuleService: GenericDialogModuleService,
      protected customButtonCheckFactoryService: CustomButtonCheckFactoryService,
      protected stepsFactory: ExecutionStepFactoryService,
      protected embedded: GenericElementEmbeddedService,
      protected contextMenu: GenericElementContextMenuService,
      protected entityManager: EntityManagerService,
      protected genericGridSingleEntitySaveService: GenericGridSingleEntitySaveService,
      protected userSession: UserSessionService,
      public cdr: ChangeDetectorRef
    ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      genericGridColumnBuilderService, genericElementEntityService, genericElementInlineEditorService,
      permissionService, genericGridBulkSaveService, entityDirtyStore, entityDataStore,
      requestCachingService, toolbarItemCheckService, eventHandlerService, confirmationService, translationService,
      genericElementFilterService, cancelComponentChangesService, executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, elementStateService, genericCrudService, fieldActionsService,
      userSession, executionStepBuilderService, layoutService,
      genericDialogModuleService, genericGridRemoteFilterService, embedded, contextMenu, entityManager, jobContainerService,
      cdr
    );
  }

  public getToolbarExtraParams() {
    return {
      'gridComponent': this
    };
  }

  public ngOnInit() {
    super.ngOnInit();

    this.returnState();
    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.removeState();
  }

  public onComponentInit() {
    this.genericGridSingleEntitySaveService.setComponent(this);
    this.showGridEmptyMessage();
    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    this.dateRangeValues = [this.dateRangeMinValue, this.dateRangeMaxValue];

    this.assignSubscriptions();
    this.elementContext = this.createContext();
    this.runJobs(RunnableEventRegistry.ContextCreated);

    const state = this.componentState;

    const stateSortField = state ? state.sortField : null;
    const stateSortDirection = state ? state.sortDirection === 'ASC' ? 1 : -1 : null;

    this.defaultSortField = stateSortField || this.element.sortField || this.sortField;

    if (stateSortDirection !== null) {
      this.defaultSortDirection = stateSortDirection;
    } else {
      this.defaultSortDirection = (stateSortDirection !== null ? stateSortDirection : null) || this.element.sortDirection === 'ASC' ? 1 : -1 ||
        this.sortDirection === 'ASC' ? 1 : -1;
    }

    this.initColumns();
    if (this.moduleElement.isPermissionAware) {
      this.addPermissionsColumns();
    }
    this.initToolbarItems();
    if (this.element.pageSize) {
      this.defaultPageSize = this.element.pageSize;
    }

    if (this.element.datamodel && !this.hasMasterElement() && !this.element.isPaginable) {
      this.subscriptions.push(
        this.loadEntities().pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    }

    if (this.element.datamodel && this.hasMasterElement()) {
      const context = new JobContext();
      context.identifier = Guid.create().toString();
      context.component = this;
      context.event = RunnableEventRegistry.PostInit;

      this.jobContainerService.runRelevantJobs(context);
    }

    this.eventHandlerService.handle(new GenericGridInitEvent(this))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: EventActionsSubscribeResponse) => {

      });

    this.executeAction(ExecutorActionEvent.Init, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public onColumnResize(event: any) {
  }

  public onEntitySelected(originalEvent = null, fieldKey = null, userTrigger: boolean = false): void {

    if (userTrigger) {
      event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

      this.executeAction(ExecutorActionEvent.Click, this)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }

    this.selectEntity(this.selectedEntity);

    this.triggerFieldActions(originalEvent, fieldKey, ActionsEvent.Click);
    this.triggerSlaves(this.selectedEntity);

    this.notifySlaves(ExecutorActionEvent.EntityChanged)
      .notifyMaster(ExecutorActionEvent.EntityChanged);

    this.executeAction(ExecutorActionEvent.EntityChanged, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public onGridFilter(event: any, dt: any): void {
    // xcentric, change every grid to local
  }

  public setEmptyEntity() {
    if (!this.emptyEntityInitDone) {
      this.subscriptions.push(
        this.entityFactory
          .buildComponentEntity(this).pipe(
          takeUntil(this.unsubscribe))
          .subscribe((entity) => {
            this.emptyEntity = entity;
            this.setEmptyEntityToSlaves(this.emptyEntity);
          })
      );
    }

    this.emptyEntityInitDone = true;
  }

  public hasCustomButton(entity: any, column: any) {
    let isVisible = false;

    if (column.field.hasCustomButton) {

      const checker = this.customButtonCheckFactoryService.instance(this, entity, column);

      isVisible = checker.isVisible();
    }

    return isVisible;
  }

  public onRowCustomButtonClick(event: any, column: any): void {
    const field: FieldMetadataGrid = column.field;

    this.triggerFieldActions(event, field.id, ActionsEvent.CustomButtonClick);
  }

  public selectFirstEntity(): this {

    if (this.entities.length > 0) {
      const entity = this.entities[0];

      this.selectEntity(entity).onEntitySelected();
    }

    return this;
  }

  public selectLastSelectedEntity(): this {
    const entity = this.entities.find((foundEntity) => { return this.selectedEntity && this.selectedEntity.id === foundEntity.id; });

    if (entity) {
      this.selectEntity(entity).onEntitySelected();
    }

    return this;
  }

  public onGridRendered() {
    this.setGridScrollHeightAndWidth();
  }

  public onRowDblclick(event: any) {
    this.executeAction(ExecutorActionEvent.DoubleClick, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    if (!this.elementContext.isDialog) {
      // onDoubleClick needs some refactoring, remove field double click, it will no longer exist... will be moved to field actions
      this.doubleClickService
        .setDialogOptions({
          height: +this.moduleElement.onDoubleClickTargetDialogHeight,
          width: +this.moduleElement.onDoubleClickTargetDialogWidth,
          isModal: this.moduleElement.isDoubleClickTargetDialogModal
        })
        .setGrid(this)
        .onDoubleClick(event);
    }

    this.triggerFieldActions(event, null, ActionsEvent.DoubleClick);
  }

  public onEntityClick(entity): void {
    this.setSelectedEntity(entity);

    setTimeout(() => {
      this.genericGridLayoutService
        .setRenderer(this.renderer)
        .setElementRef(this.elementRef)
        .setGrid(this)
        .adaptRowsColor();
    }, 30);
  }

  public selectEntity(entity): this {
    this.selectedEntity = entity;

    this.componentService.highlightEntity(this.selectedEntity);

    // Now let's announce this on the context as well:
    this.elementsStackService.setSelectedEntity(this.moduleElement.id, entity);

    return this;
  }

  public addCreatedEntityDraft(entity: any): this {
    if (!entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
      entity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
    }

    this.finishNewEntityAdd(entity);

    return this;
  }

  public onBeforeSelect(previousEntity, currentEntity) {

    if (previousEntity && !previousEntity.id &&
      currentEntity[EntityStatus.ENTITY_DRAFT_FLAG] !== previousEntity[EntityStatus.ENTITY_DRAFT_FLAG] &&
      !this.entityDirtyStore.isDirty(previousEntity) && this.getCreatedEntities(true).length === 1
    ) {
      this.removeEntity(previousEntity);
    }

    // In case specified to not show the changes dialog - just skip:
    if (!this.moduleElement.isChangesCheckActivated) {
      return;
    }

    const isAnotherEntitySelected = previousEntity && currentEntity[EntityStatus.ENTITY_DRAFT_FLAG] !==
      previousEntity[EntityStatus.ENTITY_DRAFT_FLAG];

    if (isAnotherEntitySelected && (this.entityDirtyStore.isDirty(previousEntity) || this.slavesHaveChanges())) {
      this.shelveSlaveChanges();

      if (this.moduleElement.isAutoSaveActivated) {
        this.genericGridSingleEntitySaveService.save(previousEntity)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe();
      } else {
        this.confirmationService.confirm({
          acceptVisible: true,
          rejectVisible: true,
          header: this.translationService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_SAVE_QUESTION_HEADER'),
          message: this.translationService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_SAVE_QUESTION_BODY'),
          icon: 'fa fa-trash',
          accept: () => {
            this.genericGridSingleEntitySaveService.save(previousEntity)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe();
          },
          reject: () => {
            this.genericGridSingleEntitySaveService.cancel(previousEntity)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe();
          }
        });
      }
    }
  }

  public loadGridLazy(event) {
    let observableLoad = null;
    if (typeof this.element.autoloadData === 'undefined' || this.element.autoloadData === null || this.element.autoloadData === true) {
      observableLoad = this.loadEntities();
    }
    this.defaultPageSize = event.rows;
    this.currentOffset = event.first;

    if (event.sortField) {
      this.sortField = event.sortField;
    }

    if (event.sortOrder) {
      this.sortDirection = (+event.sortOrder === 1) ? Element.SORT_DIRECTION_ASC : Element.SORT_DIRECTION_DESC;
    }

    this.gridFilters = event.filters;

    if (observableLoad instanceof Observable) {
      this.subscriptions.push(
        observableLoad.pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    }
  }

  public loadEntities(): Observable<any> {
    if (this.element && this.element.datamodel) {
      return Observable.create((observer) => {
        this.doLoadEntities(this.getElementDataModelApiRoute());

        observer.next();
        observer.complete();
      });
    }

    return observableOf(null);
  }

  public onRowSelect(event) {
    event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

    Debounce.isDoubleClick((isDoubleClick: boolean) => {
      if (isDoubleClick) {
        this.onRowDblclick(event);
      } else {
        this.onEntitySelected(event, null, true);
      }

      this.genericGridLayoutService
        .setRenderer(this.renderer)
        .setElementRef(this.elementRef)
        .setGrid(this)
        .adaptRowsColor();
    }, 300);
  }

  public onEntityChangeInit(changeData): void {
    event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

    const entity = changeData['data'],
      fieldKey = changeData['field']['id'];

    this.executeAction(ExecutorActionEvent.BeforeInlineEditInit, this).subscribe();

    if (this.selectedEntity && entity['id'] !== this.selectedEntity.id) {
      this.onBeforeSelect(this.selectedEntity, entity);

      this.selectEntity(entity).onEntitySelected(null, fieldKey);
    }

    setTimeout(() => {
      this.genericGridLayoutService
        .setRenderer(this.renderer)
        .setElementRef(this.elementRef)
        .setGrid(this)
        .adaptRowsColor();
    }, 30);
  }

  public onContextMenuSelect(event): void {
    if (event.data) {
      this.selectEntity(event.data);
    }

    setTimeout(() => {
      this.genericGridLayoutService
        .setRenderer(this.renderer)
        .setElementRef(this.elementRef)
        .setGrid(this)
        .adaptRowsColor();
    }, 30);
  }

  public setMasterFilterState(state: boolean) {
    const filterKey = this.genericGridGlobalFilterService
      .setGrid(this)
      .getFilterKeyInGridContext(
        Constants.SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY,
        this.getElementDataModelApiRoute()
      );

    this.userSession.set(filterKey, {
      value: state
    });
  }

  public getGlobalFilters(): Object {
    return this.genericGridGlobalFilterService.setGrid(this).getGridFilters(this.getElementDataModelApiRoute());
  }

  public getMasterFilterState(): boolean {
    return this.genericGridGlobalFilterService
      .setGrid(this)
      .getGlobalFilterValue(Constants.SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY, null, this.getElementDataModelApiRoute()) || false;
  }

  public adaptRowsColors(): void {
    setTimeout(() => {
      this.genericGridLayoutService
        .setElementRef(this.elementRef)
        .setRenderer(this.renderer)
        .setGrid(this)
        .adaptRowsColor();

      this.onEntitiesChanged();

      ChangeDetectorRefHelper.detectChanges(this);
    }, 5);
  }

  protected assignSubscriptions() {
    this.subscriptions.push(
      this.entityDataStore.entitiesChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((entries) => {
        if (this.updateRequested && this.getElementDataModelApiRoute() === entries['apiRoute'] && this.checkCurrentModule()) {
          this.updateRequested = false;
          this.handleLoadedEntities(entries);

          this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
            .notifyMaster(ExecutorActionEvent.EntitiesChanged);

          this.executeAction(ExecutorActionEvent.EntitiesChanged, this).subscribe();

          this.toolbarItemCheckService.check(this);
        }
      }),
      this.entityDataStore.entityDeleted$.pipe(takeUntil(this.unsubscribe)).subscribe((entity) => {
        if (entity && this.getElementDatamodelEntityName() === entity['fqn']) {
          this.subscriptions.push(
            this.loadEntities().subscribe()
          );
        }
      }),
      this.entityDataStore.entityChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((meta: EntityChangedMeta) => {
        if (meta.entity) {
          if (this.getElementDatamodelEntityName() === meta.entity['fqn']) {
            this.reselectEntity();
          } else {
            if (!this.elementContext.isSlaveContext()) {
              this.subscriptions.push(
                this.loadEntities()
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe(() => {
                  if (!this.selectedEntity && this.entities.length > 0 && this.elementContext.getSlaveElementContexts().length > 0) {
                    this.selectFirstEntity();
                  }
                })
              );
            }
          }
        }

        this.toolbarItemCheckService.check(this);
      }),
      this.layoutService.layoutSizeChanged$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
        this.setGridScrollHeightAndWidth();
      })
    );

    this.assignEntityValueChangesSubscription();
  }

  protected assignEntityValueChangesSubscription(): void {
    this.subscriptions.push(
      this.entityDataStore.entityValueChanged$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((entityDataChangeMeta: EntityDataChangeMeta) => {
        if (this.getElementDatamodelEntityName() === entityDataChangeMeta.entity['fqn'] &&
          (!this.selectedEntity || entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]
            === this.selectedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {

          this.genericElementInlineEditorService.markForCheck(this.selectedEntity);

          this.onChange(entityDataChangeMeta)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((entity) => {
            this.onValidate()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((status: ExecutionStatus) => {
              this.executeAction(ExecutorActionEvent.EntityValidated, {
                component: this,
                entityValidationStatus: status
              })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe();
            });
          });
        }
      })
    );
  }

  protected createContext() {
    const isSlave = this.moduleElement && typeof this.moduleElement.master !== 'undefined'
      && this.moduleElement.master !== null && this.moduleElement.master.id > 0;

    const isSubView = this.locationService.hasParam('parent-module')
      || (this.locationService.hasParam('id') && this.locationService.hasParam('master-entity'));

    const elementContext = new ElementContext(
      this.moduleElement.id,
      this.elementType,
      this,
      this.moduleElement,
      !isSlave,
      isSlave,
      !isSlave,
      isSubView,
      this.isPart,
      null,
      this.masterElementContext,
      null,
      null,
      null,
      this.isDialog,
      this.moduleElement.isMaster
    );

    if (isSubView) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.locationService.getParam('id');
      elementConfig.name = this.locationService.hasParam('master-entity')
        ? this.locationService.getParam('master-entity') : this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now, some kinky stuff - parts in dialogs - hit it:
    if (this.isDialog && isSubView && this.selectedMasterEntity && this.moduleElement.masterFilterField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    if (this.selectedMasterEntity && this.masterEntityField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.masterEntityField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.setSelectedMasterEntity(this.selectedMasterEntity).addMasterEntity(elementConfig);
    }

    if (this.masterFilterField && this.masterFilterValue) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.masterFilterValue;
      elementConfig.name = this.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  public startNewEntityAdd(entity?: any): void {
    this.newEntityAddStarted = true;

    entity = entity || this.emptyEntity;

    this.finishNewEntityAdd(entity);
  }

  protected finishNewEntityAdd(entity) {
    const newEntity = cloneDeep(entity);

    newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();

    this.entityFactory.assignMasterEntities(newEntity, this.getElementContext().getMasterEntities());

    this.entities = [newEntity, ...this.entities];

    this.onBeforeSelect(this.selectedEntity, newEntity);
    this.selectEntity(newEntity).onEntitySelected();

    if (this.element.isInlineEditable) {
      const grid = this;
      setTimeout(function() { grid.elementRef.nativeElement.querySelector('.ui-editable-column').click(); }, 1);
    }

    this.newEntityAddStarted = false;

    this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
      .notifyMaster(ExecutorActionEvent.EntitiesChanged);

    this.executeAction(ExecutorActionEvent.AfterNewEntityAdded, {
      component: this,
      entity: newEntity
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
    this.executeAction(ExecutorActionEvent.EntitiesChanged, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    // Now attach the new entity to the parent, if needed:
    this.embedToParent(newEntity);

    this.adaptRowsColors();
  }

  protected setGridScrollHeightAndWidth() {
    this.genericGridLayoutService
      .setGrid(this)
      .setElementRef(this.elementRef)
      .setRenderer(this.renderer)
      .setScrollHeight()
      .setScrollWidth();
  }

  protected onEntitiesChanged(): void {

  }

  public returnState(): void {
    this.componentState = this.elementStateService.findById(this.moduleElement.id);

    if (this.componentState) {
      this.gridState = this.componentState;
      this.defaultPageSize = this.componentState.pageSize;
      this.gridFilters = this.componentState.filters;
      this.firstEntry = this.componentState.pageSize * this.componentState.currentPage;
      this.currentPage = this.componentState.currentPage;
      this.dataLoaded = this.componentState.dataLoaded;
      this.simpleSearchFilters = this.componentState.simpleSearchFilters;
      this.defaultSortField = this.componentState.sortField || this.defaultSortField;
      this.defaultSortDirection = this.componentState.sortDirection === Element.SORT_DIRECTION_ASC ? 1 : -1 || this.defaultSortDirection;
    }
  }

  public removeState(): void {
    const elementContext = this.elementsStackService.findById(this.moduleElement.id);

    if (elementContext.performedAction !== PerformedAction.Close
      && elementContext.performedAction !== PerformedAction.Back) {
      // Now create a state of the component and add it:
      const componentState =
        new ElementState(this.moduleElement.id, ElementType.Grid,
          this.moduleElement, false, Math.floor(this.currentOffset / this.defaultPageSize),
          this.defaultPageSize, this.gridFilters, this.selectedEntity, this.dataLoaded, this.simpleSearchFilters,
          this.sortField, this.sortDirection);
      const newEntities = this.entityDirtyStore.getAll(StoreType.Create, this.getElementDatamodelEntityName());
      for (const createdEntity of newEntities) {
        componentState.addCreatedElement(createdEntity);
      }

      this.elementStateService.remove(componentState).add(componentState);
    }

    this.elementsStackService.remove(elementContext);

    this.executeAction(ExecutorActionEvent.Destroy, this).subscribe();
  }

  protected getFieldOnDoubleClick(event: any): FieldMetadataGrid | null {
    let field = null,
      columnIndex = event.originalEvent.target.cellIndex || undefined;

    // output span
    if (typeof columnIndex === 'undefined' && event.originalEvent.target.hasAttribute('columnIndex')) {
      columnIndex = event.originalEvent.target.getAttribute('columnIndex');
    }

    const column = this.columns[columnIndex];

    if (column) {
      field = this.findField(column.id);
    }

    return field;
  }

  private changeGridEntities(entities: any[] = [], count: number = 0) {
    this.entities = entities;
    this.totalCount = count;

    const isMasterFilterEnabled = this.getMasterFilterState();

    if (this.entities instanceof Array) {
      for (const entity of this.entities) {
        if (!isMasterFilterEnabled) {
          this.entityFactory.assignMasterEntities(entity, this.elementContext.getMasterEntities());
        }
      }
    }

    this.addShelved();

    this.layoutService.onLayoutSizeChanged();

    if (this.entities.length > 0 && !this.newEntityAddStarted) {
      if (this.selectedEntity && this.findEntity(this.selectedEntity)) {
        this.selectLastSelectedEntity();
      } else if (this.moduleElement.selectFirst) {
        this.selectFirstEntity();
      }

      this.onValidate()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }

    this.executeAction(ExecutorActionEvent.Load, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  private setEmptyEntityToSlaves(emptyEntity: any): void {
    for (const slaveContext of this.elementContext.getSlaveElementContexts()) {
      if (slaveContext.type === ElementType.Form) {
        slaveContext.component.emptyEntity = this.emptyEntity;
      }
    }
  }

  private shelveSlaveChanges(): void {
    const slaveComponents = this.elementContext.getSlaveElementContexts();
    for (const slaveComponent of slaveComponents) {
      if (slaveComponent.type === ElementType.Grid || slaveComponent.type === ElementType.Tree) {
        slaveComponent.component.shelveChanges();
      }
    }
  }

  private embedToParent(entity: any) {
    if (this.getElementContext().isSlave && this.getElementContext().getMasterElementContext()) {
      const component = this.getElementContext().getMasterElementContext().component;

      if (component instanceof AbstractGenericGridComponent && this.getEntityCollectionName()) {
        component.embedEntity(entity, this.getEntityCollectionName());
      }
    }
  }

  private checkMasterEntities() {
    let masterEntityConfigThere = true;
    if (this.hasMasterElement()) {
      masterEntityConfigThere = this.elementContext.findMasterEntity(this.moduleElement.masterFilterField) != null
        || !this.elementContext.masterElementContext
        || !this.elementContext.masterElementContext.moduleElement.selectFirst;
    }

    return masterEntityConfigThere;
  }

  protected doLoadEntities(apiRoute: string) {
    const grid = this;

    this.updateRequested = true;

    if (!this.checkMasterEntities()) {
      setTimeout(function() { grid.layoutService.onLayoutSizeChanged(); }, 1);
      return;
    }

    if (Object.keys(this.emptyEntity).length === 0) {
      this.setEmptyEntity();
    }

    this.isDataLoading = true;
    ChangeDetectorRefHelper.detectChanges(this);

    if (this.element.isPaginable) {
      apiRoute = apiRoute + '/offset/' + this.currentOffset
        + '/limit/' + this.defaultPageSize + '/orderby/'
        + this.sortField + '/' + this.sortDirection.toLowerCase();
    }

    if (this.getElementContext().getMasterElementContext()
      && EventHelper.isCustomEvent(event, Constants.EVENT_CLICK_GRID_ENTITY_SELECTED)) {
      this.setMasterFilterState(this.getElementContext().getMasterElementContext().component.selectedEntity === null);
    }

    this.shelveChanges();
    this.executeAction(ExecutorActionEvent.BeforeLoad, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
      return this.entityDataStore
        .getAll(apiRoute, this.getRemoteDynamicFilters(), this.element.isPaginable ? true : false).pipe(
          takeUntil(this.unsubscribe),
          catchError((response) => {
            this.entities = [];

            const errors = this.httpErrorResponseService.getErrors(response);

            this.showGridErrorsMessage(errors);

            this.isDataLoading = false;

            return observableThrowError(response);
          }), map((entityData: EntityData) => {
            this.gridMessage = this.translationService.instant('COMMON.NO_RECORDS_FOUND');

            this.showGridEmptyMessage();

            this.isDataLoading = false;
            this.dataLoaded = true;

            return entityData;
          }))
        .subscribe((entries) => {
          if (this.updateRequested) {
            this.updateRequested = false;
            this.handleLoadedEntities(entries);

            this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
              .notifyMaster(ExecutorActionEvent.EntitiesChanged);

            this.executeAction(ExecutorActionEvent.EntitiesChanged, this)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe();

            this.toolbarItemCheckService.check(this);
          }
        });
    });
  }

  public getRemoteDynamicFilters(): Object {
    let remoteFilters = {};

    this.genericElementFilterService.setComponent(this).setupDefaultFilters();

    const showWithoutMasterFilter = this.getMasterFilterState();

    for (const masterElementConfig of this.elementContext.getMasterEntities()) {
      // skip master filter field
      if ((showWithoutMasterFilter && masterElementConfig.filterType === 'masterEntity' &&
        this.moduleElement.masterFilterField &&
        masterElementConfig.name === this.moduleElement.masterFilterField)
        || (remoteFilters[masterElementConfig.name] && !masterElementConfig.value)
      ) {
        continue;
      }

      remoteFilters[masterElementConfig.name] = masterElementConfig.value;
    }

    remoteFilters['embedded'] = this.embedded.getEmbeddedAsString(this);

    const groupByFieldNames = this.genericGridColumnBuilderService.getGroupByFieldNames();
    if (groupByFieldNames.length > 0) {
      remoteFilters['groupBy'] = groupByFieldNames.join(',');
    }

    for (const staticFilter of this.staticFilters) {
      remoteFilters[staticFilter.field] = staticFilter.value;
    }

    for (const simpleFilter of this.simpleSearchFilters) {
      remoteFilters[simpleFilter.field] = simpleFilter.value;
    }

    if (this.sortField === 'Selection' && this.selectedEntities) {
      const sel = this.selectedEntities.map(function (item) {
        return item.id;
      });
      remoteFilters = Object.assign(remoteFilters, {'orderType': sel.join(',')});
    }

    if (this.justAFilter && this.justAFilter.name && this.justAFilter.value) {
      remoteFilters[this.justAFilter.name] = this.justAFilter.value;
    }

    remoteFilters = Object.assign(remoteFilters,
      this.genericGridRemoteFilterService
        .setGrid(this)
        .getModuleElementFilters(this.moduleElement)
    );
    remoteFilters = Object.assign(remoteFilters, this.genericGridRemoteFilterService.setGrid(this).getFilters(this.gridFilters));
    remoteFilters = Object.assign(remoteFilters, this.getGlobalFilters());

    return remoteFilters;
  }

  private showGridErrorsMessage(errors: string[]): void {
    this.gridMessage = errors.join();

    this.changeGridEmptyMessageColor('red');
  }

  private showGridEmptyMessage(): void {
    this.gridMessage = this.translationService.instant('COMMON.NO_RECORDS_FOUND');

    this.changeGridEmptyMessageColor('black');
  }

  private changeGridEmptyMessageColor(color: string): void {
    const me = this;

    setTimeout(function() {
      const emptyMessageElement = me.elementRef.nativeElement.querySelector('.ui-datatable-emptymessage');

      if (emptyMessageElement) {
        me.renderer.setStyle(emptyMessageElement, 'color', color);
      }
    }, 100);
  }

  private handleLoadedEntities(entries): void {
    this.changeGridEntities(entries['data'], entries['count']);

    if (this.newEntityAddStarted) {
      this.finishNewEntityAdd(this.emptyEntity);
    }

    const componentState = this.elementStateService.findById(this.moduleElement.id);

    if (componentState) {
      // Now let's load the newly added entities:
      for (const newEntity of componentState.getCreatedElements()) {
        if (!this.findEntity(newEntity)) {
          this.entities = [newEntity, ...this.entities];
        }
      }

      componentState.clearCreatedElements();
    }

    this.genericElementEntityService.mergeEntities();

    this.entityFactory.assignMasterEntities(this.emptyEntity, this.elementContext.getMasterEntities());

    setTimeout(() => {
      this.genericGridLayoutService
        .setElementRef(this.elementRef)
        .setRenderer(this.renderer)
        .setGrid(this)
        .adaptRowsColor();

      this.onEntitiesChanged();

      ChangeDetectorRefHelper.detectChanges(this);
    }, 5);
  }
}
