
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {MemoFieldDefinitionValueComponent} from "../../../content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component";
import {Entity} from "../../../helpers/entity";

export class SetupMemoFieldDefinitionValueComponentExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component ? payloadValue.component : payloadValue;

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    const memoComponent = this.getMemoFieldComponent(component);

    if (memoComponent === null) {
      return this.getFailObservable(`Cannot find MemoFieldDefinitionValueComponent in ${payloadValue} slaves!`);
    }

    return this.doSetup(component, memoComponent);
  }

  protected doSetup(component: GenericElementAbstract, memoComponent: MemoFieldDefinitionValueComponent): Observable<ExecutionStepStatus> {
    const memo = component.getSelectedEntity();

    memoComponent.memo = memo;

    if (memoComponent.memo) {
      memoComponent.memoType = Entity.getValue(memo, 'memoType');

      if (!isNaN(memoComponent.memoType)) {
        memoComponent.memoType = Entity.getValueInEmbedded(memo, 'memoType');
      }
    }

    return memoComponent.onRefresh().pipe(
      map(() => {
        return {status: true, content: null};
      }));
  }

  private getMemoFieldComponent(component: GenericElementAbstract): MemoFieldDefinitionValueComponent|null {
    const slaves = component.getElementContext().getSlaveElementContexts();

    let memoComponent = null;

    for (const slave of slaves) {
      if (slave.component && slave.component instanceof MemoFieldDefinitionValueComponent) {
        memoComponent = slave.component;
        break;
      }
    }

    return memoComponent;
  }
}
