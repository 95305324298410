export class Entity {

    public static getValue(entity: any, propertyName: string): any|null {
        let value = null;

        if (typeof entity === 'undefined' || typeof propertyName === 'undefined' || entity === null) {
            return null;
        }

        if (propertyName.indexOf('.') === -1) {
            value = entity[propertyName];

            if (typeof value === 'undefined') {
              value = null;
            }

            return value;
        }

        const splitParts = propertyName.split('.');

        value = entity;

        for (const part of splitParts) {
            const propertyPart = value[part],
                entityPart = value && value['_embedded'] ? value['_embedded'][part] : null;

            if (propertyPart) {
                value = propertyPart;
            }

            if (entityPart) {
                value = entityPart;
            }

            if (!propertyPart && !entityPart) {
                value = null;
                break;
            }
        }

        if (typeof value === 'undefined') {
          value = null;
        }

        return value;
    }

    public static getValueInEmbedded(entity: any, propertyName: string): any|null {

        if (entity === null || typeof entity === 'undefined' || typeof entity._embedded === 'undefined') {
            return null;
        }

        return Entity.getValue(entity._embedded, propertyName);
    }

    public static getValueForcePropertyName(entity: any, propertyName: string): any|null {
        if (typeof entity === 'undefined') {
            return null;
        }

        return entity[propertyName];
    }

    public static setValue(entity: any, propertyName: string, value: any): any|null {
      if (entity && entity instanceof Object) {
        const fieldName = !propertyName.includes('.') ?
          propertyName :
          propertyName.substring(0, propertyName.lastIndexOf('.'));

        if (propertyName.includes('.')) {
          const associatedEntityPropertyName = propertyName.substring(propertyName.lastIndexOf('.') + 1),
            associatedEntity = Entity.getValue(entity, fieldName);

          Entity.setValue(associatedEntity, associatedEntityPropertyName, value);
        } else {
          entity[fieldName] = value;
        }
      }
    }

    public static extractEmbedded(entity: any): any|null {
      if (typeof entity === 'undefined') {
        return null;
      }

      if (entity && entity._embedded && entity._embedded instanceof Object) {
        for (const property in entity._embedded) {
          if (entity._embedded.hasOwnProperty(property)) {
            entity[property] = entity._embedded[property];

            entity._embedded[property] = Entity.extractEmbedded(entity._embedded[property]);
          }
        }
      }

      return entity;
    }
}
