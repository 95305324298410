<!--
  The whole code can be replaced with this:

    <app-dialog-add-address
      [(visible)]="isDialogVisible"
      [closeAfterSubmit]="true / false"
      (onAdd)="onImport($event)"
    ></app-dialog-add-address>

                        ^-$event same as form.value
                        ^-closeAfterSubmit default is true

  After this we could remove all dialog functions from the component except the onImport

  They are all now at the HrDialogAddAddressComponent inside the HrDialogModule (which is under /shared/).
-->

<app-dialog-add-address *ngIf="isDialogVisible" [(visible)]="isDialogVisible" (onAdd)="onImport($event)"></app-dialog-add-address>

<!--<p-dialog
    [(visible)]="isDialogVisible"
    [modal]="true"
    [minWidth]="200"
    [responsive]="true"
    *ngIf="isDialogVisible"
    appendTo="body"
    header="{{ 'TOOLBARMANAGEMENT.FORM_ADD_ADDRESS' | translate }}"
    showEffect="fade"
    width="800"
>
    <form [formGroup]="form" (ngSubmit)="onImport(form.value)">
        <div class="ui-grid ui-grid-responsive ui-fluid">

            <div class="ui-grid-row">
                <div class="ui-grid-col-2">
                    {{ 'COMMON.COUNTRY' | translate }} *:
                </div>
                <div class="ui-grid-col-6">
                    <p-autoComplete
                        [dropdown]="true"
                        [suggestions]="countries"
                        field="name"
                        formControlName="country"
                        (onSelect)="onSelect($event, 'contry')"
                        (completeMethod)="onSearch($event, 'countries')"
                        (onDropdownClick)="onSearch($event, 'countries')"
                ></p-autoComplete>
                </div>
                <div class="ui-grid-col-4">
                    <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.controls['country']['dirty'] && !form.controls['country']['value']">
                        <i class="fa fa-close"></i>
                        Country is required
                    </div>
                </div>
            </div>

            <div class="ui-grid-row">
                <div class="ui-grid-col-2">
                    {{ 'COMMON.CITY' | translate }} *:
                </div>
                <div class="ui-grid-col-6">
                    <p-autoComplete
                        [dropdown]="true"
                        [suggestions]="cities"
                        field="city"
                        formControlName="city"
                        (onSelect)="onSelect($event, 'city')"
                        (completeMethod)="onSearch($event, 'cities')"
                        (onDropdownClick)="onSearch($event, 'cities')"
                    ></p-autoComplete>
                </div>
                <div class="ui-grid-col-4">
                    <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.controls['city']['dirty'] && !form.controls['city']['value']">
                        <i class="fa fa-close"></i>
                        City is required
                    </div>
                </div>
            </div>


            <div class="ui-grid-row" *ngIf="formData.city">
                <div class="ui-grid-col-2">
                    {{ 'COMMON.STREET' | translate }} *:
                </div>
                <div class="ui-grid-col-6">
                    <p-autoComplete
                        [dropdown]="true"
                        [suggestions]="streets"
                        field="street"
                        formControlName="street"
                        (onSelect)="onSelect($event, 'street')"
                        (completeMethod)="onSearch($event, 'streets')"
                        (onDropdownClick)="onSearch($event, 'streets')"
                    ></p-autoComplete>
                </div>
                <div class="ui-grid-col-4">
                    <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.controls['street']['dirty'] && !form.controls['street']['value']">
                        <i class="fa fa-close"></i>
                        Street is required
                    </div>
                </div>
            </div>

            <div class="ui-grid-row">
                <div class="ui-grid-col-2"></div>
                <div class="ui-grid-col-6">
                    <button class="ui-helper-clearfix float-right btn" pButton type="submit" icon="fa-check" label="{{ 'COMMON.IMPORT' | translate }}" [disabled]="false"></button>
                </div>
                <div class="ui-grid-col-4"></div>
            </div>
            <div style="text-align:center;margin-top:20px" *ngIf="submitted">
                Form Submitted
                <br />
            </div>
        </div>
    </form>
</p-dialog>-->
