/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/upload/multi-upload-button/multi-upload-button.component.ngfactory";
import * as i2 from "../../components/upload/multi-upload-button/multi-upload-button.component";
import * as i3 from "../../services/generic-crud.service";
import * as i4 from "../../../core/message/message-growl.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./element-input-file-multi-upload.component";
import * as i7 from "../form.service";
import * as i8 from "../form-viewer.service";
var styles_ElementInputFileMultiUploadComponent = ["[_nghost-%COMP%] {\n          height: 100%;\n        }"];
var RenderType_ElementInputFileMultiUploadComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputFileMultiUploadComponent, data: {} });
export { RenderType_ElementInputFileMultiUploadComponent as RenderType_ElementInputFileMultiUploadComponent };
export function View_ElementInputFileMultiUploadComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { inputElement: 0 }), i0.ɵqud(671088640, 2, { uploadButton: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-multi-upload-button", [], null, null, null, i1.View_MultiUploadButtonComponent_0, i1.RenderType_MultiUploadButtonComponent)), i0.ɵdid(4, 49152, [[2, 4], ["uploadButton", 4]], 0, i2.MultiUploadButtonComponent, [i3.GenericCrudService, i4.MessageGrowlService, i5.TranslateService, i0.ChangeDetectorRef], { disabled: [0, "disabled"], fileContext: [1, "fileContext"], filePath: [2, "filePath"], mode: [3, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.element.fileContext; var currVal_2 = _co.element.filePath; var currVal_3 = "advanced"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ElementInputFileMultiUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-file-multi-upload", [], null, null, null, View_ElementInputFileMultiUploadComponent_0, RenderType_ElementInputFileMultiUploadComponent)), i0.ɵdid(1, 6012928, null, 1, i6.ElementInputFileMultiUploadComponent, [i7.FormService, i0.ChangeDetectorRef, i8.FormViewerService, i3.GenericCrudService, i0.ElementRef, i5.TranslateService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputFileMultiUploadComponentNgFactory = i0.ɵccf("app-form-element-input-file-multi-upload", i6.ElementInputFileMultiUploadComponent, View_ElementInputFileMultiUploadComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputFileMultiUploadComponentNgFactory as ElementInputFileMultiUploadComponentNgFactory };
