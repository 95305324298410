
import {throwError as observableThrowError, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../../../environments';
import {Constants} from 'app/constants';
import {FileData} from '../../../../../../../core/file/file-data';
import {FileService} from '../../../../../../../core/file/file.service';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {CloudPrinter} from '../../../../../../services/cloud-printer/cloud-printer';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {AuthenticationService} from '../../../../../../../core/authentication/authentication.service';

interface PrintTemplateGroup {
  id: number;
  defaultPrintTemplate: any;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-word-export',
  styleUrls: ['./toolbar-item-word-export.component.scss'],
  templateUrl: './toolbar-item-word-export.component.html'
})
export class ToolbarItemWordExport extends ToolbarItemAbstract {

  public static readonly CONTRACTS_URL = 'phoenix/printtemplates';
  public static readonly TEMPLATE_GROUPS_URL = 'phoenix/printtemplategroups';
  public static readonly TEMPLATE_PLACEHOLDER_URL = 'phoenix/printtemplateplaceholders';
  public static readonly PARAMETER_NAME = 'printFunction';
  public static readonly WORD_EXPORT_URL = `${Constants.APP_API_ROUTE}/export/entity/`;

  public isPreviewDialogVisible = false;
  public previewFileData: FileData = null;

  public placeholders: any[];
  public placeholderConfigs: any[];
  public isPlaceholderDialogVisible = false;
  public callbackFunctionOnPopulatedPlaceholder = function (component: ToolbarItemWordExport) { };

  public printerOptions: SelectItem[] = [];
  public selectedPrinter: any|CloudPrinter = null;

  public isDialogVisible = false;
  public isDataLoading = false;
  public isReportReady = false;


  public printTemplatesGroups: PrintTemplateGroup[] = [];
  public selectedPrintTemplateGroups: PrintTemplateGroup[] = [];

  public selectedEntity: any;
  public datamodel: any;
  public fileUrl = '';
  public pdfUrl = '';
  public baseUrl = '';

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected fileService: FileService,
    protected requestCachingService: RequestCachingService,
    protected toastService: ToastService,
    protected cdr: ChangeDetectorRef
  ) {
    super();
    this.baseUrl = environment.baseUrl;
  }

  public click() {
    // If it is in module context, we do not know how to get the proper entity - so we will just not do anything.
    if (this.isInCurrentContext(ToolbarItemWordExport.CONTEXT_MODULE)) {
      return;
    }

    this.isDialogVisible = true;
    this.datamodel = this.getDatamodel();
    this.selectedEntity = this.getEntity();

    this.loadPrintTemplateGroups();

    this.loadOnlinePrinters().subscribe((printers) => {
      this.printerOptions = [{
        value: null,
        label: '---'
      }];

      for (const printer of printers) {
        this.printerOptions = [...this.printerOptions, {
          value: printer,
          label: printer.name
        }];
      }

      this.printerOptions = [...this.printerOptions];
      this.selectDefaultPrinter();
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  private selectDefaultPrinter() {
    this.genericCrudService.getEntities(`phoenix/cloudprinters/userdefault`, '', {'embedded': 'none'})
      .subscribe((defaultPrinter) => {
        if (defaultPrinter) {
          this.selectedPrinter = this.printerOptions.find((aPrinter: SelectItem) => {
            return aPrinter.value && aPrinter.value.id === defaultPrinter.id;
          }).value;

          ChangeDetectorRefHelper.detectChanges(this);
          this.cdr.detectChanges();
        }
    });
  }

  public isPrintTemplateGroupSelected(printTemplateGroup): boolean {
    return this.getPrintTemplateGroupIndex(printTemplateGroup) !== -1;
  }

  public getPrintTemplateGroupIndex(printTemplateGroup): number {
    return this.selectedPrintTemplateGroups.findIndex((aPrintTemplateGroup: PrintTemplateGroup) => {
      return aPrintTemplateGroup && printTemplateGroup && aPrintTemplateGroup.id === printTemplateGroup.id
    });
  }

  public onSelectPrintTemplateGroup(event, entity) {
    const index = this.getPrintTemplateGroupIndex(entity);

    if (event && index === -1) {
      this.selectedPrintTemplateGroups.push(entity);
    }else if (!event && index !== -1) {
      this.selectedPrintTemplateGroups.splice(index, 1);
    }
  }

  public onExportWithPlaceholders() {
    this.callbackFunctionOnPopulatedPlaceholder(this);
    this.isPlaceholderDialogVisible = false;
  }

  public onExportSingle(printTemplateGroup, key: string): void {
    this.selectedPrintTemplateGroups = [printTemplateGroup];

    this.onExport(key, false);
  }

  public onPreviewSingle(printTemplateGroup): void {
    this.selectedPrintTemplateGroups = [printTemplateGroup];

    this.onPreview();
  }

  public onExport(key: string, store: boolean = false): void {
    const me = this;

    if (this.datamodel && this.datamodel.id && this.selectedEntity && this.selectedEntity.id) {
      this.callbackFunctionOnPopulatedPlaceholder = function (component: ToolbarItemWordExport) {
        component.doExport(key, store).subscribe((fileResponse) => {
          me.requestCachingService.removeByExpression('phoenix');

          component.downloadFile(fileResponse);
        });
      };

      const selectedTemplates = this.prepareDefaultPrintTemplates();
      this.getPrintTemplatePlaceholders(selectedTemplates).subscribe((placeholders: any[]) => {
        this.placeholders = placeholders;
        if (this.placeholders.length > 0) {
          this.isPlaceholderDialogVisible = true;
        }else {
          this.callbackFunctionOnPopulatedPlaceholder(this);
        }
      });
    }
  }

  public onEmail() {
    if (this.datamodel && this.datamodel.id && this.selectedEntity && this.selectedEntity.id) {
      this.callbackFunctionOnPopulatedPlaceholder = function (component: ToolbarItemWordExport) {
        component.doExport('docx').subscribe((fileResponse) => {
          const url = encodeURI(component.baseUrl + '/' + fileResponse.file);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.setProperty('display', 'none');
          a.href = `mailto:?subject=Report&body=${url}`;
          a.click();
        });
      };

      const selectedTemplates = this.prepareDefaultPrintTemplates();
      this.getPrintTemplatePlaceholders(selectedTemplates).subscribe((placeholders: any[]) => {
        this.placeholders = placeholders;
        if (this.placeholders.length > 0) {
          this.isPlaceholderDialogVisible = true;
        }else {
          this.callbackFunctionOnPopulatedPlaceholder(this);
        }
      });

    }
  }

  public onPreview(): void {
    if (this.datamodel && this.datamodel.id && this.selectedEntity && this.selectedEntity.id) {
      this.callbackFunctionOnPopulatedPlaceholder = function (component: ToolbarItemWordExport) {
        const selectedTemplate = component.getFirstSelectedTemplateEntity();
        if (selectedTemplate) {
          component.fileService.getFile(selectedTemplate , {
            datamodelId: component.datamodel.id,
            customerId: component.selectedEntity.id,
            placeholders: component.placeholders
          }).subscribe((fileData: FileData) => {
            component.previewFileData = fileData;
            component.isPreviewDialogVisible = true;

            ChangeDetectorRefHelper.detectChanges(this);
          });
        }
      };

      const selectedTemplates = this.prepareDefaultPrintTemplates();
      this.getPrintTemplatePlaceholders(selectedTemplates).subscribe((placeholders: any[]) => {
        this.placeholders = placeholders;
        if (this.placeholders.length > 0) {
          this.isPlaceholderDialogVisible = true;
        } else {
          this.callbackFunctionOnPopulatedPlaceholder(this);
        }
      });

    }
  }

  public onPrint(): void {
    if (this.datamodel && this.datamodel.id && this.selectedEntity && this.selectedEntity.id &&
      this.selectedPrinter !== null
    ) {
      const me = this;
      this.callbackFunctionOnPopulatedPlaceholder = function (component: ToolbarItemWordExport) {

        me.requestCachingService.removeByExpression('phoenix');
        const selectedTemplatesIds = me.prepareDefaultPrintTemplates(),
          observables = [];

        for (const selectedTemplateId of selectedTemplatesIds) {
          const url = ToolbarItemWordExport.WORD_EXPORT_URL + me.datamodel.id + '/file/'
            + me.selectedEntity.id + '/report/' + selectedTemplateId + '/pdf';

          const placeholderIndex = me.placeholders.findIndex((aPlaceholders) => {
            return aPlaceholders.printTemplateId === selectedTemplateId;
          });

          observables.push(me.genericCrudService.customPost(url, {'placeholders': [me.placeholders[placeholderIndex]]}));
        }

        if (observables.length !== 0) {
          me.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
            severity: 'info',
            life: TOAST_LIFE_INFINITE,
            closable: false,
            summary: me.translate.instant('COMMON.PRINTING_PLEASE_WAIT')
          });

          observableForkJoin(observables).pipe(
            catchError((status) => {
              me.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

              return observableThrowError(status);
            }))
            .subscribe((fileResponses: {file: string, fileName: string}[]) => {
            for (const fileResponse of fileResponses) {
              me.requestCachingService.removeByExpression('phoenix');

              if (me.selectedPrinter.type === 'google') {
                me.doPrint(fileResponse);
              } else {
                me.doSftpUpload(fileResponse);
              }
            }
          });
        }
      };

      const selectedTemplates = this.prepareDefaultPrintTemplates();
      this.getPrintTemplatePlaceholders(selectedTemplates).subscribe((placeholders: any[]) => {
        this.placeholders = placeholders;
        if (this.placeholders.length > 0) {
          this.isPlaceholderDialogVisible = true;
        }else {
          this.callbackFunctionOnPopulatedPlaceholder(this);
        }
      });
    }
  }

  public onStore(): void {
    this.onExport('docx', true);
  }

  protected handleClickInGridContext() {
    this.isDialogVisible = true;

    this.loadPrintTemplateGroups();
  }

  protected loadPrintTemplateGroups() {
    this.isDataLoading = true;

    this.genericCrudService
      .getEntities(ToolbarItemWordExport.TEMPLATE_GROUPS_URL
        + '?' + ToolbarItemWordExport.PARAMETER_NAME + '=' + this.getToolbarItem().parameter).subscribe((aPrintTemplatesGroups) => {
      this.printTemplatesGroups = aPrintTemplatesGroups;

      this.isDataLoading = false;
    });
  }

  public onDefaultTemplateChanged(event, entity) {
    if (entity && event.value) {
      entity['defaultPrintTemplate'] = event.value;
    }
  }

  public getTemplateName(entity): string {
    let reportName = '';

    if (entity['defaultPrintTemplate']) {
      reportName = entity['defaultPrintTemplate']['name'];
    }

    return reportName;
  }

  public getOptions(entity) {
    const options = [];

    for (const option of entity.printTemplates) {
      options.push({
        label: option.name,
        value: option
      });
    }

    return options;
  }

  private prepareDefaultPrintTemplates(): number[] {
    const keys = [];

    for (const printTemplateGroup of this.selectedPrintTemplateGroups) {
      const defaultPrintTemplate = this.getDefaultPrintTemplate(printTemplateGroup);

      if (defaultPrintTemplate && defaultPrintTemplate.id) {
        keys.push(defaultPrintTemplate.id);
      }
    }

    return keys;
  }

  private getFirstSelectedTemplateEntity() {
    return this.selectedPrintTemplateGroups.length > 0 ? this.getDefaultPrintTemplate(this.selectedPrintTemplateGroups[0]) : null;
  }

  private getPrintTemplatePlaceholders(printTemplates: any[]): Observable<any> {
    const printTemplateString = 'in:' + printTemplates.join(',');
    return this.genericCrudService.getEntities(
      ToolbarItemWordExport.TEMPLATE_PLACEHOLDER_URL + '/offset/0/limit/50/orderby/rank/asc',
      '',
      { 'printTemplate': printTemplateString }
      ).pipe(
      map((placeholders: any[]) => {
        const formattedPlaceholders: any[] = [];
        for (const placeholder of placeholders['data']){
          let formattedPlaceholder = formattedPlaceholders.find((printTemplate) => {
            return printTemplate['printTemplateId'] === placeholder['_embedded']['printTemplate']['id'];
          });
          if (!formattedPlaceholder) {
            formattedPlaceholder = {
              'printTemplateName': placeholder['_embedded']['printTemplate']['name'],
              'printTemplateId': placeholder['_embedded']['printTemplate']['id'],
              'placeholders': []};
            formattedPlaceholders.push(formattedPlaceholder);
          }
          formattedPlaceholder['placeholders'] = [...formattedPlaceholder['placeholders'], {
            name: placeholder['name'],
            description: placeholder['description'],
            inputType: placeholder['_embedded']['inputType'] ? placeholder['_embedded']['inputType'] : {name: 'text'},
            availableValues: placeholder['availableValues'] || [],
            value: null
          }];
        }
        return formattedPlaceholders;
      }));
  }

  public onEditDate(event, placeholder) {
    placeholder['value'] = event;
  }

  public onEditDropdown(event, placeholder) {
    placeholder['value'] = event.value;
  }

  public isXmlEnabled(printTemplateGroup: PrintTemplateGroup): boolean {
    const template = this.getDefaultPrintTemplate(printTemplateGroup);

    return template && template['reportXmlDocumentName'];
  }

  public isPdfEnabled(printTemplateGroup: PrintTemplateGroup): boolean {
    const template = this.getDefaultPrintTemplate(printTemplateGroup);

    return template && template['isPdfOnly'] && template['reportDocumentName'];
  }

  public isWordEnabled(printTemplateGroup: PrintTemplateGroup): boolean {
    const template = this.getDefaultPrintTemplate(printTemplateGroup);

    return template && template['isWordOnly'] && template['reportDocumentName'];
  }

  public getPlaceholderOptions(availableValues) {
    const values = [];
    availableValues = availableValues.split(';');
    for (const value of availableValues){
      values.push({label: value, value: value});
    }
    return values;
  }

  private downloadFile(fileResponse) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');
    a.href = this.baseUrl + '/' + fileResponse.file;
    a.target = '_blank';
    a.download = fileResponse.fileName;
    a.click();
  }

  private doExport(key: string, store: boolean = false): Observable<any> {
    if (this.datamodel && this.datamodel.id && this.selectedEntity && this.selectedEntity.id) {
      const selectedTemplates = this.prepareDefaultPrintTemplates();

      if (selectedTemplates.length > 1) {
        const url = ToolbarItemWordExport.WORD_EXPORT_URL + this.datamodel.id + '/file/'
          + this.selectedEntity.id + '/reports/' + key + (store ? '?store=true' : '');

        return this.genericCrudService.customPost(url, {'templates': selectedTemplates, 'placeholders': this.placeholders});

      }else if (selectedTemplates.length > 0) {
        const url = ToolbarItemWordExport.WORD_EXPORT_URL + this.datamodel.id + '/file/'
          + this.selectedEntity.id + '/report/' + selectedTemplates[0] + '/' + key + (store ? '?store=true' : '');

        return this.genericCrudService.customPost(url, {'placeholders': this.placeholders});
      }
    }
  }

  private doPrint(fileResponse: {file: string, fileName: string}): void {
    this.genericCrudService.createEntity('app/google/print/submit', {
      'printerId': this.selectedPrinter.printerId,
      'title': this.selectedPrinter.name,
      'filePath': fileResponse.file
    }).pipe(
      catchError((status) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        this.messageGrowlService.error(
          status,
          this.translate.instant('COMMON.ERROR')
        );
        return observableThrowError(status);
      }))
      .subscribe(() => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        this.messageGrowlService.showDataSaved();
      });
  }

  private doSftpUpload(fileResponse: {file: string, fileName: string}): void {
    this.genericCrudService.createEntity('phoenix/cloudprinters/sftp/upload', {
      'filePath': fileResponse.file,
      'fileName': fileResponse.fileName,
      'printerId': this.selectedPrinter.id,
      'uploadPath': 'exports/' + fileResponse.fileName
    }).pipe(
      catchError((status) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        this.messageGrowlService.error(
          status,
          this.translate.instant('COMMON.ERROR')
        );
        return observableThrowError(status);
      }))
      .subscribe((status) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        if (status) {
          this.messageGrowlService.showDataSaved();
        } else {
          this.messageGrowlService.error(
            this.translate.instant('COMMON.GENERIC_ERROR'),
            this.translate.instant('COMMON.ERROR')
          );
        }
      });
  }

  private loadOnlinePrinters(): Observable<any[]> {
    return this.genericCrudService.getEntities('phoenix/cloudprinters/online', '', {'embedded': 'none'});
  }

  private getDefaultPrintTemplate(printTemplateGroup: {defaultPrintTemplate: any}) {
    return printTemplateGroup && printTemplateGroup.defaultPrintTemplate ? printTemplateGroup.defaultPrintTemplate : null;
  }

}
