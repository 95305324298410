
import {of as observableOf, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ComponentOpenModuleExecutionStep} from '../component-open-module-execution-step';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {DoubleClickService} from '../../../content-renderer/services/double-click.service';
import {ModuleElement} from '../../module/module-element';
import {RequestCachingService} from '../../request-caching.service';
import {ModuleContentBlockerService} from '../../../../core/service/module-content-blocker/module-content-blocker.service';
import {ElementType} from '../../../content-renderer/services/ElementContext';
import {GenericDialogModuleService} from "../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service";
import {EntityValidator} from '../../../validators/services/entity-validator';

export class OpenCustomerDetailsModuleExecutionStep extends ComponentOpenModuleExecutionStep {

  protected doOpenModuleInGridContext(component: AbstractGenericGridComponent|FormViewerComponent) {

    const blocker: ModuleContentBlockerService = this.injector.get(ModuleContentBlockerService, null),
      translateService: TranslateService = this.injector.get(TranslateService, null);

    blocker.start(translateService.instant('COMMON.LOADING_PLEASE_WAIT'));

    this.removeCurrentModules()
      .clearCache('phoenix/customers')
      .clearValidationsCache()
      .openNewModule(component);

    return observableOf({status: true, content: null});
  }

  protected clearCache(apiRoute: string): this {
    const requestCachingService: RequestCachingService = this.injector.get(RequestCachingService, null);

    requestCachingService.removeByExpression(apiRoute);
    this.entityDraftStoreService.clear();
    this.entityDirtyStoreService.clear();

    return this;
  }

  protected clearValidationsCache(): this {
    const entityValidator: EntityValidator = this.injector.get(EntityValidator, null);

    entityValidator.clearDictionaries().clearCache();

    return this;
  }

  protected openNewModule(component:  AbstractGenericGridComponent|FormViewerComponent): this {
    const doubleClickService: DoubleClickService = this.injector.get(DoubleClickService, null),
      entity = component.getSelectedEntity();

    let moduleOpenerComponent = component;

    if (component.getElementContext().getMasterElementContext() && component.getElementContext().getMasterElementContext().component) {
      moduleOpenerComponent = component.getElementContext().getMasterElementContext().component;
    }

    if (moduleOpenerComponent instanceof AbstractGenericGridComponent) {
      doubleClickService.setGrid(moduleOpenerComponent);
    }

    if (moduleOpenerComponent instanceof FormViewerComponent) {
      doubleClickService.setForm(moduleOpenerComponent);
    }

    const target = ModuleElement.DOUBLE_CLICK_TARGET_CURRENT_TAB,
      module = this.getParamValue('module');

    doubleClickService
      .handleOpenModule(moduleOpenerComponent, target, module, entity);

    return this;
  }

  protected removeCurrentModules(): this {
    const currentModule = this.modulesStateService.getCurrent();

    currentModule.module = null;

    if (currentModule.isDialog && currentModule.parent) {
      let masterComponent = null;

      for (const aComponent of currentModule.getComponents()) {
        if (aComponent.getElementContext() && aComponent.getElementContext().type === ElementType.Form &&
          aComponent.getElementContext().getMasterElementContext() !== null
        ) {
          masterComponent = aComponent.getElementContext().getMasterElementContext().component;
        }
      }

      // destroy dialog as soon as possible, performance booster
      if (masterComponent !== null) {
        const dialogService = this.injector.get(GenericDialogModuleService);

        dialogService.persistHide();
      }
    }

    if (currentModule.parent) {
      currentModule.parent.module = null;
      this.modulesStateService.remove(currentModule.parent);
    }

    this.modulesStateService.remove(currentModule);

    return this;
  }
}
