
import {refCount, map, publishReplay, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppHttpService } from '../../../app-http.service';
import { ModuleElementPart } from './module-element-part';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ModuleElementPartCrudService extends AppHttpService {

  private partsUrl = 'superadmin/moduleelements/:moduleElementId/parts';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   *
   * @param moduleElementId
   * @returns {Observable<R>}
   */
  getParts(moduleElementId: number): Observable<ModuleElementPart[]> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    const url = this._createUrl(moduleElementId);

    return this.genericCrudService.get(url).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getActiveParts(moduleElementId: number): Observable<ModuleElementPart[]> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    const url = this._createUrl(moduleElementId) + '/active';

    return this.genericCrudService.get(url).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   *
   * @param moduleElementId
   * @param partId
   * @returns {Observable<Object>}
   */
  deleteModuleElementPart(moduleElementId: number, partId: number): Observable<Object> {
    if (!moduleElementId || !partId) {
      throw new Error('Invalid module element id or part id given.');
    }

    const url = this._createUrl(moduleElementId);

    return this.genericCrudService.deleteEntity(url + '/' + partId);
  }

  /**
   *
   * @param moduleElementId
   * @param moduleElementPart
   * @returns {Observable<R>}
   */
  createModuleElementPart(moduleElementId: any, moduleElementPart: ModuleElementPart): Observable<ModuleElementPart> {
    if (!moduleElementId || !moduleElementPart) {
      throw new Error(`
        Invalid module element given.
        Module element id: ${moduleElementId};
        new module element part: ${moduleElementPart};
       
      `);

    }

    const url = this._createUrl(moduleElementId);

    return this.genericCrudService.createEntity(url, moduleElementPart);
  }

  editModuleElementPart(moduleElementPart: ModuleElementPart, moduleElementId: number): Observable<ModuleElementPart> {
    if (!moduleElementId || !moduleElementPart) {
      throw new Error(`
        Invalid module element given.
        Module element id: ${moduleElementId};
        new module element part: ${moduleElementPart};
            `);

    }

    const url = this._createUrl(moduleElementId);

    return this.genericCrudService.editEntity(url + '/' + moduleElementPart.id, moduleElementPart).pipe(
      map((json) => { return <ModuleElementPart>json; }));
  }

  /**
   *
   * @param moduleElementId
   * @returns {string}
   * @private
   */
  _createUrl(moduleElementId: number) {
    const re = /\:moduleElementId/gi;

    return this.partsUrl.replace(re, String(moduleElementId));
  }
}
