import {Component} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {DoubleClickService} from '../../../../services/double-click.service';
import {GenericDialogModuleService} from '../../../generic-dialog/service/generic-dialog-module.service';
import {Module} from '../../../../../services/module/module';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {ExecutionStepFactoryService} from '../../../../../../core/executor/factory/execution-step-factory.service';
import {FieldActionOpenModuleComponent} from './field-action-open-module.component';
import {Guid} from 'guid-typescript';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {SetElementFiltersCondition} from '../../../../../job-runner/condition/inquiry/set-element-filters.condition';
import {SetElementFiltersJob} from '../../../../../job-runner/job/inquiry/set-element-filters.job';
import {ElementType} from '../../../../services/ElementContext';
import {
  SetPrecalculationArticleOtherIsActivePropertyCondition
} from '../../../../../job-runner/condition/set-precalculation-article-other-is-active-property.condition';
import {
  SetPrecalculationArticleOtherIsActivePropertyJob
} from '../../../../../job-runner/job/set-precalculation-article-other-is-active-property.job';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';
import {SetPrecalculationArticleOtherEmbeddedCondition} from '../../../../../job-runner/condition/set-precalculation-article-other-embedded.condition';
import {SetPrecalculationArticleOtherEmbeddedJob} from '../../../../../job-runner/job/set-precalculation-article-other-embedded.job';

@Component({
  selector: 'field-action-open-condition-module',
  template: ''
})
export class FieldActionOpenConditionModuleComponent extends FieldActionOpenModuleComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected jobContainerService: JobContainerService,
    protected modulesStateService: ModulesStateService,
    protected entityManager: EntityManagerService
  ) {
    super(genericCrudService, doubleClickService, genericDialogModuleService, executionStepFactoryService);
  }

  protected doOpenInDialog(module: Module): void {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity(),
      form = gridComponent.getElementContext().getMasterElementContext().component,
      masterEntity = form.getSelectedEntity(),
      isAutocompleteModuleState = this.getActionParamValue('isAutocompleteView') || false,
      onCloseStep = this.getActionParamValue('onClose') || null,
      dialogWidth = this.getActionParamValue('width') || 1280,
      dialogHeight = this.getActionParamValue('height') || 920,
      maximized = this.getActionParamValue('maximized') || false,
      emptyEntityFqn = 'PhoenixBundle\\Entity\\PreCalculation',
      emptyRoute = 'phoenix/precalculations';

    this.createJobs(gridComponent, masterEntity);

    this.genericDialogModuleService.showDialog(module, {
      height: dialogHeight,
      width: dialogWidth,
      entity: this.prepareEmptyEntity(emptyEntityFqn, emptyRoute, entity, masterEntity),
      masterEntity: masterEntity['customer'],
      masterField: 'customer',
      masterFilterField: 'customer',
      masterFilterValue: masterEntity['customer'].id,
      masterElementContext: gridComponent.getElementContext(),
      isAutocompleteModuleState: isAutocompleteModuleState,
      maximized: maximized,
      onClose: (closeDialogCallerComponent: GenericElementAbstract) => {

        if (closeDialogCallerComponent && onCloseStep) {
          this.executeOnCloseStep(onCloseStep, closeDialogCallerComponent);
        }

        return true;
      },
      afterClose: () => {
        this.reloadGenericTreeComponent();
      }
    });
  }

  private createJobs(gridComponent, masterEntity) {
    this.createPreCalculationArticleEmbeddedJob();
    this.createPreCalculationArticleIsActiveJob(gridComponent);
    this.createPreCalculationFilterJob(gridComponent, masterEntity);
  }

  private createPreCalculationArticleEmbeddedJob(): void {
    const condition = new SetPrecalculationArticleOtherEmbeddedCondition();
    this.jobContainerService.registerJob(
      new SetPrecalculationArticleOtherEmbeddedJob().setIsImmutable(true),
      condition
    );
  }

  private createPreCalculationArticleIsActiveJob(gridComponent): void {
    const inquiryPosition = gridComponent.getSelectedEntity(),
      currentModule = this.modulesStateService.getCurrent();

    const tree = currentModule.getComponents().find((aComponent) => aComponent.getElementContext().type === ElementType.DynamicTree),
      salaryTypes = [];

    if (tree instanceof AbstractGenericGridComponent) {
      for (const entity of tree.getEntities()) {
        if (entity.inquiryPositions && entity.inquiryPositions[inquiryPosition.id] &&
          entity.inquiryPositions[inquiryPosition.id].isRequired
        ) {
          salaryTypes.push(entity.salaryType);
        }
      }
    }

    const condition = new SetPrecalculationArticleOtherIsActivePropertyCondition();
    condition.setPayload({
      requiredSalaryTypes: salaryTypes
    });
    this.jobContainerService.registerJob(
      new SetPrecalculationArticleOtherIsActivePropertyJob().setIsImmutable(true),
      condition
    );
  }

  private createPreCalculationFilterJob(gridComponent, masterEntity) {
    const fields = [
      'customerCollectiveAgreement',
      'workplace'
    ];

    for (const field of fields) {
      const condition = new SetElementFiltersCondition();
      condition.setPayload({
        component: field,
        entity: masterEntity
      });

      this.doCreateFilterJob(condition);
    }
  }

  private doCreateFilterJob(condition: SetElementFiltersCondition) {
    this.jobContainerService.registerJob(
      new SetElementFiltersJob(),
      condition
    );
  }

  private reloadGenericTreeComponent(): void {
    const currentModule = this.modulesStateService.getCurrent();

    const tree = currentModule.getComponents().find((aComponent) => aComponent.getElementContext().type === ElementType.DynamicTree);

    if (tree instanceof AbstractGenericGridComponent) {
      tree.onRefresh().subscribe();
    }
  }

  private prepareEmptyEntity(emptyEntityFqn: string, emptyEntityRoute: string, entity: any, masterEntity: any) {
    const emptyEntity = {
      uniqueId: Guid.create().toString(),
      fqn: emptyEntityFqn || null,
      _links: null,
      _embedded: {
        customer: masterEntity['customer'],
        salaryClassification: entity['assignmentCollectiveAgreementClassification'],
        secondarySalaryClassification: entity['employeeCollectiveAgreementClassification'],
        workplace: entity['workplace'],
        workingMode: entity['workingMode'],
        customerCollectiveAgreement: masterEntity['customerCollectiveContract'],
        preCalculationType: masterEntity['preCalculationType']
      }
    };

    if (emptyEntityRoute) {
      emptyEntity._links = {
        self: {
          href: emptyEntityRoute
        }
      };
    }

    this.entityManager.persist(emptyEntity, {property: 'customer', oldValue: null, newValue: masterEntity['customer'], force: true });
    this.entityManager.persist(emptyEntity, {property: 'salaryClassification', oldValue: null, newValue: entity['assignmentCollectiveAgreementClassification'], force: true });
    this.entityManager.persist(emptyEntity, {property: 'secondarySalaryClassification', oldValue: null, newValue: entity['employeeCollectiveAgreementClassification'], force: true });
    this.entityManager.persist(emptyEntity, {property: 'workplace', oldValue: null, newValue: entity['workplace'], force: true });
    this.entityManager.persist(emptyEntity, {property: 'workingMode', oldValue: null, newValue: entity['workingMode'], force: true });
    this.entityManager.persist(emptyEntity, {property: 'customerCollectiveAgreement', oldValue: null, newValue: masterEntity['customerCollectiveContract'], force: true });
    this.entityManager.persist(emptyEntity, {property: 'preCalculationType', oldValue: null, newValue: masterEntity['preCalculationType'], force: true });

    return emptyEntity;
  }
}
