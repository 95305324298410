
    <div [formGroup]="formGroup">
      <p-radioButton
        appSeleniumDirective
        [element]="element"
        [formControlName]="formControlName"
        [name]="element.group "
        [value]="element.defaultValue"
        (onClick)="onValueChange($event)"
      ></p-radioButton>
    </div>
  