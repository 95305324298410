
<div
  *ngIf="src"
  style="height: 100%"
>
  <pdf-viewer
    [src]="src"
    [render-text]="true"
    [autoresize]="true"
    [fit-to-page]="true"
    [show-all]="true"
  ></pdf-viewer>
</div>
