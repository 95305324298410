import {Component, OnInit, AfterViewInit, ViewContainerRef} from '@angular/core';
import './rxjs-operators';
import {AuthenticationService} from './core/authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from './shared/services/language/language.service';
import {GenericDialogModuleService} from './shared/content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {BootstrapService} from './core/service/bootstrap.service';
import {ExecutorService} from './core/executor/executor.service';
import {ToastService} from './core/service/toast.service';
import {ModuleContentBlockerService} from './core/service/module-content-blocker/module-content-blocker.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
  selector: 'app-root',
  template: `
    <div class="app" fxLayoutAlign="start stretch" fxLayout="column" [class.primary-bg-lighter]="!authenticationService.authenticated()">
      <app-header fxFlex="nogrow" *ngIf="authenticationService.authenticated()"></app-header>
      <div fxFlex *blockUI="'app-container'" fxLayoutAlign="start stretch" fxLayout="column" class="app-content">
        <router-outlet></router-outlet>
      </div>
      <app-footer fxFlex="nogrow" *ngIf="authenticationService.authenticated()"></app-footer>
    </div>
    <app-messages></app-messages>
  `,
  styleUrls: ['./app.component.scss'],
  providers: [ExecutorService]
})
export class AppComponent implements OnInit, AfterViewInit {
  @BlockUI('app-container') blockUI: NgBlockUI;

  constructor(
    public authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    protected viewContainerRef: ViewContainerRef,
    protected genericDialogService: GenericDialogModuleService,
    protected bootstrapService: BootstrapService,
    protected executorService: ExecutorService,
    protected toastService: ToastService,
    protected moduleContentBlockerService: ModuleContentBlockerService
  ) {
    this.bootstrapLanguage();
  }

  private bootstrapLanguage() {
    this.translateService.setDefaultLang(this.languageService.getLocalStorageLanguage());

    this.translateService.use(this.languageService.getLocalStorageLanguage());
  }

  ngOnInit() {
    this.moduleContentBlockerService.component = this;
    this.genericDialogService.setRootViewContainerRef(this.viewContainerRef);
    this.toastService.setViewContainerRef(this.viewContainerRef);
  }

  ngAfterViewInit() {
    this.bootstrapService.setExecutorService(this.executorService).bootstrap().subscribe();
  }
}
