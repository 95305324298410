<span
  [attr.columnIndex]="columnIndex"
  *ngIf="
  !column.isAssociatedField &&
  column.fieldType !== 'date' &&
  column.fieldType !== 'dateMonthAndYear' &&
  column.fieldType !== 'dateTime' &&
  column.fieldType !== 'checkbox' &&
  column.fieldType !== 'tricheckbox' &&
  column.fieldType !== 'lockState' &&
  column.fieldType !== 'decimal' &&
  column.fieldType !== 'link' &&
  column.fieldType !== 'leasedEmployeeAbsenceDate' &&
  column.fieldType !== 'decimal' &&
  column.fieldType !== 'image' &&
  column.fieldType !== 'phoneNumber' &&
  column.fieldType !== 'permissionInformation' &&
  column.fieldType !== 'documentDownload' &&
  column.fieldType !== 'eldaDownload' &&
  column.fieldType !== 'moduleElementColumn' &&
  column.fieldType !== 'openmodule' &&
  column.fieldType !== 'color' &&
  column.fieldType !== 'externalLink' &&
  column.fieldType !== 'email' &&
  column.fieldType !== 'currentBranchOffice' &&
  column.fieldType !== 'html'
">
{{ entity[column.property] }}
</span>

<span
  *ngIf="column.fieldType === 'html'"
  [attr.columnIndex]="columnIndex"
  [innerHTML]="entity[column.property]"
>
</span>

<span
  [attr.columnIndex]="columnIndex"
  *ngIf="column.fieldType === 'currentBranchOffice'">
  {{ getDatasetBranchOffice(entity) }}
</span>

<span
  [attr.columnIndex]="columnIndex"
  *ngIf="column.fieldType === 'email'"
>
  <input
    class="email-input"
    type="email"
    readonly
    [(ngModel)]="entity[column.property]"
  />
</span>

<button
  pButton
  *ngIf="column.fieldType === 'openmodule'"
  [disabled]="component.isEditCellDisabled(entity, column)"
  class="openmodule-button"
  [label]="component.getEditCellValue(entity, column) || column.header"
  (click)="component.onEditCell($event, entity, column)">
</button>

<span
  [attr.columnIndex]="columnIndex"
  *ngIf="
    column.isAssociatedField &&
    column.fieldType !== 'date' &&
    column.fieldType !== 'checkbox' &&
    column.fieldType !== 'decimal' &&
    column.fieldType !== 'image'"
>
    {{ getEntityAssociatedOneEntityValueLabel(entity, column) }}
</span>

<span
  [attr.columnIndex]="columnIndex"
  *ngIf="column.fieldType === 'associationMany'"
>
    {{ getEntityAssociatedManyEntityValue(entity, column) }}
</span>

<app-generic-calendar-field-leased-employee-absence-date
  *ngIf="!column.isAssociatedField && column.fieldType === 'leasedEmployeeAbsenceDate'"
  [entity]="entity"
  [column]="column"
  [renderer]="true"
></app-generic-calendar-field-leased-employee-absence-date>

<span
  [attr.columnIndex]="columnIndex"
  *ngIf="!column.isAssociatedField && column.fieldType === 'date'"
>
    {{ entity[column.property] | appDate:'dd.mm.yy' }}
</span>

<span
  *ngIf="column.isAssociatedField && column.fieldType === 'date'"
  pTooltip="{{ getEntityAssociatedOneEntityValue(entity, column) | appDate:'dd.mm.yy' }}" tooltipPosition="top"
>
  {{ getEntityAssociatedOneEntityValue(entity, column) | appDate:'dd.mm.yy' }}
</span>

<span
  *ngIf="!column.isAssociatedField && column.fieldType === 'dateTime'"
  pTooltip="{{ entity[column.property] | date:'dd.MM.yyyy HH:mm' }}" tooltipPosition="top"
>
  {{ entity[column.property] | date:'dd.MM.yyyy HH:mm' }}
</span>

<span
  pTooltip="{{ entity[column.property] | date:'MM.yyyy' }}" tooltipPosition="top"
  [attr.columnIndex]="columnIndex"
  *ngIf="column.fieldType === 'dateMonthAndYear'"
>
  {{ entity[column.property] | date:'MM.yyyy' }}
</span>

<span
  *ngIf="!column.isAssociatedField && column.fieldType === 'decimal'"
  pTooltip="{{ entity[column.property] | decimal: ',':'.':column?.field?.inputFormat?.number?.decimalLimit }}" tooltipPosition="top"
>
  {{ entity[column.property] | decimal: ',':'.':column?.field?.inputFormat?.number?.decimalLimit }}
</span>

<span
  *ngIf="column.isAssociatedField && column.fieldType === 'decimal'"
  pTooltip="{{ getEntityAssociatedOneEntityValue(entity, column) | decimal: ',':'.':column?.field?.inputFormat?.number?.decimalLimit }}" tooltipPosition="top"
>
  {{ getEntityAssociatedOneEntityValue(entity, column) | decimal: ',':'.':column?.field?.inputFormat?.number?.decimalLimit }}
</span>

<span
  *ngIf="column.fieldType === 'lockState'"
>
  <i class="pi pi-lock" style="font-size: 1.5em" *ngIf="getEntityAssociatedOneEntityValue(entity, column) === true"></i>
  <i class="pi pi-lock-open" style="font-size: 1.5em" *ngIf="getEntityAssociatedOneEntityValue(entity, column) === false"></i>
</span>

<p-checkbox
  *ngIf="column.fieldType === 'checkbox'"
  [hidden]="entity.isSummary"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  [ngModel]="getEntityAssociatedOneEntityValue(entity, column)"
  binary="true"
></p-checkbox>

<p-triStateCheckbox
  *ngIf="column.fieldType === 'tricheckbox'"
  [hidden]="entity.isSummary"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  [(ngModel)]="entity[column.property]"
></p-triStateCheckbox>

<a
  [attr.columnIndex]="columnIndex"
  *ngIf="column.fieldType === 'link'"
  class="link-entity-cell"
  (click)="component.onEditCellFocus($event, entity, column)"
>
  {{ entity[column.property] }}
</a>

<a
  *ngIf="column.fieldType === 'documentDownload'"
  class="link-entity-cell"
  (click)="component.onEditCellFocus($event, entity, column)"
>{{ entity[column.property] }}</a>

<a
  *ngIf="column.fieldType === 'eldaDownload'"
  class="link-entity-cell"
  (click)="component.onEditCellFocus($event, entity, column)"
>{{ entity[column.property] }}</a>

<a
  *ngIf="column.fieldType === 'externalLink'"
  class="link-entity-cell" (click)="component.onEditCellFocus($event, entity, column)"
  pTooltip="{{ entity[column.property] }}" tooltipPosition="top"
>|link|</a>

<a
  *ngIf="column.fieldType === 'phoneNumber'"
  (click)="component.onEditCellFocus($event, entity, column)"
>
  <span>{{ entity[column.property] }}</span>
  <i
    *ngIf="entity[column.property]"
    (click)="component.onEditCellPhoneIconList($event, entity, column)"
    class="fa fa-phone phone-number-entity-cell"
  ></i>
</a>

<div *ngIf="column.fieldType === 'color'">
  <div class="generic-grid-color-picker-color">
    <p-colorPicker
      appColorPicker
      [disabled]="component.isEditCellDisabled(entity, column)"
      (onChange)="component.onEditCell($event, entity, column)"
      [ngModel]="entity[column.property]"
      appendTo="body"
    ></p-colorPicker>
  </div>
  <div class="generic-grid-color-picker-input">
    <input
      type="text"
      [disabled]="component.isEditCellDisabled(entity, column)"
      (keyup)="component.onEditCell($event, entity, column)"
      pInputText
      [ngModel]="entity[column.property]"
      (focus)="component.onEditCellFocus($event, entity, column)"
    />
  </div>
</div>

<p-checkbox
  *ngIf="column.fieldType === 'permissionInformation' && entity && entity.permissionInformation && entity.permissionInformation[column.organisation]"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onInstantEditCell($event, entity, column)"
  [ngModel]="entity.permissionInformation[column.organisation][column.property]"
  binary="true"
  class="ui-column-editor-checkbox"
></p-checkbox>

<div *ngIf="column.fieldType === 'image'">
  <app-image-column
    [entity]="entity"
    [component]="component"
    [column]="column"
    [editMode]="false"
  ></app-image-column>
</div>

<span *ngIf="column.fieldType === 'moduleElementColumn'">
    <app-generic-column [entity]="entity" [component]="component" [column]="column.moduleElementColumn"></app-generic-column>
</span>
