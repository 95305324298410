
    <div class="form-element-input-upload simple-upload ui-simple-upload" *ngIf="!getFileUrl()">
      <input
        type="file"
        id="{{hashIdForUpload | genHash}}"
        (change)="onChangeFile($event)"
      />
      <label for="{{hashIdForUpload | genHash}}">
        <span class="fa fa-fw fa-upload"></span>
        <span class="upload-text">Durchsuchen</span>
      </label>
    </div>
    <div class="form-element-input-file-preview" *ngIf="getFileUrl()">
      <div class="form-element-input-file-link-container">
        <a [href]="getFileUrl()" target="_blank">{{getValue().fileName}}</a>
        <i class="fa fa-trash-o" aria-hidden="true" style="cursor: pointer;" (click)="removeFile($event)"></i>
      </div>
    </div>
  