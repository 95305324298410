
import {of as observableOf, Observable} from 'rxjs';
import {ChangeDetectorRef, Component, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {Wizard, WizardElement, WizardElementButton} from '../../../../services/element/wizard';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../../../../../core/executor/execution-step-payload';
import {ExecutionStatus} from '../../../../../core/executor/execution-status';
import {LayoutService} from '../../../../services/layout-service';
import {ModuleStateContext} from '../../../services/module-state';
import {WizardElementDetails, WizardService} from './service/wizard.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {PermissionService} from '../../../../services/permission/permission.service';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-wizard',
  styleUrls: ['./wizard.component.scss'],
  templateUrl: './wizard.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    WizardService
  ]
})
export class WizardComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  @ViewChild('wizardContainer', {static: false}) wizardContainer;
  @ViewChild('wizardElementModuleContainer', {static: false}) wizardElementModuleContainer;
  public wizardElementModuleContainerHeight = 400;

  public toolbarContextName = 'wizardComponent';

  public wizard: Wizard = null;
  public wizardElement = null;

  public constructor(
    public wizardService: WizardService,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected jobContainerService: JobContainerService,
    protected stepFactory: ExecutionStepFactoryService,
    protected layoutService: LayoutService,
    public executorActionsService: ExecutorActionsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.wizardService.wizardComponent = this;
    this.wizardService.executorService = this.executorService;

    this.elementContext = this.createContext();

    this.modulesStateService.getCurrent().addContext(ModuleStateContext.Wizard);

    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .subscribe();

    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => {
        this.setWizardElementModuleContainerHeight();
      }, 10);
    });

    this.genericCrudService.getEntity('superadmin/wizards/element', this.element.id)
      .subscribe((wizard: Wizard) => {
        this.setWizardElementModuleContainerHeight();
        this.initWizard(wizard);
      });
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public onWizardElementButtonClick(event, wizardElement: WizardElement, button: WizardElementButton): void {
    const step = this.stepFactory.createFromString(button.action, new ExecutionStepPayload({
      wizardComponent: this,
      wizardElement: wizardElement
    }));

    this.executorService.addStep(step)
      .execute()
      .subscribe((executionStatus: ExecutionStatus) => {

      });
  }

  public getExecutor(): ExecutorService {
    return this.executorService;
  }

  protected setWizardElementModuleContainerHeight(): void {
    const containerHeight = this.wizardContainer.nativeElement.clientHeight;

    if (containerHeight) {
      this.wizardElementModuleContainerHeight = containerHeight - 40;
    }
  }

  private initWizard(wizard: Wizard): void {
    this.wizard = wizard;

    this.wizardService.openFirst()
      .subscribe((wizardElementDetails: WizardElementDetails) => {
        this.wizardElement = wizardElementDetails.wizardElement;

        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  private createContext(): ElementContext {

    const elementContext = new ElementContext(
      this.moduleElement.id,
      ElementType.Wizard,
      this,
      this.moduleElement,
      true,
      false,
      false,
      false,
      false
    );

    return elementContext;
  }
}
