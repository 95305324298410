import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElementInputComponent } from './element-input.component';
import { ElementInputFileMultiUpload } from '../models/element-input-file-multi-upload';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import { GenericCrudService } from '../../services/generic-crud.service';
import {DataModel} from '../../services/element/data-model';
import {EntityFqnHelper} from '../../helpers/entity-fqn.helper';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {MultiUploadButtonComponent} from '../../components/upload/multi-upload-button/multi-upload-button.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-form-element-input-file-multi-upload',
    template: `
        <div class="ui-g-12">
            <app-multi-upload-button
                #uploadButton
                [mode]="'advanced'"
                [disabled]="disabled"
                [filePath]="element.filePath"
                [fileContext]="element.fileContext"
            ></app-multi-upload-button>
        </div>
    `,
    styles: [`
        :host {
          height: 100%;
        }
    `]
})
export class ElementInputFileMultiUploadComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() element: ElementInputFileMultiUpload;
    @ViewChild('uploadButton', {static: false}) uploadButton: MultiUploadButtonComponent;

    public fileClassName: any;

    public constructor(
        protected formService: FormService,
        public cdr: ChangeDetectorRef,
        protected formViewerService: FormViewerService,
        protected genericCrudService: GenericCrudService,
        protected elementRef: ElementRef,
        protected translateService?: TranslateService
    ) {
        super(formService, cdr, formViewerService, translateService);
    }

    get disabled() {
      return !this.entity || !this.fileClassName;
    }

  public onComponentChanges(): void {
    this.setupValue();
  }

  public onComponentInit(): void {
      this.setFormControlName();
      this.filterActionAndHandleIt('oninit');
  }

  public onComponentDestroy() { }

  public ngAfterViewInit() { }

  public setupValue(): void {
    if (this.element.datamodelId) {
      this.genericCrudService.getEntity(`superadmin/datamodels`, this.element.datamodelId)
        .subscribe((datamodel: DataModel) => {
          const fileClassName = EntityFqnHelper.getEntityFqn(datamodel);

          this.fileClassName = fileClassName;
          this.uploadButton.fileEntity = this.entity;
          this.uploadButton.fileClassName = fileClassName;

          if (this.uploadButton.fileEntity && this.uploadButton.fileClassName) {
            this.uploadButton.loadFiles().subscribe();
          }

          ChangeDetectorRefHelper.detectChanges(this);
        });
    }
  }
}
