
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {PreCalculation, PreCalculationArticle} from '../../../precalculation/article/pre-calculation-article';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';
import {EntityStatus} from '../../../entity/entity-status';

export class PrecalculationDetailsRecalculateArticlesStep extends AbstractExecutionStep {
  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    let component = payload.getValue();

    if (payload instanceof Object && payload.getValue().component) {
      component = payload.getValue().component;
    }

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass form viewer as Payload value!');
    }

    const entity = payload.getValue().entity;

    const factor = this.getAdditionalCalculationFactor(entity);

    const genericGrid = component.getElementContext().getSlaveElementContexts()[0].component;

    if (genericGrid instanceof AbstractGenericGridComponent) {
      for (const preCalculationArticle of genericGrid.getEntities()) {
        this.recalculateGeneralArticle(preCalculationArticle, entity, factor);

        genericGrid.getInlineEditService().markForCheck(entity);
      }
    }

    return observableOf({status: true, content: null});
  }

  protected recalculateGeneralArticle(preCalculationArticle: PreCalculationArticle, precalculation: PreCalculation, factor: number) {
    preCalculationArticle.invoiceMonth = preCalculationArticle.invoiceHour * precalculation.standardHoursDivider * factor;
    preCalculationArticle.salaryMonth = preCalculationArticle.salaryHour * precalculation.standardHoursDivider * factor;
    preCalculationArticle.selfCostsCalculatedMonth = preCalculationArticle.selfCostsCalculatedHour * precalculation.standardHoursDivider * factor;
    preCalculationArticle.selfCostsMonth = preCalculationArticle.selfCostsHour * precalculation.standardHoursDivider * factor;
    preCalculationArticle.targetInvoiceMonth = preCalculationArticle.targetInvoiceHour * precalculation.standardHoursDivider * factor;
    preCalculationArticle[EntityStatus.ENTITY_CHANGED_FLAG] = true;
  }

  protected getAdditionalCalculationFactor(precalculation: PreCalculation, inverse: boolean = false): number {
    if (precalculation.defaultWeeklyWorkingHours && precalculation.weeklyWorkingHours) {
      return inverse ? precalculation.defaultWeeklyWorkingHours / precalculation.weeklyWorkingHours
        : precalculation.weeklyWorkingHours / precalculation.defaultWeeklyWorkingHours;
    }
    return 1;
  }
}
