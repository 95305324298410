import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ElementInputComponent } from './element-input.component';
import { ElementInputRadio } from '../models';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';
import { FormElementAction } from '../models/form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-radio',
  template: `
    <div [formGroup]="formGroup">
      <p-radioButton
        appSeleniumDirective
        [element]="element"
        [formControlName]="formControlName"
        [name]="element.group "
        [value]="element.defaultValue"
        (onClick)="onValueChange($event)"
      ></p-radioButton>
    </div>
  `,
  styles: [`
    ::ng-deep .ui-radiobutton-icon {
      display: block;
      font-size: 10px;
    }
  `]
})
export class ElementInputRadioComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputRadio;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');
  }

  onComponentDestroy() {

  }

  ngAfterViewInit() {
  }

  public onValueChange(event) {
    this.setValue(this.element.defaultValue);
  }

  onFormElementAction(action: FormElementAction) {

  }
}
