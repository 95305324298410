import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Calendar, InputMask, InputText, OverlayPanel} from 'primeng/primeng';

@Component({
  selector: 'app-generic-calendar',
  templateUrl: './generic-calendar.component.html',
  styleUrls: ['./generic-calendar.component.scss']
})
export class GenericCalendarComponent implements OnInit {

  @Output() onInputChangeValid: EventEmitter<Date|null> = new EventEmitter();
  @Output() onSelect: EventEmitter<Date> = new EventEmitter();
  @Output() focus: EventEmitter<Date> = new EventEmitter();

  @ViewChild('overlay', {static: false}) overlay: OverlayPanel = null;
  @ViewChild('inputMask', {static: false}) inputMask: InputMask;
  @ViewChild('inputText', {static: false}) inputText: InputText;
  @ViewChild('inputElement', {static: false}) inputElement: Calendar;

  @Input() showOnFocus = false;
  @Input() dateFormat = '';

  @Input() timeOnly = false;
  @Input() placeholder = '';
  @Input() showIcon = true;
  @Input() disabled = false;
  @Input() yearRange = '';
  @Input() monthNavigator = true;
  @Input() yearNavigator = true;
  @Input() inputStyleClass = '';
  @Input() hourFormat = '24';
  @Input() view = 'date';

  private _mask = '';
  @Input() set mask(mask: string) {
    this._mask = mask;

    if (this.dateValue) {
      this.initValue(this.dateValue);
    }
  }
  get mask() {
    return this._mask;
  }

  private _showTime = false;
  @Input() set showTime(showTime: boolean) {
    this._showTime = showTime;

    if (this.dateValue) {
      this.initValue(this.dateValue);
    }
  }
  get showTime() {
    return this._showTime;
  }

  @Input() set value(value: Date|null|string) {
    this.initValue(value);
  };

  public inputValue = '';

  public dateValue: Date = null;
  public calendar: Calendar = null;

  public constructor() { }

  public ngOnInit(): void {
    this.initCalendar();
  }

  public initCalendar(): void {
    this.calendar = new Calendar(null, null, null, null);
    this.calendar.showTime = this.showTime;
    this.calendar.timeOnly = this.timeOnly;
    this.calendar.dateFormat = this.dateFormat;
    this.calendar.view = this.view;
  }

  public initValue(value): void {
    this.initCalendar();

    if (typeof value === 'string' && value !== '' && value !== null) {
      if (value.includes('+')) {
        value = value.substring(0, value.indexOf('+')); // remove after '+' (remove timezone)
      }

      value = new Date(value);
    }

    if (value instanceof Date) {
      this.inputValue = this.dateToString(value);
      this.dateValue = value;
    }else if (value === null) {
      this.inputValue = '';
      this.dateValue = null;
    }
  }

  public onClick(event): void {
    if (!this.disabled) {
      this.overlay.toggle(event);
    }
  }

  public onFocus(event): void {
    if (this.showOnFocus && !this.disabled) {
      this.overlay.show(event);

      this.focus.emit(event);
    }
  }

  public onInputKeyUp(event): void {
    this.onInputChangeValidCheck(event);
  }

  public onMaskInputKeyUp(event): void {
    if (this.inputMask.isCompleted()) {
      this.onInputChangeValidCheck({
        target: {
          value: this.inputValue
        }
      });
    } else {
      if (this.inputMask.getUnmaskedValue() === '') {
        this.onInputChangeValid.emit(null);
      }
    }
  }

  public onDateSelect(date: Date): void {
    this.value = date;
    this.onSelect.emit(date);

    if (!this.timeOnly) {
      this.overlay.hide();
    }
  }

  public onInputChangeValidCheck(event: any): void {
    const inputString = event.target.value;

    const date = this.getDate(inputString);

    // skip backspace, space, delete InputEvent codes
    if (date !== null && event.data !== null && event.data !== ' ') {

      if (!this.calendar.showTime) {
        this.onInputChange(date);
      }

      if (this.calendar.showTime && this.getTime(inputString) !== null) {
        const time = this.getTime(inputString);

        date.setHours(time.hour);
        date.setMinutes(time.minute);

        this.onInputChange(date);
      }
    }

    if (this.calendar.timeOnly) {
      let time = null;

      try {
        time = this.calendar.parseTime(inputString);
      } catch (e) {

      } finally {
        if (time !== null) {
          const timeDate = new Date();

          timeDate.setHours(time.hour);
          timeDate.setMinutes(time.minute);

          this.onInputChange(timeDate);
        }
      }
    }
  }

  public onInputChange(date: Date): void {
    this.value = date;
    this.onInputChangeValid.emit(date);
  }

  public onTodayButtonClick(event){
    this.inputElement.onTodayButtonClick(event);
  }

  private dateToString(date: Date): string {
    let dateString = '';

    if (this.calendar.dateFormat) {
      dateString = this.calendar.formatDate(date, this.calendar.dateFormat);
    }

    if (this.calendar.dateFormat && this.calendar.showTime) {
      dateString = this.calendar.formatDateTime(date);
    }

    if (this.calendar.timeOnly) {
      dateString = this.calendar.formatTime(date);
    }

    return dateString;
  }

  private getTime(dateString: string): {hour: number, minute: number, second: number} | null {
    let time = null;

    const splitDate = dateString.split(' ');

    if (splitDate[0] && splitDate[1]) {
      try {
        time = this.calendar.parseTime(splitDate[1]);
      } catch (e) {

      }
    }

    return time;
  }

  private getDate(dateString: string): Date|null {
    let date = null;

    if (dateString.indexOf(' ') !== -1) {
      dateString = dateString.split(' ')[0];
    }

    try {
      date = this.calendar.parseDate(dateString, this.calendar.dateFormat);
    } catch (e) {

    }

    return date;
  }
}
