import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import { Datamodel } from '../../../services/datamodel/datamodel';
import { DatamodelCrudService } from '../../../services/datamodel/datamodel.crud.service';
import { FormViewerService } from '../../form-viewer.service';

@Injectable()
export class FormColorFromDatamodelSourceActionHandler extends AbstractFormActionHandler {

    public constructor(
        private datamodelCrudService: DatamodelCrudService,
        private formViewerService: FormViewerService
    ) {
        super();
    }

    public handleAction(): void {
        let action = this.getAction(),
            entity = this.getEntity();

        const datamodelField: string = (action.params['datamodelField'] && action.params['datamodelField'].value) ? action.params['datamodelField'].value : '';

          let dm: Datamodel;

          if (entity && action.params['datamodel'] && action.params['datamodel'].value && this.datamodelCrudService && this.formViewerService && datamodelField) {
            const datamodelFieldValue = entity[datamodelField];
            dm = new Datamodel(action.params['datamodel'].value);

            this.datamodelCrudService.getDatamodelRecords(dm).subscribe((dmRecords: any[]) => {

              if (typeof datamodelFieldValue !== 'undefined' && datamodelFieldValue !== null) {
                const formFieldValue = (typeof datamodelFieldValue.id !== 'undefined') ? datamodelFieldValue.id : datamodelFieldValue;

                for (const record of dmRecords) {
                  if (record.id == formFieldValue) {
                      let color = record.color;
                      if (record.color && record.color.charAt(0) !== '#') {
                          /* tslint:disable:no-bitwise */
                          color = '#' + ('000000' + (record.color >>> 0).toString(16)).slice(-6);
                          /* tslint:enable:no-bitwise */
                      }

                      this.getComponent().formBackground = color;
                  }
                }
              }
            });
          }
    }
}
