import { ElementLabel } from '../models';
import {
  Component,
  Input,
  OnInit,
  ContentChild,
  AfterViewInit,
  AfterContentInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ElementComponent } from './element.component';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';
import {DatamodelCrudService} from '../../services/datamodel/datamodel.crud.service';
import { FormElementAction } from '../models/form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-label',
  template: `
    <div class="input-element"
      [ngClass]="getElementInputCssClass()"
      [fxLayout]="direction()"
      fxLayoutAlign="start center"
    >
      <label
        [fxFlex]="getLabelWidth()"
        [ngClass]="getLabelCssClass()"
        [ngStyle]="getLabelStyle()"
        appSeleniumDirective
        [element]="element"
        *ngIf="(element.showLabel && element.type !== 'placeholder') || element.type === 'placeholder'"
      >
        <ng-container *ngIf="element.showLabel">
          {{element.label}}
        </ng-container>

        <span class="hidden-content"></span>

        <ng-container *ngIf="checkRequired()"> *</ng-container>
      </label>
      <div *ngIf="insideNgContent"
        class="input-item"
        style="position: relative;"
        [fxFlex]="getInputWidth()"
        [class.input-item-required]="checkRequired()"
        [style.padding]="getInputPadding()"
        [style.align-self]="(element.textOnly ? 'center' : '')"
        [style.height]="getInputHeight()"
        (click)="onClickElement($event)"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`
    label,
    .input-item {
      width: 100%;
    }

    .input-item {
      width: 100%;
      padding: .5em;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: stretch;
      align-items: center;
    }

    .input-element.flex-layout-row label {
      padding: .8335em .5em;
    }

    .input-element.flex-layout-column label {
      padding: 1.6670em .5em 0;
    }

    .input-element.flex-layout-row .input-item {
      padding: .5em;
    }

    .input-element.flex-layout-column .input-item {
      padding: .5em;
    }
  `]
})
export class ElementLabelComponent extends ElementComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ContentChild('insideNgContent', {static: false}) insideNgContent;

  @Input() element: ElementLabel;

  labelStyleArray: string[] = [];

  originalLabel: string;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService,
    protected datamodelCrudService?: DatamodelCrudService
  ) {
    super(formService, cdr, formViewerService, translateService, datamodelCrudService);
  }

  onComponentInit() {
    this.originalLabel = this.element.label;
  }

  onComponentDestroy() {

  }

  onComponentAfterViewInit() {
  }

  ngAfterContentInit() {
  }

  onFormElementAction(action: FormElementAction) {

  }

  getWidth(formFirstWidth, formSecondWidth, firstWidth, secondWidth) {
    if (!formFirstWidth && !firstWidth && !formSecondWidth && !secondWidth) {
      return '100%';
    }

    if (!formFirstWidth && formSecondWidth && !firstWidth && !secondWidth) {
      const unit = formSecondWidth.replace(new RegExp(/[0-9]*/g), '');
      const value = +formSecondWidth.replace(unit, '');
      if (unit === '%' || unit === '') {
        return `${100 - value}%`;
      }
      return '';
    }

    if (formFirstWidth && !firstWidth) {
      let unit = formFirstWidth.replace(new RegExp(/[0-9]*/g), '');
      const value = +formFirstWidth.replace(unit, '');

      if (!unit) {
        unit = '%';
      }
      return `${value}${unit}`;
    }

    if (!firstWidth && secondWidth) {
      const unit = secondWidth.replace(new RegExp(/[0-9]*/g), '');
      const value = +secondWidth.replace(unit, '');
      if (unit === '%' || unit === '') {
        return `${100 - value}%`;
      }
      return '';
    }

    let unit = firstWidth.replace(new RegExp(/[0-9]*/g), '');
    const value = +firstWidth.replace(unit, '');

    if (!unit) {
      unit = '%';
    }
    return `${value}${unit}`;
  }

  getHeight(formInputHeight: string, elementInputHeight: string) {

    if (formInputHeight && !elementInputHeight) {
      const unit = formInputHeight.replace(new RegExp(/[0-9]*/g), '');
      const value = +formInputHeight.replace(unit, '');

      if (unit) {
        return `${value}${unit}`;
      }
    }

    if (elementInputHeight) {
      const unit = elementInputHeight.replace(new RegExp(/[0-9]*/g), '');
      const value = +elementInputHeight.replace(unit, '');

      if (unit) {
        return `${value}${unit}`;
      }
    }

    return '';
  }

  getLabelPadding() {
    const form = this.form;
    const formLabelPadding = form.labelPadding;
    const labelPadding = this.element.labelPadding;

    if (!formLabelPadding && !labelPadding) {
      return '';
    }
    if (formLabelPadding && !labelPadding) {
      return formLabelPadding;
    }
    if (labelPadding) {
      return labelPadding;
    }
  }

  getLabelWidth() {
    const form = this.form;
    const formLabelWidth = form.labelWidth;
    const formInputWidth = form.inputWidth;
    const labelWidth = this.element.labelWidth;
    const inputWidth = this.element.inputWidth;

    return this.getWidth(formLabelWidth, formInputWidth, labelWidth, inputWidth);
  }

  getLabelStyle() {
    return {
      'padding': this.getLabelPadding(),
      'order': this.element.orientation === 'right' ? 1 : 0
    };
  }

  labelStyleInArray(style: string) {
    if (this.element.labelStyle) {
      this.labelStyleArray = JSON.parse(this.element.labelStyle);
    } else {
      this.labelStyleArray = [];
    }
    const xy = this.labelStyleArray.find((val) => {
      return (style === val);
    });
    return (xy !== undefined && xy !== null && xy !== '');
  }

  getElementInputCssClass() {
    return {
      'flex-layout-row': (this.direction() === 'row'),
      'flex-layout-column': (this.direction() !== 'row')
    };
  }

  getLabelCssClass() {
    return {
      'italic': this.labelStyleInArray('italic'),
      'bold': this.labelStyleInArray('bold'),
      'underline': this.labelStyleInArray('underline'),
      'text-left': this.element.textAlign === 'left',
      'text-center': this.element.textAlign === 'center',
      'text-right': this.element.textAlign === 'right'
    };
  }

  getInputWidth() {
    const form = this.form;
    const formLabelWidth = form.labelWidth || '';
    const formInputWidth = form.inputWidth || '';
    const labelWidth = this.element.labelWidth || '';
    const inputWidth = this.element.inputWidth || '';

    return this.getWidth(formInputWidth, formLabelWidth, inputWidth, labelWidth);
  }

  getInputHeight() {
    const formInputHeight = this.form.inputHeight || '';
    const inputHeight = this.element.inputHeight || '';

    return this.getHeight(formInputHeight, inputHeight);
  }

  getInputPadding() {
    const form = this.form;
    const formInputPadding = form.inputPadding;
    const inputPadding = this.element.inputPadding;

    if (!formInputPadding && !inputPadding) {
      return '';
    }
    if (formInputPadding && !inputPadding) {
      return formInputPadding;
    }
    if (inputPadding) {
      return inputPadding;
    }
  }

  direction(): string {
    if (this.element.orientation === 'center') {
      return 'column';
    }
    return 'row';
  }

  checkRequired() {
    if (this.element['validators'] && this.element['validators'].length > 0) {
      const requiredValidator = this.element['validators'].find((validator) => {
        return (validator.key.toLowerCase() === 'validators.required');
      });

      if (requiredValidator) {
        return true;
      }
    }
    return false;
  }
}
