
    <div class="form-element-input-image-upload" *ngIf="!imageSrc">
      <div class="simple-upload" [formGroup]="formGroup">
        <input
          type="file"
          [readonly]="isReadOnly()"
          id="{{hashIdForUpload | genHash}}"
          (change)="onChangeImage($event)"
        />
        <label for="{{hashIdForUpload | genHash}}" *ngIf="!isReadOnly()">
          <span class="fa fa-fw fa-upload"></span><br />
          <span class="upload-text">Bild hochladen</span>
        </label>
      </div>
    </div>
    <div class="form-element-input-image-preview" style="width: 100px; height: 120px; display: grid; position: relative;" *ngIf="imageSrc">
      <img [src]="imageSrc" alt="Bild Vorschau" style="max-height: 100%; max-width: 100%;" />
      <div class="image-action" (click)="onRemoveImage($event)" *ngIf="!isReadOnly()">
        entfernen
      </div>
    </div>
  