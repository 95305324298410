import {Component, Input, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {ElementInputComponent} from './element-input.component';
import {ElementInputDate} from '../models/index';

import {FormElementValueChange, FormService} from './../form.service';
import {FormViewerService} from './../form-viewer.service';
import {DateHelper} from '../../helpers/date.helper';
import {Entity} from '../../helpers/entity';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-date',
  template: `
    <div class="container" [formGroup]="formGroup">
      <input type="hidden" [formControlName]="formControlName" />

      <app-generic-calendar
        appSeleniumDirective
        [element]="element"
        [value]="entityValue"
        (onInputChangeValid)="onInputChange($event)"
        (onSelect)="onSelect($event)"
        [mask]="this.element.inputFormat.mask"
        [dateFormat]="element.inputFormat.date.format"
        [showTime]="showTime()"
        [timeOnly]="timeOnly()"
        [placeholder]="element.placeholder"
        [showIcon]="element.showIcon"
        [disabled]="isReadOnlyOrDisabled() || !entity"
        [yearRange]="element.yearNavigator ? '1950:2050' : ''"
        [monthNavigator]="element.monthNavigator"
        [yearNavigator]="element.yearNavigator"
        [hourFormat]="element.inputFormat['date']['hourFormatPmAm'] ? 12 : 24"
        #inputElement
      ></app-generic-calendar>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    .container {
      height: 100%;
    }

    p-calendar ::ng-deep .ui-calendar {
      height: 100%;
      width: 100%;
    }

    p-calendar ::ng-deep .ui-inputtext {
      height: 100%;
    }

    p-calendar ::ng-deep input[disabled].ui-inputtext, p-calendar ::ng-deep input[disabled].ui-inputtext:focus {
      background-color: lightgrey;
      border-color: lightgrey;
      color: black;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      pointer-events: none;
      opacity: 1;
    }
  `]
})
export class ElementInputDateComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputDate;

  public entityValue: Date|string|null = null;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');

    this.subscriptions.push(
      this.formService.formElementAfterValueChanged$.subscribe((meta: FormElementValueChange) => {
        if (meta.element === this.element && meta.action === 'onselect') {
          this.onActionSelect();
        }
      })
    );
  }

  onComponentDestroy() {

  }

  onComponentChanges(): void {
    this.setupValue();
  }

  public setupValue(): void {
    if (this.entity && !this.entity[this.element.datamodelField]) {
      this.setupDefaultValue();
    } else if (this.entity && this.entity[this.element.datamodelField]) {
      this.setupEntityValue();
    }
  }

  ngAfterViewInit() {
  }

  public setValue(value: any, triggerChange: boolean = true, options: Object = {}, updateFormComponent = true, fromAction: string = '') {
    this.entityValue = value;

    if (typeof (value) !== 'undefined') {
      this.formService.onFormElementValueChange({
        formControlValue: value,
        element: this.element,
        entityValue: value instanceof Date ? DateHelper.toISOLocalWithoutTimezone(value) : value,
        formControlName: this.formControlName,
        formControlOptions: options,
        triggerChange: triggerChange,
        entity: this.entity,
        component: this,
        updateFormComponent: updateFormComponent,
        action: fromAction
      });
    }

    return this;
  }

  public showTime(): boolean {
    if (this.element.dateFormat === 'date+time' || this.element.dateFormat === 'time') {
      return true;
    }
    return false;
  }

  public timeOnly(): boolean {
    if (this.element.dateFormat === 'time') {
      return true;
    }
    return false;
  }

  getDateFormat() {

  }

  protected setupDefaultValue(): void {
    this.setFormControlName();
    let value = this.element.defaultValue;
    if (value && typeof value === 'string' && value === 'today') {
      value = new Date();
    }
    if (value && typeof value === 'string' && DateHelper.isIso(value)) {
      value = new Date(value);
    }

    if (this.entity && value) {
      this.setValue(value, false);
    }else if (this.entity && !value){
      this.setValue(null, false);
    }
  }

  protected setupEntityValue(): void {
    if (this.entity && this.element.datamodelField) {
      const value = Entity.getValue(this.entity, this.element.datamodelField);

      this.setValue(value, false);
    }
  }

  public onInputChange(date: Date|null): void {
    this.setValue(date);
  }

  public onSelect(event): void {
    const date = event;

    if (!isNaN(date.getTime())) {
      this.setValue(date, true, {}, true, 'onselect');
    }
  }
}
