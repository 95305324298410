<div class="ui-grid ui-grid-responsive ui-fluid">
  <div class="ui-grid-row">
    <div class="ui-grid-col-12">
      <div class="ui-grid ui-grid-responsive ui-fluid">
        <div class="ui-grid-row">
          <div class="ui-grid-col-12" *ngIf="items.length > 0 && display==='inline'">
            <p-splitButton
              label="{{ 'FILE.ADD_FILES' | translate }}"
              [model]="items"
              [disabled]="disabled"
              icon="fa fa-paperclip"
              styleClass="ui-button-info"
              [style]="{'width': '100%'}"
              [menuStyle]="{'width': '400px'}"
              (click)="onOpenUpload($event)"
              (onDropdownClick)="onDropdownClick($event)"
              appendTo="body"
            ></p-splitButton>
          </div>

          <div class="ui-grid-col-12" *ngIf="items.length === 0">
            <button
              pButton
              type="button"
              [disabled]="disabled"
              label="{{ 'FILE.ADD_FILES' | translate }}"
              icon="fa fa-paperclip"
              class="ui-button-info"
              (click)="onOpenUpload($event)"
            >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog
  *ngIf="isUploadVisible"
  [(visible)]="isUploadVisible"
  [modal]="true"
  [style]="{width: '40vw'}"
  [maximizable]="false"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-fileUpload
    chooseLabel="{{ 'FILE.CHOOSE' | translate }}"
    uploadLabel="{{ 'FILE.UPLOAD' | translate }}"
    cancelLabel="{{ 'FILE.CANCEL' | translate }}"
    invalidFileLimitMessageDetail="{{ 'FILE.LIMIT_MESSAGE_DETAIL' | translate }}"
    invalidFileLimitMessageSummary="{{ 'FILE.LIMIT_MESSAGE_SUMMARY' | translate }}"
    mode="{{mode}}"
    name="{{name}}"
    url="{{url}}"
    accept="{{accept}}"
    multiple="true"
    maxFileSize="1000000000000"
    customUpload="true"
    [fileLimit]="fileLimit"
    (onClear)="onClear()"
    (uploadHandler)="onUpload($event)"
    (onSelect)="onSelect($event)"
  >
    <ng-template pTemplate="toolbar">
      <div *ngIf="filePath" [style.marginTop.px]="10">Path: {{ filePath }}</div>
    </ng-template>
    <ng-template let-i="index" let-file pTemplate="file">
      <div class="ui-g">
        <div class="ui-g-4">
          <img alt="{{file.name}}" [src]="file.objectURL" *ngIf="fileUpload.isImage(file)" [width]="100"/>
        </div>
        <div class="ui-g-6">
          {{ file.name }}
        </div>
        <div class="ui-g-1">
          {{ fileUpload.formatSize(file.size) }}
        </div>
        <div class="ui-g-1">
          <button type="button" icon="pi pi-times" pButton (click)="fileUpload.remove($event,i)"
                  [disabled]="fileUpload.uploading"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div *ngFor="let item of items" class="ui-g">
        <div class="ui-g-4">
          <img alt="{{item.file.name}}" [src]="getDownloadUrl(item.file)" *ngIf="isImage(item.file)" [width]="100"/>
        </div>
        <div class="ui-g-6">
          {{ item.file.name }}
        </div>
        <div class="ui-g-1">
          {{ fileUpload ? fileUpload.formatSize(item.file.fileSize) : '' }}
        </div>
        <div class="ui-g-1">
          <button type="button" icon="pi pi-download" pButton (click)="onItemDownload(item)"></button>
          <button type="button" icon="pi pi-times" pButton (click)="onItemRemove(item)"></button>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
</p-dialog>
