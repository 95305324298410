import { Injectable } from '@angular/core';
import { ToolbarItem } from "../../../../../services/element/toolbar-item";
import { ToolbarItemDisabledCheckInterface } from "./toolbar-item-disabled-check-factory.service";
import { ToolbarItemAbstractDisabledCheckService } from "./toolbar-item-abstract-disabled-check.service";
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {ElementsStackService} from '../../../../services/elements-stack.service';
import {ComponentService} from '../../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';

@Injectable()
export class ToolbarItemSaveDisabledCheckService extends ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {

  constructor(
    protected elementStackService: ElementsStackService,
    protected componentService: ComponentService,
    protected modulesStateService: ModulesStateService,
    protected authenticationService: AuthenticationService
  ) {
    super(elementStackService, componentService, modulesStateService, authenticationService);
  }

  isDisabled(): boolean {
    let isDisabled = (this.toolbarItem.contextTarget === ToolbarItem.CONTEXT_TARGET_MODULE) ? this.isDisabledInModuleContext()
      : this.isDisabledInComponentContext(this.entity, this.component);

    if (this.isInAutocompleteViewModule() && this.isComponentGridAndEntityIsSelected()) {
      isDisabled = false;
    }

    return isDisabled;
  }

  private isComponentGridAndEntityIsSelected(): boolean {
    return this.component instanceof AbstractGenericGridComponent && this.component.getSelectedEntity();
  }

  private isInAutocompleteViewModule(): boolean {
    const currentModule = this.modulesStateService.getCurrent();

    return currentModule && currentModule.isAutocompleteView;
  }
}
