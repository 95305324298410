/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i2 from "primeng/components/checkbox/checkbox";
import * as i3 from "@angular/forms";
import * as i4 from "../../directives/selenium.directive";
import * as i5 from "../../../../../node_modules/primeng/components/tristatecheckbox/tristatecheckbox.ngfactory";
import * as i6 from "primeng/components/tristatecheckbox/tristatecheckbox";
import * as i7 from "@angular/common";
import * as i8 from "./element-input-checkbox.component";
import * as i9 from "../form.service";
import * as i10 from "../form-viewer.service";
import * as i11 from "@ngx-translate/core";
var styles_ElementInputCheckboxComponent = ["[_nghost-%COMP%]    > div[_ngcontent-%COMP%] {\n      display: inline-block;\n    }\n\n    [_nghost-%COMP%]    > div[_ngcontent-%COMP%]    > p-checkbox[_ngcontent-%COMP%]     .ui-chkbox {\n      padding-top: .48em;\n      padding-bottom: .48em;\n    }"];
var RenderType_ElementInputCheckboxComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputCheckboxComponent, data: {} });
export { RenderType_ElementInputCheckboxComponent as RenderType_ElementInputCheckboxComponent };
function View_ElementInputCheckboxComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "p-checkbox", [["appSeleniumDirective", ""], ["binary", "true"]], [[8, "hidden", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onElementValueChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Checkbox_0, i1.RenderType_Checkbox)), i0.ɵdid(1, 49152, null, 0, i2.Checkbox, [i0.ChangeDetectorRef], { binary: [0, "binary"] }, { onChange: "onChange" }), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.Checkbox]), i0.ɵdid(3, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i0.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i0.ɵdid(6, 4210688, null, 0, i4.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "true"; _ck(_v, 1, 0, currVal_8); var currVal_9 = _co.formControlName; _ck(_v, 3, 0, currVal_9); var currVal_10 = _co.element; _ck(_v, 6, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.isHidden; var currVal_1 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 5).ngClassValid; var currVal_6 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_ElementInputCheckboxComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "p-triStateCheckbox", [["appSeleniumDirective", ""], ["binary", "true"]], [[8, "hidden", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onElementValueChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TriStateCheckbox_0, i5.RenderType_TriStateCheckbox)), i0.ɵdid(1, 49152, null, 0, i6.TriStateCheckbox, [i0.ChangeDetectorRef], null, { onChange: "onChange" }), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.TriStateCheckbox]), i0.ɵdid(3, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i0.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i0.ɵdid(6, 4210688, null, 0, i4.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.formControlName; _ck(_v, 3, 0, currVal_8); var currVal_9 = _co.element; _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.isHidden; var currVal_1 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 5).ngClassValid; var currVal_6 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ElementInputCheckboxComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { inputElement: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputCheckboxComponent_1)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputCheckboxComponent_2)), i0.ɵdid(8, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 2, 0, currVal_7); var currVal_8 = !_co.element.isNullable; _ck(_v, 6, 0, currVal_8); var currVal_9 = _co.element.isNullable; _ck(_v, 8, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ElementInputCheckboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-checkbox", [], null, null, null, View_ElementInputCheckboxComponent_0, RenderType_ElementInputCheckboxComponent)), i0.ɵdid(1, 6012928, null, 1, i8.ElementInputCheckboxComponent, [i9.FormService, i0.ChangeDetectorRef, i10.FormViewerService, i11.TranslateService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputCheckboxComponentNgFactory = i0.ɵccf("app-form-element-input-checkbox", i8.ElementInputCheckboxComponent, View_ElementInputCheckboxComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputCheckboxComponentNgFactory as ElementInputCheckboxComponentNgFactory };
