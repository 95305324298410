
    <div class="button-wrapper">
      <button
        pButton
        [style.width]="getButtonWidth()"
        [iconUrl]="getButtonImageSource(element)"
        [label]="getButtonLabel(element.label)"
        (click)="onClickElement($event)"
        [disabled]="isReadOnlyOrDisabled() || !entity"
      ></button>
    </div>
  