
    <div class="container" [formGroup]="formGroup">
      <textarea *ngIf="!element.isEditor"
        pInputTextarea
        appSeleniumDirective
        [element]="element"
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readOnly]="isReadOnly()"
        [style.font-weight]="getFontWeight()"
        (keyup)="keyUp.next($event)"
        (keydown)="keyDown.next($event)"
        #inputElement
      ></textarea>

      <div
      class="editor-container"
      *ngIf="element.isEditor"
      [froalaEditor]="editorOptions"
      [formControlName]="formControlName"
      >
      </div>
    </div>
  