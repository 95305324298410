import {
  ComponentFactoryResolver,
  Injectable,
  ComponentRef
} from '@angular/core';
import { Module } from '../../../../services/module/module';
import { GenericDialogModuleComponent } from '../generic-dialog-module.component';
import {Observable} from 'rxjs';
import { GenericElementAbstract } from '../../generic-element-abstract.component';
import { cloneDeep } from 'lodash';
import {ElementContext} from '../../../services/ElementContext';
import {ModulesStateService} from "../../../services/modules-state.service";
import {ModuleState} from "../../../services/module-state";

export interface GenericDialogOptions {
  height?: number;
  width?: number;
  isModal?: boolean;
  isAutocompleteModuleState?: boolean;
  onClose?: Function;
  afterClose?: Function;
  entity?: any;
  masterEntity?: any;
  masterElementContext?: ElementContext;
  masterFilterField?: string;
  masterFilterValue?: any;
  masterField?: string;
  masterEntityEditingField?: string;
  maximized?: boolean;
}

@Injectable()
export class GenericDialogModuleService {

  protected rootViewContainer: any;

  protected hideDialogCallerComponent: GenericElementAbstract = null;

  protected collection: GenericDialogModuleComponent[] = [];

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private modulesStateService: ModulesStateService
  ) { }

  public setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  public showDialog(module: Module, options: GenericDialogOptions) {
    options = options || {};

    const factory = this.factoryResolver.resolveComponentFactory(GenericDialogModuleComponent);

    const component = factory.create(this.rootViewContainer.parentInjector);

    component.instance.dialogService = this;
    component.instance.module = module;
    component.instance.options = options;
    component.instance.dialogHeight = options.height;
    component.instance.dialogWidth = options.width;
    component.instance.isAutocompleteModuleState = options.isAutocompleteModuleState || false;
    component.instance.beforeClose = Observable.create((observer) => {
      let shouldClose = true;

      if (options.onClose) {
        shouldClose = options.onClose(this.hideDialogCallerComponent);
      }

      observer.next(shouldClose);
      observer.complete();
    });

    component.instance.afterClose = Observable.create((observer) => {

      if (options.afterClose) {
        options.afterClose(this.hideDialogCallerComponent);
      }

      observer.next(true);
      observer.complete();
    });

    this.rootViewContainer.insert(component.hostView);
  }

  public hideDialog(): void {
    const moduleState = this.modulesStateService.getCurrent();

    if (moduleState && moduleState.module) {
      const component = this.getComponent(moduleState.module);

      if (null !== component) {
        component.onCloseDialog(null);
      }
    }
  }

  public persistHide(): void {
    const moduleState = this.modulesStateService.getCurrent(),
      component = this.getComponent(moduleState.module);

    if (null !== component) {
      component.doClose();
    }
  }

  public getCallerComponent(): GenericElementAbstract|null {
    const moduleState = this.modulesStateService.getCurrent(),
      component = this.getComponent(moduleState.module);

    return component && component.options && component.options.masterElementContext ?
      component.options.masterElementContext.component : null;
  }

  public createState(component: GenericDialogModuleComponent): void {
    this.setModuleState(component.module, component.options);
    this.addComponent(component);
  }

  public removeState(component: GenericDialogModuleComponent): void {
    this.removeModuleState(component.module);
    this.removeComponent(component);
  }

  public setModuleState(module, options: GenericDialogOptions): void {
    const currentModuleState = this.modulesStateService.getCurrent();

    const moduleState = new ModuleState(
      module.id,
      module,
      '',
      {},
      false,
      null,
      false,
      [],
      true,
      false,
      options.isAutocompleteModuleState,
      currentModuleState
    );

    this.modulesStateService.add(moduleState);
  }

  public removeModuleState(module): void {
    const moduleState = this.modulesStateService.getCurrent();

    if (moduleState.id === module.id) {
      this.modulesStateService.remove(moduleState);
    }
  }

  public addComponent(component: GenericDialogModuleComponent): void {
    this.collection.push(component);
  }

  public removeComponent(component: GenericDialogModuleComponent): void {
    const index = this.collection.findIndex((aComponent: GenericDialogModuleComponent) => {
      return aComponent.module.id == component.module.id;
    });

    if (index !== -1) {
      this.collection.splice(index, 1);
    }
  }

  public getComponent(module): GenericDialogModuleComponent|null {
    const index = this.collection.findIndex((aComponent: GenericDialogModuleComponent) => {
      return aComponent.module.id == module.id;
    });

    return this.collection[index] || null;
  }

  public hideDialogWithSave(callerComponent: GenericElementAbstract): void {
    const moduleState = this.modulesStateService.getCurrent(),
      component = this.getComponent(moduleState.module);

    if (null !== component) {
      this.hideDialogCallerComponent = callerComponent;

      component.onCloseDialog(null);

      this.modulesStateService.remove(
        moduleState
      );

      this.hideDialogCallerComponent = null;
    }
  }
}
