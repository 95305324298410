/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../generate-hash.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./element-input-upload.component";
import * as i4 from "../form.service";
import * as i5 from "../form-viewer.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@ngx-translate/core";
var styles_ElementInputUploadComponent = [".form-element-input-file-preview {\n      min-height: 25px;\n      display: flex;\n      flex-direction: row;\n      flex-wrap: nowrap;\n      justify-content: flex-start;\n      align-content: stretch;\n      align-items: center;\n    }\n\n      .form-element-input-file-preview .form-element-input-file-link-container {\n      order: 0;\n      flex: 0 0 auto;\n      align-self: auto;\n    }"];
var RenderType_ElementInputUploadComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputUploadComponent, data: {} });
export { RenderType_ElementInputUploadComponent as RenderType_ElementInputUploadComponent };
function View_ElementInputUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "form-element-input-upload simple-upload ui-simple-upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "input", [["type", "file"]], [[8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 4, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "fa fa-fw fa-upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "upload-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Durchsuchen"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.hashIdForUpload)), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.hashIdForUpload)), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_ElementInputUploadComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "form-element-input-file-preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "form-element-input-file-link-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash-o"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFileUrl(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getValue().fileName; _ck(_v, 3, 0, currVal_1); }); }
export function View_ElementInputUploadComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.GenerateHashPipe, []), i0.ɵqud(671088640, 1, { inputElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputUploadComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputUploadComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.getFileUrl(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getFileUrl(); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ElementInputUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-upload", [], null, null, null, View_ElementInputUploadComponent_0, RenderType_ElementInputUploadComponent)), i0.ɵdid(1, 6012928, null, 1, i3.ElementInputUploadComponent, [i4.FormService, i0.ChangeDetectorRef, i5.FormViewerService, i6.DomSanitizer, i7.TranslateService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputUploadComponentNgFactory = i0.ɵccf("app-form-element-input-upload", i3.ElementInputUploadComponent, View_ElementInputUploadComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputUploadComponentNgFactory as ElementInputUploadComponentNgFactory };
