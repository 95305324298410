<li (mouseenter)="onMouseOver($event)" (mouseleave)="onMouseLeave($event)" *ngFor="let item of items; let i = index">
    <a (click)="onItemClick(item)" *ngIf="!item.isSeparator && !item.isCustomMenuItem" [pTooltip]="item.tooltip"
       appSeleniumDirective
       [menuItem]="item"
       [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
        <div *ngIf="doShowImageContainer(item)" class="item-container item-image-container">
            <img *ngIf="item.iconPath" src="{{iconsPath}}/{{item.iconPath}}"
                 appSeleniumDirective
                 [menuItem]="item"
            />
        </div>
        <div class="item-container item-text-container">
            {{item.label}}
        </div>
        <div class="item-container item-container-arrow">
            <i *ngIf="item.items && item.items.length > 0" class="item-arrow-icon {{this.levelIcon}}"></i>
            <!-- fa-caret-right -->
        </div>
    </a>

    <app-module-opener-menu-item
            *ngIf="!item.isSeparator && item.isCustomMenuItem && item.customMenuItem == 'moduleOpener'"
            [item]="item"
            appSeleniumDirective
            [menuItem]="item"
    ></app-module-opener-menu-item>

    <app-bdm-export-menu-item
            *ngIf="!item.isSeparator && item.isCustomMenuItem && item.customMenuItem == 'bdmExport'"
            [item]="item"
            appSeleniumDirective
            [menuItem]="item"
    ></app-bdm-export-menu-item>

    <app-bdm-export-special-assignments-menu-item
            *ngIf="!item.isSeparator && item.isCustomMenuItem && item.customMenuItem == 'bdmExportSpecialAssignments'"
            [item]="item"
            appSeleniumDirective
            [menuItem]="item"
    ></app-bdm-export-special-assignments-menu-item>

    <app-bdm-export-invoices-menu-item
            *ngIf="!item.isSeparator && item.isCustomMenuItem && item.customMenuItem == 'bdmExportInvoices'"
            [item]="item"
            appSeleniumDirective
            [menuItem]="item"
    ></app-bdm-export-invoices-menu-item>

    <app-bonus-time-import-menu-item
            *ngIf="!item.isSeparator && item.isCustomMenuItem && item.customMenuItem == 'bonusTimeImport'"
            [item]="item"
            appSeleniumDirective
            [menuItem]="item"
    ></app-bonus-time-import-menu-item>

    <!-- horizontal separator -->
    <hr *ngIf="item.isSeparator && !isFirstLevel"/>

    <!-- vertical separator, first level -->
    <span class="item-vertical-line-container item-vertical-line-container-first-level"
          *ngIf="item.isSeparator && isFirstLevel"></span>

    <!-- recursion FTW -->
    <ul class="{{menuPosition}}" *ngIf="item.items && item.items.length > 0 && !item.isSeparator" app-generic-menu-item
        [menuPosition]="menuPosition" [iconsPath]="iconsPath" [isFirstLevel]="false" [items]="item.items"></ul>
</li>
