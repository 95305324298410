import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {DuplicateEntitiesGridComponent} from './duplicate-entities-grid.component';
import {FormViewerComponent} from '../../../form-viewer.component';
import {Entity} from '../../../../helpers/entity';
import {cloneDeep} from 'lodash';

export class DuplicateEntityCheckFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  public handleAction(): void {
    const entity = this.getEntity();
    if (typeof entity === 'undefined') {
      return;
    }
    if (!this.getAction().hasOwnProperty('additionalData') || typeof this.getAction().additionalData === 'undefined') {
      this.getAction().additionalData = {};
    }
    if (!this.getAction().hasOwnProperty('additionalData') || typeof this.getAction().additionalData === 'undefined'
      || typeof this.getAction().additionalData['duplicateEntityConditionItems'] === 'undefined'
    ) {
      this.loadDuplicateConditionItemsAndCheck();
    } else {
      this.doCheck();
    }
  }

  private doCheck() {
    if (this.shouldCheck()) {

      this.saveCheckedValues();
      const entity = cloneDeep(this.getEntity());
      entity.id = null;

      this.genericCrudService.customPost(
        `app/duplicateentityconditions/${this.getAction().params['duplicateEntityCondition'].value.value}/duplicatedEntities`,
        entity
      ).subscribe((entities) => {
          if (entities.length > 0) {
            const duplicatedEntities = entities.map((aEntity) => {
              return aEntity.id
            });
            this.openModal(duplicatedEntities);
          }
        });
    }
  }

  private loadDuplicateConditionItemsAndCheck() {
    this.genericCrudService.getEntities('app/duplicateentityconditionitems', '',
      {duplicateEntityCondition: this.getAction().params['duplicateEntityCondition'].value.value}).subscribe((items) => {
      this.getAction().additionalData['duplicateEntityConditionItems'] = items;
      this.getAction().additionalData['duplicateEntityConditionFields'] = items.map(item => {
        return item.datamodelField;
      });
      this.doCheck();
    });
  }

  private shouldCheck(): boolean {
    const forceChecking = this.getAction().params['forceChecking'].value || false;
    const isConditionalField
      = this.getAction().additionalData['duplicateEntityConditionFields'].indexOf(this.getElement().datamodelField) !== -1;
    return (isConditionalField || forceChecking) && !this.checkIsSameValueAsBefore();
  }

  private saveCheckedValues() {
    if (typeof this.getAction().additionalData['duplicateEntityConditionCheckValue'] === 'undefined') {
      this.getAction().additionalData['duplicateEntityConditionCheckValue'] = [];
    }
    this.getAction().additionalData['duplicateEntityConditionCheckValue'][this.getElement().datamodelField]
      = Entity.getValue(this.getEntity(), this.getElement().datamodelField);
  }

  private checkIsSameValueAsBefore(): boolean {
    if (
      typeof this.getAction().additionalData['duplicateEntityConditionCheckValue'] !== 'undefined'
      && typeof this.getAction().additionalData['duplicateEntityConditionCheckValue'][this.getElement().datamodelField] !== 'undefined'
    ) {
      const oldValue = this.getAction().additionalData['duplicateEntityConditionCheckValue'][this.getElement().datamodelField]
      return oldValue === Entity.getValue(this.getEntity(), this.getElement().datamodelField)
    }
    return false;
  }

  private openModal(duplicatedEntities: number[]) {
    const formComponent = this.getComponent();
    if (formComponent instanceof FormViewerComponent) {
      const targetModuleId = this.getAction().params['targetModule'].value,
        modalWidth = this.getAction().params['modalWidth'].value || '500',
        modalHeight = this.getAction().params['modalHeight'].value || '300';
      this.genericCrudService.getEntities(`superadmin/modules/${targetModuleId}`).subscribe((targetModule) => {
        const ref = formComponent.dialogService.open(DuplicateEntitiesGridComponent, {
          header: targetModule.label,
          data: {
            entity: this.getEntity(),
            module: targetModule,
            justAFilter: { name: 'id', value: `in:${duplicatedEntities.join(',')}`}
          },
          width: `${modalWidth}px`,
          height: `${modalHeight}px`,
          closable: false,
        });
      });
    }
  }
}
