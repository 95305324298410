import { ElementInput } from './element';

export class ElementInputTextarea extends ElementInput {
  constructor(
    public typeElement = 'textarea',
    public defaultValue = '',
    public placeholder = '',
    public editorRows = 2,
    public editorToolbarButtonsConfiguration = 'default',
    public isEditor = false
  ) {
    super();

    if (this.isEditor && this.editorRows < 4) {
      this.editorRows = 4;
    }

    if (!this.inputHeight) {
      this.inputHeight = '150px';
    }
  }
}
