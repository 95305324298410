
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {catchError, map, switchMap} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../../core/executor/execution-step-status';
import {WizardComponent} from '../../../../../content-renderer/elements/custom/wizard/wizard.component';
import {ModuleState} from '../../../../../content-renderer/services/module-state';
import {ElementContext, ElementType} from '../../../../../content-renderer/services/ElementContext';
import {GenericDialogModuleService} from '../../../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {WizardElementDetails} from '../../../../../content-renderer/elements/custom/wizard/service/wizard.service';
import {WizardElement} from '../../../../element/wizard';
import {GenericDynamicTreeOldComponent} from '../../../../../content-renderer/elements/generic-dynamic-tree-old/generic-dynamic-tree-old.component';
import {EntityStatus} from '../../../../entity/entity-status';

export class WizardPrecalculationFinishExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue();

    const wizardComponent = payloadValue.wizardComponent,
      wizardElement = payloadValue.wizardElement;

    if (!(wizardComponent instanceof WizardComponent)) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.doSet(wizardComponent, wizardElement);
  }

  protected doSet(wizardComponent: WizardComponent, wizardElement: WizardElement): Observable<ExecutionStepStatus> {
    const elementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(wizardElement);

    const preCalculation = elementDetails.inputDetails.entity,
      inquiryPosition = this.getEntityFromOpenerContext(wizardComponent.masterElementContext);

    if (preCalculation && inquiryPosition) {
      return this.savePreCalculation(preCalculation).pipe(
        switchMap((savedPreCalculation) => {
          return this.saveInquiryPosition(inquiryPosition, savedPreCalculation).pipe(
            switchMap((savedInquiryPosition) => {
              return this.saveTreeChangedEntities(elementDetails).pipe(
                map(() => {
                  this.getGrowl().success(
                    this.getTranslate().instant('COMMON.DATA_SAVED'),
                    this.getTranslate().instant('COMMON.SUCCESS')
                  );

                  this.getDialog().persistHide();

                  return {status: true, content: null};
                }));
            }));
      }));
    }

    return observableOf({status: true, content: null});
  }

  protected savePreCalculation(fromEntity: any): Observable<any> {
    if (fromEntity.id) {
      return this.genericCrudService
        .editEntity(`phoenix/precalculations/${fromEntity.id}`, fromEntity).pipe(
        map((savedPreCalculation) => {
          return savedPreCalculation;
        }));
    } else {
      return this.genericCrudService
        .createEntity(`phoenix/precalculations`, fromEntity).pipe(
        map((savedPreCalculation) => {
          return savedPreCalculation;
        }));
    }
  }

  protected saveTreeChangedEntities(elementDetails): Observable<any> {
    const tree = this.getTree(elementDetails.moduleState);

    return Observable.create((observer) => {
      if (!tree) {
        observer.next({
          status: true,
          content: []
        });
        observer.complete();
      }

      const entities = tree.getUpdatedEntities();
      const observables = [];

      for (const entity of entities) {
        entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
        observables.push(this.genericCrudService.editEntity(`phoenix/precalculationotherarticles/${entity.id}`, entity));
      }

      if (observables.length === 0) {
        observer.next({
          status: true,
          content: []
        });
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(results => {
          observer.next({
            status: true,
            content: results
          });
          observer.complete();
        });
    });
  }

  protected getGrowl(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  protected getTranslate(): TranslateService {
    return this.injector.get(TranslateService, null);
  }

  protected getDialog(): GenericDialogModuleService {
    return this.injector.get(GenericDialogModuleService, null);
  }

  private getEntityFromOpenerContext(masterElementContext: ElementContext): any {
    let entity = null;

    if (masterElementContext && masterElementContext.type === ElementType.Grid) {
      entity = masterElementContext.component.getSelectedEntity();
    }

    return entity;
  }

  private saveInquiryPosition(inquiryPosition: any, preCalculation: any): Observable<any> {
    inquiryPosition.preCalculation = preCalculation;
    inquiryPosition._embedded.preCalculation = preCalculation;
    inquiryPosition[EntityStatus.ENTITY_CHANGED_FLAG] = true;

    if (inquiryPosition.id) {
      return this.genericCrudService
        .editEntity(`phoenix/inquirypositions/${inquiryPosition.id}`, inquiryPosition).pipe(
        map((savedInquiryPosition) => {
          return savedInquiryPosition;
        }));
    } else {
      delete inquiryPosition[EntityStatus.ENTITY_CHANGED_FLAG];

      return this.genericCrudService
        .createEntity(`phoenix/inquirypositions`, inquiryPosition).pipe(
        map((savedInquiryPosition) => {
          inquiryPosition.id = savedInquiryPosition.id;

          return savedInquiryPosition;
        }));
    }
  }

  private getTree(moduleState: ModuleState): GenericDynamicTreeOldComponent|null {
    let tree = null;

    for (const component of moduleState.getComponents()) {
      if (component.getElementContext() && component.getElementContext().type === ElementType.Tree) {
        tree = component;
      }
    }

    return tree;
  }
}
