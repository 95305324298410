import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { DoubleClickService } from '../../../../../services/double-click.service';
import { cloneDeep } from 'lodash';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-entity-import-dialog',
  templateUrl: './toolbar-item-entity-import-dialog.component.html'
})
export class ToolbarItemEntityImportDialog extends ToolbarItemAbstract {

  private toolbarItem: ToolbarItem;
  private entity: any;

  constructor(
    private messageGrowlService: MessageGrowlService,
    private doubleClickService: DoubleClickService
  ) {
    super();
  }

  ngOnInit() {

  }

  public click() {
    const component = this.getComponent();

    if (this.getToolbarItem().actionModuleId && component instanceof AbstractGenericGridComponent) {
        this.doOpenModuleInGridContext(component);
    }
  }

  private doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();

    if (gridComponent.selectedEntity) {
      this.entity = gridComponent.selectedEntity;
    } else {
      this.entity = cloneDeep(gridComponent.emptyEntity);
    }

    if (!this.validateInGridContext(gridComponent)) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }

    this.doubleClickService.setGrid(gridComponent)
      .setDialogOptions({
        height: +this.getToolbarItem().targetDialogHeight,
        width: +this.getToolbarItem().targetDialogWidth
      })
      .handleOpenModule(gridComponent, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, this.entity);
  }

  private validateInGridContext(gridComponent) {
    let isValid = true;

    if (this.getToolbarItem().onclick === 'edit' && !this.entity) {
      this.setValidateMessage('Edit called but no entity has been selected!');

      isValid = false;
    } else if (!this.toolbarItem.actionModuleId) {
      this.setValidateMessage('Toolbar Item action module not assigned.');

      isValid = false;
    }

    return isValid;
  }
}
