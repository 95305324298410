
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {GenericElementAbstract} from './generic-element-abstract.component';
import {Element} from 'app/shared/services/element/element';
import {FieldMetadataGrid} from '../../services/module/module-element-field-metadata-grid';
import {ChangeDetectorRef, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {ComponentService} from '../services/component-highlight-stack.service';
import {EntityDataStoreService} from 'app/shared/content-renderer/services/entity-data-store.service';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {GenericGridColumnBuilderService} from './generic-grid/services/generic-grid-column-builder.service';
import {ElementState} from 'app/shared/content-renderer/services/element-state';
import {
  CreatedEntitiesAware,
  DraftAware,
  DraftDeleteAware,
  UpdateEntitiesAware
} from 'app/shared/generic-element.typings';
import {AbstractGenericElementEntityService} from 'app/shared/content-renderer/services/generic/entity/abstract-generic-element-entity.service';
import {GenericGridBulkSaveService} from './generic-grid/services/generic-grid-bulk-save.service';
import {EntityDirtyStoreService} from '../services/entity-dirty-store.service';
import {RequestCachingService} from '../../services/request-caching.service';
import {ToolbarItemCheckService} from 'app/shared/content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementInlineEditorService} from 'app/shared/content-renderer/services/generic/generic-element-inline-editor.service';
import {PermissionService} from 'app/shared/services/permission/permission.service';
import {ElementSaveStatus} from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {EventHandlerService} from '../../../core/events/event/event-handler.service';
import {ConfirmationService, OverlayPanel, Paginator, TreeNode} from 'primeng/primeng';
import {TranslateService} from '@ngx-translate/core';
import {GenericElementFilterService} from 'app/shared/content-renderer/services/generic/filter/generic-element-filter.service';
import {GenericCrudService} from '../../services/generic-crud.service';
import {EntityData, EntityDataChangeMeta} from '../services/entity-data-store.service';
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {ExecutorService} from '../../../core/executor/executor.service';
import {EntityValidator, EntityValidatorStatus} from '../../validators/services/entity-validator';
import {ElementsStateService} from '../services/elements-state.service';
import {CancelComponentChangesService} from 'app/shared/content-renderer/services/cancel-components-changes.service';
import {ActionsEvent} from 'app/shared/services/action/action';
import {FieldActionsService} from '../services/field-actions.service';
import {ExecutorActionEvent} from 'app/core/executor/service/executor-actions/executor-action-event';
import {ExecutionStatus} from '../../../core/executor/execution-status';
import {EntityStatus} from '../../services/entity/entity-status';
import {LocalStorageDataService} from '../../services/local-storage-data.service';
import {ExpanderData} from '../../services/expander/expander-data';
import {FilterItem} from '../../services/element/filter-item';
import {Debounce} from '../../helpers/debounce';
import {ElementType} from '../services/ElementContext';
import {ExecutionStepBuilderService} from '../../../core/executor/builder/execution-step-builder.service';
import {Constants} from '../../../constants';
import {LayoutService} from '../../services/layout-service';
import {EventHelper} from "../../helpers/event.helper";
import {GenericDialogModuleService} from './generic-dialog/service/generic-dialog-module.service';
import {GenericGridRemoteFilterService} from './generic-grid/services/generic-grid-remote-filter.service';
import {environment} from '../../../../environments';
import {GenericElementEmbeddedService} from '../services/generic/generic-element-embedded.service';
import {GenericElementContextMenuService} from '../services/generic/generic-element-context-menu.service';
import {Entity} from '../../helpers/entity';
import {EntityManagerService} from '../../../core/service/entity-manager/entity-manager.service';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../core/job-runner/type/runnable-event.registry';
import {Guid} from 'guid-typescript';
import {JobContainerService} from '../../../core/job-runner/job-container.service';
import {GenericFilterComponent} from './generic-filter/generic-filter.component';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {GenericTurboGridLayoutService} from './generic-turbo-grid/service/generic-turbo-grid-layout-service';
import {UserSessionService} from '../../../core/service/user-session.service';

export interface StaticGridFilterField {
  field: string;
  value: any;
}

export abstract class AbstractGenericGridComponent extends GenericElementAbstract implements DraftAware, CreatedEntitiesAware,
    UpdateEntitiesAware, DraftDeleteAware {

    public uiCellDisabledFlag = Constants.UI_CELL_DISABLED_FLAG;

    @Output() public onSelectionChanged: EventEmitter<any[]> = new EventEmitter();

    @Input() fields: Array<FieldMetadataGrid>;
    @Input() expanderData: ExpanderData;

    @Input() public selectedEntities: any[] = [];
    @Input() public selectedNodes: TreeNode[] = [];
    @Input() masterEntity: any = null;
    @Input() masterEntityEditingField: any = null;

    /**
     * @description fires when view is initialized
     * https://stackoverflow.com/a/41095677
     */
    @ViewChild('gridContainer', {static: false}) set gridContainer(gridContainer: ElementRef) {
        this._gridContainer = gridContainer;

        if (this._gridContainer && this._gridContainer.nativeElement) {
            this.onGridRendered();
        }
    }

    @ViewChild('dt', {static: false}) grid;

    @ViewChild('dialog', {static: false}) dialog;
    @ViewChild('paginator', {static: false}) paginator: Paginator = null;
    @ViewChild('filterComponent', {static: false}) filterComponent: GenericFilterComponent;

    public _gridContainer: ElementRef = null;

    public scrollHeight = '1px'; // (GU) :: this will look awfull at first render, add some overlay
    public scrollWidth = '1px';

    public gridFilters: {[filterKey: string]: {value?: any, matchMode?: string, type?: string, labelValue?: any, stateValue?: any}} = {};
    public apiRoutAdditionalParameters: any = {};

    public staticFilters: StaticGridFilterField[] = [];
    public simpleSearchFilters: StaticGridFilterField[] = [];

    public sortField = 'id';
    public sortDirection: string = Element.SORT_DIRECTION_ASC;

    public defaultPageSize: number = 5;
    public totalCount: number = 0;
    public currentOffset: number = 0;
    public currentPage: number = 0;
    public firstEntry: number = 0;
    public isDataLoading: boolean = false;
    public dataLoaded: boolean = false;
    public responseFormat = '';

    public associatedOptions: Object = {};
    public associatedOptionsValues: Object = {};
    public associatedFilterOptions: Object = {};
    public selectableMonths: SelectItem[] = this.genericElementFilterService.getSelectableMonths();
    public autocompleteFilterColumnValues: Object = {};
    public embeddedFields: any[] = [];

    public columns: any[] = [];
    public entities: any[] = [];

    public selectedEntity: any = null;
    public emptyEntity: any = null;
    public emptyEntityInitDone = false;

    public componentState: ElementState = null;

    public isFilterContainerVisible = false;

    public updatedEntities: any[] = [];
    public createdEntities: any[] = [];
    public draftDeletedEntities: any[] = [];

    public abstract setEmptyEntity();
    public abstract loadEntities(): Observable<EntityData>;
    public abstract getRemoteDynamicFilters(): Object;
    public abstract onGridRendered();
    public abstract addCreatedEntityDraft(entity: any);

    @HostListener('document:keydown', ['$event']) onDocumentKeyDown(event) {
      if (this.isMouseOver && event.ctrlKey && event.key === 'a') {
        this.startNewEntityAdd();
        event.preventDefault();
      }
    }

    public constructor(
        protected componentService: ComponentService,
        protected viewContainerRef: ViewContainerRef,
        protected entityDataStoreService: EntityDataStoreService,
        protected modulesStateService: ModulesStateService,
        protected genericGridColumnBuilderService: GenericGridColumnBuilderService,
        protected genericElementEntityService: AbstractGenericElementEntityService,
        protected genericElementInlineEditorService: GenericElementInlineEditorService,
        protected permissionService: PermissionService,
        protected genericGridBulkSaveService: GenericGridBulkSaveService,
        protected entityDirtyStore: EntityDirtyStoreService,
        protected entityDataStore: EntityDataStoreService,
        protected requestCachingService: RequestCachingService,
        protected toolbarItemCheckService: ToolbarItemCheckService,
        protected eventHandlerService: EventHandlerService,
        protected confirmationService: ConfirmationService,
        protected translationService: TranslateService,
        protected genericElementFilterService: GenericElementFilterService,
        protected cancelComponentChangesService: CancelComponentChangesService,
        protected executorService: ExecutorService,
        protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
        protected entityValidator: EntityValidator,
        protected elementStateService: ElementsStateService,
        protected genericCrudService: GenericCrudService,
        protected fieldActionsService: FieldActionsService,
        protected userSession: UserSessionService,
        protected executionStepBuilderService: ExecutionStepBuilderService,
        protected layoutService: LayoutService,
        protected genericDialogModuleService: GenericDialogModuleService,
        protected genericGridRemoteFilterService: GenericGridRemoteFilterService,
        protected embedded: GenericElementEmbeddedService,
        protected contextMenu: GenericElementContextMenuService,
        protected entityManager: EntityManagerService,
        protected jobContainerService: JobContainerService,
        public cdr: ChangeDetectorRef
    ) {
        super(componentService, viewContainerRef, entityDataStoreService,
          modulesStateService, executorService, genericElementValidationExecutionStepsFactory, entityValidator,
          genericCrudService, userSession, permissionService, cdr);

        this.genericGridColumnBuilderService.firstSetGrid(this);
        this.genericElementEntityService.setComponent(this);
        this.genericElementInlineEditorService.setComponent(this);
        this.genericElementFilterService.setComponent(this);
        this.genericElementValidationExecutionStepsFactory.setComponent(this);
    }

    public recheckToolbarItems(): void {
      this.toolbarItemCheckService.check(this);
    }

    public onDialogShow(event): void {
      const dialogMaximizeButtons = document.getElementsByClassName('ui-dialog-titlebar-maximize');

      if (dialogMaximizeButtons.length > 0) {
        const dialogButton = dialogMaximizeButtons[0];

        dialogButton.addEventListener('click', () => {
          this.layoutService.onLayoutSizeChanged();
        });
      }
    }

    public areAllFiltersDefined(): boolean {
      return true;
    }

    public selectLastOrFirstEntity(): void {
      console.log('not implemented yet');
    }

    public getInlineEditService(): GenericElementInlineEditorService {
      return this.genericElementInlineEditorService;
    }

    public getCancelChangesService(): CancelComponentChangesService {
      return this.cancelComponentChangesService;
    }

    public findEntity(entity: any): any {
        return this.genericElementEntityService.findEntity(entity);
    }

    public findEntityById(entityId: number): any {
      return this.genericElementEntityService.findEntityById(entityId);
    }

    public addEntity(entity): void {
        return this.genericElementEntityService.addEntity(entity);
    }

    public startNewEntityAdd(): void {

    }

    public removeEntity(entity: any) {
        this.entityDirtyStore.remove(entity);

        this.genericElementEntityService.removeEntity(entity);

        this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
            .notifyMaster(ExecutorActionEvent.EntitiesChanged);

        this.executeAction(ExecutorActionEvent.EntitiesChanged, this)
            .subscribe();
    }

    public softDeleteEntity(entity: any) {
      this.removeEntity(entity);

      this.entityDataStore.softDelete(this.getElementDataModelApiRoute(), entity);
    }

    public replaceEntity(entity, forceReplace: boolean = false): this {
        this.genericElementEntityService.replaceEntity(entity, forceReplace);
        return this;
    }

    public findEntityIndex(entity): number {
      return this.genericElementEntityService.getEntityIndex(entity);
    }

    public removeEntityFlag(entity, property: string): this {
      delete entity[property];
      return this;
    }

    public resetSelectedEntity() {
      if (this.selectedEntity) {
        const entity = this.findEntity(this.selectedEntity);

        if (entity) {
          this.setSelectedEntity(entity);
        }
      }
    }

    public embedEntity(entityToEmbed: any, collectionName: string) {
      if (this.getSelectedEntity()) {
        this.genericElementEntityService.embedEntity(this.getSelectedEntity(), entityToEmbed, collectionName);
      }
    }

    public clearEntities(): void {
      this.entities = [];
    }

    public reselectEntity(): void {
      this.resetSelectedEntity();
    }

    public getChangedEntities(): Array<any> {
      const changedEntities = [];

      for (const updatedEntity of this.getUpdatedEntities(true)) {
        changedEntities.push(updatedEntity);
      }

      for (const createdEntity of this.getCreatedEntities(true)) {
        changedEntities.push(createdEntity);
      }

      return changedEntities;
    }

    public getEntities(): Array<any> {
      return this.genericElementEntityService.getEntities();
    }

    public getSelectedEntity(): any {
        return this.selectedEntity || null;
    }

    public setSelectedEntity(entity: any): any {
        this.selectedEntity = entity;
    }

    public selectEntity(entity): this {
      this.selectedEntity = entity;

      return this;
    }

    public onEntitySelected(originalEvent = null, fieldKey = null, userTrigger: boolean = false): void {

    }

    public getUpdatedEntities(shelved: boolean = false) {
        return this.genericElementEntityService.getUpdatedEntities(shelved);
    }

    public getCreatedEntities(shelved: boolean = false) {
        return this.genericElementEntityService.getCreatedEntities(shelved);
    }

    public getEmbeddedEntitiesChanged() {
        return this.genericElementEntityService.getEmbeddedEntitiesChanged();
    }

    public getDraftDeletedEntities(shelved: boolean = false) {
        return this.genericElementEntityService.getDraftDeletedEntities(shelved);
    }

    public onEditCell(event, entity: any, column: any) {
        const isChangingCell = EventHelper.isTabPressed(event) || EventHelper.isEnterPressed(event) || (event && event.code === 'blur');

        if (!isChangingCell && this.permissionService.isGranted(column, 'edit')) {
            this.selectedEntity = entity;

            const oldValue = this.genericElementInlineEditorService.getValue(entity, column);

            this.genericElementInlineEditorService.onEdit(event, entity, column);

            const currentValue = this.genericElementInlineEditorService.getValue(entity, column);

            this.flagEntityForBulkSave(entity, currentValue, oldValue).subscribe((canBeChanged: boolean) => {
              if (canBeChanged) {
                this.changeEntityValue(entity, column, currentValue, oldValue, event);
              }
            });
        }
    }

    public changeEntityValue(entity: any, column: any, newValue: any = null, oldValue: any = null, event: any = null): void {
      const field: FieldMetadataGrid = (column && column.field) ? column.field : null;

      if (field.isAssociatedField) {
        const associatedEntity = Entity.getValue(entity, field.entityName) ||
          Entity.getValueInEmbedded(entity, field.entityName);

        if (associatedEntity instanceof Object) {
          this.entityManager.persist(associatedEntity, {
            property: EntityStatus.ENTITY_CHANGED_FLAG,
            newValue: true
          });
          this.entityManager.persist(associatedEntity, {
            property: field.entityFieldName,
            newValue: associatedEntity[field.entityFieldName]
          });

          this.entityManager.persist(entity, {
            property: field.entityName,
            newValue: associatedEntity,
            force: true
          });

          newValue = associatedEntity;
        }
      } else {
        const fieldName = !field.name.includes('.') ?
          field.name :
          field.name.substring(0, field.name.lastIndexOf('.'));

        this.entityManager.persist(entity, {property: fieldName, oldValue: oldValue, newValue: newValue });
      }

      this.genericElementInlineEditorService.markForCheck(entity);

      this.getEntityDataStore()
        .onEntityValueChanged(this.getElementDataModelApiRoute(), {
          entity: entity,
          gridField: field,
          value: newValue,
          oldValue: oldValue,
          event: event
        });
    }

    public onInstantEditCell(event, entity: any, column: any) {
        if (this.permissionService.isGranted(column, 'edit')) {
            this.genericElementInlineEditorService.onEdit(event, entity, column);
        }
    }

    public flagEntityForBulkSave(entity: any, currentValue?: any, oldValue?: any): Observable<boolean> {
        return this.genericElementEntityService.flagEntityForBulkSave(entity, currentValue, oldValue);
    }

    public onEditCellSearch(event, entity: any, column: any) {
      Debounce.debounce(() => {
        this.genericElementInlineEditorService.onSearch(event, entity, column).subscribe();
      }, 10);

    }

    public onEditCellBlur(event, entity: any, column: any) {
        this.genericElementInlineEditorService.onBlur(event, entity, column);

        this.executeAction(ExecutorActionEvent.InlineEditCellLeave, {
          component: this,
          entityDataChangeMeta: {
            'entity': entity,
            'gridField': column.field
          }
        }).subscribe();
    }

    public getDownloadUrl(suffix: string): string {
      return environment.uploadUrl + '/' + suffix;
    }

    public onEditCellBeforeFocus(event, entity: any, column: any) {
      this.triggerFieldActions(event, column.field.id, ActionsEvent.BeforeFocus);
    }

    public onEditCellFocus(event, entity: any, column: any) {
        this.genericElementInlineEditorService.onFocus(event, entity, column).subscribe();
    }

    public onFilterCellFocus(event, column: any) {
      this.genericGridRemoteFilterService.onFocus(event, null, column);
    }

    public onEditCellPhoneIconList(event, entity: any, column: any): void {
      this.setSelectedEntity(entity);

      this.triggerFieldActions(event, column.field.id, ActionsEvent.PhoneIconClick);
    }

    public getEntityAssociatedManyEntityValue(entity: any, column: any) {
      const associatedEntitiesPath = column.id.substr(0, column.id.indexOf('.')),
        associatedEntityPropertyPath = column.id.substring(column.id.indexOf('.') + 1);

      let value = '';

      if (associatedEntitiesPath) {
        let embeddedEntities = entity[associatedEntitiesPath] && entity[associatedEntitiesPath] instanceof Array ?
          entity[associatedEntitiesPath] :
          null;

        if (!embeddedEntities === null &&
          associatedEntitiesPath &&
          entity._embedded &&
          entity._embedded &&
          entity._embedded[associatedEntitiesPath] instanceof Array
        ) {
          embeddedEntities = entity._embedded[column.entityName];
        }

        if (embeddedEntities instanceof Array) {
          for (let i = 0; i < embeddedEntities.length; i++) {
            const embeddedEntity = embeddedEntities[i];

            value += Entity.getValue(embeddedEntity, associatedEntityPropertyPath) ||
              Entity.getValueInEmbedded(embeddedEntity, associatedEntityPropertyPath);

            if (i + 1 !== embeddedEntities.length) {
              value += ', ';
            }
          }
        }
      }

      return value;
    }

    public onContextMenuOpen(event: MouseEvent, overlayPanel: OverlayPanel, column?: any, entity?: any): void {
      const element = this.moduleElement.element;

      if (element && element.contextMenuCount > 0) {
        this.contextMenu.showOverlay(event, overlayPanel, this);
      }
    }

    public getEditCellValue(entity: any, column: any) {
        if (this.getSelectedEntity() &&
          this.getSelectedEntity()[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]
        ) {
          return this.genericElementInlineEditorService.getValue(entity, column);
        }
        return null;
    }

    public isEditCellVisible(entity): boolean {
      return !entity.isSummary;
    }

    public isEditCellDisabled(entity: any, column: any) {
      if (this.getSelectedEntity() && this.getSelectedEntity()[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return this.genericElementInlineEditorService.isEditDisabled(entity, column);
      }
      return false;
    }

    public isEditCellTextEditor(entity: any, column: any) {
        return this.genericElementInlineEditorService.isEditCellTextEditor(entity, column);
    }

    /**
     * Clears the shelved internal entity changes.
     * @returns {GenericGridComponent}
     */
    public clearShelvedChanges() {
        return this.genericElementEntityService.clearShelvedChanges();
    }

    public hasChanges(checkEmbedded: boolean = false): boolean {
        return this.genericElementEntityService.hasChanges(checkEmbedded);
    }

    public hasShelvedChanges(): boolean {
        return this.genericElementEntityService.hasShelvedChanges();
    }

    /**
     * Shelves all the internal entity changes.
     * @returns {GenericGridComponent}
     */
    public shelveChanges(): AbstractGenericGridComponent {
        this.clearShelvedChanges();
        this.updatedEntities = this.getUpdatedEntities();
        this.createdEntities = this.getCreatedEntities();

        return this;
    }

    public addShelved(): AbstractGenericGridComponent {
        this.genericElementEntityService.addShelved();

        return this;
    }

    public onSave(): Observable<ElementSaveStatus> {
        return this.genericGridBulkSaveService.firstSetGrid(this).thenCommitChanges().finallyValidateAndPush();
    }

    public doValidate(): Observable<EntityValidatorStatus> {
        return this.genericGridBulkSaveService.firstSetGrid(this).thenCommitChanges().validate();
    }

    // damn this needs good refactoring... we will do that in turbogrid!
    public onChange(entityDataChangeMeta: EntityDataChangeMeta): Observable<any> {

        if (!this.selectedEntity) {
            return observableOf(entityDataChangeMeta.entity);
        }

        if (entityDataChangeMeta.entity) {
            entityDataChangeMeta.entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
        }

        this.replaceEntity(entityDataChangeMeta.entity);

        if ((entityDataChangeMeta.entity.id === this.selectedEntity.id) ||
            (entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]
            === this.selectedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {
            this.setSelectedEntity(entityDataChangeMeta.entity);
        }

        const column = this.genericGridColumnBuilderService.firstSetGrid(this).findColumnBy(entityDataChangeMeta.datamodelField, 'entityName');

        if (column && entityDataChangeMeta.value) {
            let value = (entityDataChangeMeta.value['id']) ? entityDataChangeMeta.value['id'] : entityDataChangeMeta.value;

            this.handleAssociatedEntityChanged(column, entityDataChangeMeta.entity, value);
        }

        if (entityDataChangeMeta.gridField) {
            this.triggerFieldActions(event, entityDataChangeMeta.gridField, ActionsEvent.EntityValueChange);
        }

        this.notifySlaves(ExecutorActionEvent.EntityValueChanged, entityDataChangeMeta)
            .notifyMaster(ExecutorActionEvent.EntityValueChanged);

        return Observable.create((observer) => {
            this.executeAction(ExecutorActionEvent.EntityValueChanged, {
                event: event,
                component: this,
                entityDataChangeMeta: entityDataChangeMeta
            }).subscribe((status: ExecutionStatus) => {
                observer.next(entityDataChangeMeta.entity);
                observer.complete();
            });
        });
    }

    /**
     * @description - use it to remove current state of component
     */
    public onAfterSave(): Observable<any> {
        return Observable.create((observer) => {
            this.createdEntities = [];
            this.updatedEntities = [];
            this.draftDeletedEntities = [];

            this.entityDirtyStore.removeFQN(this.getElementDatamodelEntityName());
            this.requestCachingService.removeByExpression(this.getElementDataModelApiRoute());

            observer.next();
            observer.complete();
        });
    }

    public onDraftDelete(): Observable<any> {
        return Observable.create((observer) => {

            if (this.selectedEntity && !this.entityDataStore.isEntityDeleted(this.selectedEntity)) {
                this.selectedEntity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] = true;
            }

            if (this.selectedEntity && !this.selectedEntity.id) {
                this.removeEntity(this.selectedEntity);
            }

            this.toolbarItemCheckService.check(this);

            this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
                .notifyMaster(ExecutorActionEvent.EntitiesChanged);

            this.executeAction(ExecutorActionEvent.EntitiesChanged, this)
                .subscribe();

            observer.next(status);
            observer.complete();
        });
    }

    public onForceRefresh(): Observable<any> {
      this.requestCachingService.removeByExpression(this.getElementDataModelApiRoute());
      return this.loadEntities();
    }

    public onRefresh(): Observable<any> {
        if ('' === this.getElementDataModelApiRoute()) {
            console.log('Module element has no datamodel assigned!');
        }

        return Observable.create((observer) => {

          this.reloadChangedEntities().subscribe((status) => {
            this.reselectEntity();

            this.createdEntities = [];
            this.updatedEntities = [];
            this.draftDeletedEntities = [];

            this.entityDirtyStore.removeFQN(this.getElementDatamodelEntityName());
            this.requestCachingService.removeByExpression(this.getElementDataModelApiRoute());

            const elementState = this.elementStateService.findById(this.moduleElement.id);

            if (elementState) {
              elementState.clearCreatedElements();
              elementState.clearUpdatedElements();
            }

            this.executeAction(ExecutorActionEvent.EntitiesRefresh, this)
                .subscribe((status: ExecutionStatus) => {
                    this.recheckToolbarItems();
                });

            ChangeDetectorRefHelper.detectChanges(this);
            this.emptyEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();

            observer.next();
            observer.complete();
          });
        });
    }

    public onRefreshEntities(entities: any[]): Observable<any> {
        if ('' === this.getElementDataModelApiRoute()) {
            console.log('Module element has no datamodel assigned!');
        }

        return Observable.create((observer) => {

          this.reloadEntities(entities).subscribe((status) => {
            this.reselectEntity();

            for (let entity of entities) {
              let createdEntityIndex = this.createdEntities.findIndex(createdEntity => {
                return createdEntity[EntityStatus.ENTITY_DRAFT_FLAG] == entity[EntityStatus.ENTITY_DRAFT_FLAG];
              });
              this.createdEntities.splice(createdEntityIndex,1);

              let updatedEntityIndex = this.updatedEntities.findIndex(updatedEntity => {
                return updatedEntity[EntityStatus.ENTITY_DRAFT_FLAG] == entity[EntityStatus.ENTITY_DRAFT_FLAG];
              });
              this.updatedEntities.splice(updatedEntityIndex,1);

              let draftDeletedEntityIndex = this.draftDeletedEntities.findIndex(draftDeletedEntity => {
                return draftDeletedEntity[EntityStatus.ENTITY_DRAFT_FLAG] == entity[EntityStatus.ENTITY_DRAFT_FLAG];
              });
              this.draftDeletedEntities.splice(draftDeletedEntityIndex,1);

              this.entityDirtyStore.remove(entity);
              this.requestCachingService.removeByExpression(this.getElementDataModelApiRoute());

              const elementState = this.elementStateService.findById(this.moduleElement.id);

              if (elementState) {
                elementState.removeCreatedElement(entity);
                elementState.removeUpdatedElement(entity);
              }
            }

            this.executeAction(ExecutorActionEvent.EntitiesRefresh, this)
                .subscribe((status: ExecutionStatus) => {
                    this.recheckToolbarItems();
                });

            observer.next();
            observer.complete();
          });
        });
    }

    public onCancelEntityChanges(entity): Observable<any> {
      const index = this.entities.findIndex(r => r.id === entity['id']) || 0;

      return this.genericCrudService.getEntity(this.element.datamodel.apiRoute, entity['id']).pipe(
        map((aEntity) => {
          if (this.getElementDatamodelEntityName() === aEntity.fqn && aEntity.id) {
            this.replaceEntity(aEntity);
            if (this.selectedEntity && this.selectedEntity.id === aEntity.id) {
              this.selectEntity(aEntity).onEntitySelected();
            }

            ChangeDetectorRefHelper.detectChanges(this);
          }

          return aEntity;
        }));
    }

    public getRowStyleClass(entity) {
      let style = '';

      style += entity && entity[EntityStatus.ENTITY_CHANGED_FLAG] ? ' dirty-entity-row' : '';
      style += entity && entity.hasExpired ? ' expired-entity-row' : '';
      style += entity && (entity.tmpIsDeleted || entity.deletedAt) ? ' deleted-entity-row' : '';
      style += entity && (entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] || entity[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG]) ?
        ' draft-deleted-entity-row' : '';
      style += entity && entity[EntityStatus.ENTITY_INVALID_FLAG] ? ' invalid-entity-row' : '';
      style += entity && !entity['id'] ? ' draft-entity-row' : '';
      style += entity && entity.cssColor ? ' background-' + entity.cssColor : '';
      style += entity && entity.isLocked ? ` ${GenericTurboGridLayoutService.LOCKED_ENTITY_ROW_STYE}` : '';
      style += entity && entity.isSummary ? ` ${GenericTurboGridLayoutService.SUMMARY_ENTITY_ROW_STYE}` : '';

      return style;
    }

    public getCellBackgroundColor(entity: any, column: any): string {
      const isDisabled = this.isEditCellDisabled(entity, column);

      if (column.Style && column.Style.entities &&
        column.Style.entities[entity[EntityStatus.ENTITY_DRAFT_FLAG]] &&
        column.Style.entities[entity[EntityStatus.ENTITY_DRAFT_FLAG]].backgroundColor
      ) {
        return column.Style.entities[entity[EntityStatus.ENTITY_DRAFT_FLAG]].backgroundColor;
      }

      return isDisabled && entity.disabledColor ? entity.disabledColor : '';
    }

    protected reloadChangedEntities(): Observable<any> {
      return Observable.create((observer) => {
        const observables = [];

        for (const createdEntity of this.getCreatedEntities(true)) {
          observables.push(
            this.getGenericCrudService()
            .getEntityBy(
              this.getElementDataModelApiRoute(),
              EntityStatus.ENTITY_DRAFT_FLAG,
              createdEntity[EntityStatus.ENTITY_DRAFT_FLAG],
              {
                'embedded': this.embedded.getEmbeddedAsString(this)
              }
            )
          );
        }

        for (const updatedEntity of this.getUpdatedEntities(true)) {
          observables.push(
            this.getGenericCrudService()
              .getEntity(
                this.getElementDataModelApiRoute(),
                updatedEntity.id,
                '',
                {
                  'embedded': this.embedded.getEmbeddedAsString(this)
                }
              )
          );
        }

        for (const embeddedUpdatedEntity of this.getEmbeddedEntitiesChanged()) {
          observables.push(
            this.getGenericCrudService()
              .getEntity(
                this.getElementDataModelApiRoute(),
                embeddedUpdatedEntity.id
              )
          );
        }

        for (const draftDeletedEntity of this.getDraftDeletedEntities(true)) {
          delete draftDeletedEntity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG];
          this.removeEntity(draftDeletedEntity);
        }

        if (observables.length === 0) {
          observer.next();
          observer.complete();
        }

        observableForkJoin(observables).subscribe((results) => {
          for (const resultingEntity of results) {
            this.replaceEntity(resultingEntity, true);
          }
          observer.next();
          observer.complete();
        });
      });
    }

    public reloadEntities(entities: any[]): Observable<any> {
      return Observable.create((observer) => {
        const observables = [];

        for (const entity of entities) {
          observables.push(
            this.getGenericCrudService()
            .getEntityBy(
              this.getElementDataModelApiRoute(),
              EntityStatus.ENTITY_DRAFT_FLAG,
              entity[EntityStatus.ENTITY_DRAFT_FLAG]
            )
          );
        }

        if (observables.length === 0) {
          observer.next();
          observer.complete();
        }

        observableForkJoin(observables).subscribe((results) => {
          for (const resultingEntity of results) {
            this.replaceEntity(resultingEntity, true);
          }
          observer.next();
          observer.complete();
        });
      });
    }

    public removeCreatedEntity(entity) {
        this.removeEntity(entity);
    }

    public removeUpdatedEntity(entity) {
        delete entity[EntityStatus.ENTITY_CHANGED_FLAG];

        this.removeEntity(entity);
    }

    public initColumns(): this {
        this.genericGridColumnBuilderService.thenSetupColumns();

        return this;
    }

    public addPermissionsColumns(): this {
      this.genericGridColumnBuilderService.thenSetupPermissionsColumns();

      return this;
    }

    public findField(fieldId: string) {
        return this.genericGridColumnBuilderService.findField(fieldId);
    }

    public getColumnBuilder(): GenericGridColumnBuilderService {
      return this.genericGridColumnBuilderService;
    }

    public clearFilters(): this {
        this.grid.filters = [];
        this.gridFilters = {};
        this.simpleSearchFilters = [];

        if (this.componentState) {
            this.componentState.filters = {};
        }

        return this;
    }

    public onFilter(event: any, filterItem: FilterItem, triggerFiltering: boolean = true) {
        this.genericElementFilterService.onFilter(event, filterItem, triggerFiltering);

        const field = filterItem.field || null;

        if (field) {
          this.triggerFieldActions(event, filterItem.field.id, ActionsEvent.FilterValueChange);
        }
    }

    public onFilterAutocompleteSearch(event: any, column: any) {
        this.genericElementFilterService.onFilterAutocompleteSearch(event, column);
    }

    // todo :: move every module, element, details context to component service scope as providers
    public onGoBackToTable() {
        this.showDetailedOverview = false;
    }

    public moduleElementTargetElementDialogHide() {
        this.moduleElementTargetElement = null;
    }

    public onDialogMaximize(event) {
      this.dialog.toggleMaximize(event);
      window.dispatchEvent(new Event('resize'));
    }

    public getGenericCrudService(): GenericCrudService {
      return this.genericGridBulkSaveService.getGenericCrudService();
    }

    protected getEntityCollectionName(): string|null {
      if (this.element && this.element.datamodel) {
        return this.element.datamodel.entityCollectionName;
      }

      return null;
    }

    protected handleAssociatedEntityChanged(column, entity, value) {
        const apiUrl = column.associationEndpoint;

        if (apiUrl && +value) {
          this.genericCrudService.getEntity(apiUrl, value).subscribe((loadedEntity) => {
            entity[column.entityName] = loadedEntity.id;

            for (const gridColumn of this.columns) {
              if (this.extractTopLevelEntity(gridColumn.field.id) === this.extractTopLevelEntity(column.field.id)) {
                this.assignEntityValue(column, gridColumn, loadedEntity, entity);
              }
            }
          });
        }
    }

    protected triggerFieldActions(event = null, fieldKey = null, action: ActionsEvent) {
        let field = null;

        if (null !== event && event.originalEvent) {
          field = this.getFieldOnDoubleClick(event);
        }

        if (null !== fieldKey) {
          field = this.findField(fieldKey)
        }

        if(field === null && fieldKey !== null && fieldKey.id !== null){
          field = fieldKey;
        }

        if (null !== field) {
          this.fieldActionsService.setGrid(this).setField(field).fire(action);
        }
    }

    protected extractTopLevelEntity(column: string): string {
        return column.split('.')[0];
    }

    protected assignEntityValue(fromColumn, toColumn, fromEntity, toEntity) {
        if (fromColumn.entityName == toColumn.entityName) {
          this.assignEntityToEntity(toEntity, toColumn.entityName, fromEntity);
          toEntity[toColumn.property] = fromEntity[toColumn.entityFieldName];
        } else {
          // Now this is a sick scenario - we have an entity embedded inside an entity and I am tired of this shit:
          let toColumnParts = toColumn.property.split('.');
          toColumnParts.shift();
          let withoutTopLevelEntity = toColumnParts.join('.');
          let embeddedObject = this.extractEmbeddedObject(fromEntity, withoutTopLevelEntity);
          this.assignEntityToEntity(toEntity, toColumn.entityName, embeddedObject);
          toEntity[toColumn.property] = embeddedObject[toColumn.entityFieldName];
        }
    }

    protected assignEntityToEntity(toEntity: any, entityPropertyName: string, embeddedEntity: any) {
        toEntity._embedded = toEntity._embedded || {};

        toEntity._embedded[entityPropertyName] = embeddedEntity;
    }

    protected extractEmbeddedObject(entity: any, fullyQualifiedColumnName: string, doNotTraverse: boolean = false): any {
        let splitParts = fullyQualifiedColumnName.split('.'),
          valuePart = entity;
        let objectPart = entity;

        if (doNotTraverse) {
          splitParts.pop();
        }

        for (let part of splitParts) {
          if (valuePart && valuePart['_embedded'] && valuePart['_embedded'][part]) {
            objectPart = valuePart['_embedded'][part];
            valuePart = valuePart['_embedded'][part];
          } else if (doNotTraverse) {
            return null;
          }
        }

        return objectPart;
    }

    protected getFieldOnDoubleClick(event: any): FieldMetadataGrid | null {
        let field = null,
          columnIndex = event.originalEvent.target.cellIndex;

        if (typeof columnIndex === 'undefined') {
          columnIndex = event.originalEvent.target.parentNode.parentNode.cellIndex;
        }

        const column = this.columns[columnIndex];

        if (column) {
          field = this.findField(column.id);
        }

        return field;
    }

    protected runJobs(runnableEvent: RunnableEventRegistry): void {
      const context = new JobContext();
      context.component = this;
      context.event = runnableEvent;
      context.identifier = Guid.create().toString();

      this.jobContainerService.runRelevantJobs(context);
    }
}
