
    <div [formGroup]="formGroup">
      <p-spinner
        appSeleniumDirective
        [element]="element"
        appSpinner
        (valueChange)="onValueChange($event)"
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readonly]="isReadOnly()"
        decimalSeparator= "decimalSeparator"
        thousandSeparator="thousandSeparator"
      ></p-spinner>
    </div>
  