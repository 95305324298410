import {Validation} from '../../validators/services/validation';
import {Action} from '../action/action';
import {FieldDisplayConfig} from './field-display-config';

export class ModuleElementFieldValidation extends Validation {
}

export class ModuleElementFieldAction extends Action {
}

export class ModuleElementFieldDisplayConfig extends FieldDisplayConfig {}

export class ModuleElementFieldDataConfig {
  type?: string;
  values?: string;
}
export class ModuleElementFieldCustomAutocompleteFilter {
  name?: string;
  value?: string;
}

export class ModuleElementFieldFilter {
  column: string;
  value: any;
}

export class ModuleElementField {
  id: number|string;
  name: string;
  visible: boolean;
  fieldType: string;
  deletable? = false;
  validations?: ModuleElementFieldValidation[] = [];
  actions?: ModuleElementFieldAction[] = [];
  displayConfig?: ModuleElementFieldDisplayConfig[] = [];
  datamodelId?: number;
  dataConfig?: ModuleElementFieldDataConfig[] = [];
  inputFormat?: {
    number: {
      active: true,
      prefix: '',
      suffix: '',
      allowDecimal: false,
      decimalLimit: 2,
      integerLimit: null,
      requireDecimal: true,
      allowNegative: true
    }
  };
  validationsError? = '';
  hasCustomButton?: boolean;
  customButtonChecker?: string = '';
  associationEndpoint?: string;
  chosenEntityName?: string;
  customAutocompleteFilters?: ModuleElementFieldCustomAutocompleteFilter[] = [];
  chosenEntityFieldName?: string;

  constructor(
      id?: string,
      name?: string,
      visible?: boolean,
      deletable?: boolean,
      datamodelId?: number,
      fieldType?: string,
      validations?: ModuleElementFieldValidation[],
      actions?: ModuleElementFieldAction[],
      validationsError?: string,
      displayConfig?: ModuleElementFieldDisplayConfig[],
      hasCustomButton: boolean = false,
      customButtonChecker?: string,
      associationEndpoint?: string,
      chosenEntityName?: string,
      customAutocompleteFilters?: ModuleElementFieldCustomAutocompleteFilter[],
      chosenEntityFieldName?: string,
      dataConfig?: ModuleElementFieldDataConfig[],
      inputFormat?: {
        number: {
          active: true,
          prefix: '',
          suffix: '',
          allowDecimal: false,
          decimalLimit: 2,
          integerLimit: null,
          requireDecimal: true,
          allowNegative: true
        }
      }
    ) {
    this.id = id;
    this.name = name;
    this.visible = visible;
    this.deletable = deletable;
    this.datamodelId = datamodelId;
    this.fieldType = fieldType;
    this.validations = validations;
    this.actions = actions;
    this.displayConfig = displayConfig;
    this.validationsError = validationsError;
    this.hasCustomButton = hasCustomButton;
    this.customButtonChecker = customButtonChecker;
    this.associationEndpoint = associationEndpoint;
    this.chosenEntityName = chosenEntityName;
    this.customAutocompleteFilters = customAutocompleteFilters;
    this.chosenEntityFieldName = chosenEntityFieldName;
    this.dataConfig = dataConfig;
    this.inputFormat = inputFormat;
  }
}
