import {debounceTime} from 'rxjs/operators';
import {Component, Input, AfterViewInit, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ElementInputComponent} from './element-input.component';
import {ElementInputTextarea} from '../models';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments';
import {Entity} from '../../helpers/entity';
import {EntityStatus} from '../../services/entity/entity-status';
import {ToolbarItemCheckService} from '../../content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {FroalaToolbarConfiguration} from '../models/element-input-textarea-froala';
declare var $: any;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-textarea',
  template: `
    <div class="container" [formGroup]="formGroup">
      <textarea *ngIf="!element.isEditor"
        pInputTextarea
        appSeleniumDirective
        [element]="element"
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readOnly]="isReadOnly()"
        [style.font-weight]="getFontWeight()"
        (keyup)="keyUp.next($event)"
        (keydown)="keyDown.next($event)"
        #inputElement
      ></textarea>

      <div
      class="editor-container"
      *ngIf="element.isEditor"
      [froalaEditor]="editorOptions"
      [formControlName]="formControlName"
      >
      </div>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    .container, .editor-container, .ui-inputtext {
      height: 100%;
    }

    ::ng-deep .fr-wrapper {
      height: calc(100% - 85px);
      min-height: 85px;
    }

    ::ng-deep .fr-element {
      height: 100%;
    }

    textarea[readonly].ui-widget-header .ui-inputtext.ui-state-default,
    textarea[readonly].ui-widget-content .ui-inputtext.ui-state-default,
    textarea[readonly].ui-inputtext.ui-state-default,
    textarea[readonly].ui-widget-header .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    textarea[readonly].ui-widget-content .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    textarea[readonly].ui-inputtext.ui-state-default:focus:not(:active):not(:hover),

    textarea[readonly].ui-inputtext.ui-state-focus, textarea[readonly].ui-inputtext:focus,
    textarea[readonly].ui-inputtext, textarea[readonly].ui-inputtext {
      background-color: lightgrey;
      border-color: lightgrey;
      color: black;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    `]
})
export class ElementInputTextareaComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputTextarea;

  public keyUp = new Subject<any>();
  public keyDown = new Subject<any>();
  public editorChange = new Subject<any>();

  public editorOptions: Object = {};

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');
    this.setupOptions();

    this.keyDown.subscribe(() => {
      this.entity[EntityStatus.ENTITY_CHANGING_FLAG] = true;
      this.formService.getInjector().get(ToolbarItemCheckService).check(null);
    })

    this.keyUp.pipe(
      debounceTime(500))
      .subscribe((event: any) => {
        this.onInputKeyUp(event);
      });

    this.editorChange.pipe(
      debounceTime(500))
      .subscribe((editor: any) => {
        this.onEditorChange(editor);
      });
  }

  onComponentDestroy() {

  }

  onComponentChanges(): void {
    this.setupValue();
  }

  ngAfterViewInit() {
  }

  public onInputKeyUp(event) {
    this.setValue(event.target.value);
  }

  public onEditorChange(editor): void {
    const value = editor.html.get();

    this.setValue(value, Entity.getValue(this.entity, this.element.datamodelField) !== value, {}, false);
  }

  public onTextChange(event) {
    this.setValue(event.htmlValue, true, {}, false);
  }

  private setupOptions(): void {
    const me = this;

    const configuration = FroalaToolbarConfiguration.getConfiguration(this.element.editorToolbarButtonsConfiguration || 'default');

    for (const customButton of configuration.customButtons) {
      $.FroalaEditor.DefineIcon(customButton.name, {NAME: customButton.icon});
      $.FroalaEditor.RegisterCommand(customButton.name, {
        title: customButton.label,
        focus: false,
        undo: false,
        refreshAfterCallback: false,

        callback: () => {
          this.filterActionAndHandleIt('onCustomButtonClick');
        }
      });
    }

    this.editorOptions = {
      enter: 1,
      key: environment.froalaKey,
      toolbarButtons: configuration.toolbarButtons,
      events : {
        'froalaEditor.contentChanged': function(e, editor) {
          me.onEditorChange(editor);
        }
      }
    };
  }
}
