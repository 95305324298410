import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, ViewContainerRef } from '@angular/core';
import './rxjs-operators';
import { ExecutorService } from './core/executor/executor.service';
import { BlockUI } from 'ng-block-ui';
export class AppComponent {
    constructor(authenticationService, translateService, languageService, viewContainerRef, genericDialogService, bootstrapService, executorService, toastService, moduleContentBlockerService) {
        this.authenticationService = authenticationService;
        this.translateService = translateService;
        this.languageService = languageService;
        this.viewContainerRef = viewContainerRef;
        this.genericDialogService = genericDialogService;
        this.bootstrapService = bootstrapService;
        this.executorService = executorService;
        this.toastService = toastService;
        this.moduleContentBlockerService = moduleContentBlockerService;
        this.bootstrapLanguage();
    }
    bootstrapLanguage() {
        this.translateService.setDefaultLang(this.languageService.getLocalStorageLanguage());
        this.translateService.use(this.languageService.getLocalStorageLanguage());
    }
    ngOnInit() {
        this.moduleContentBlockerService.component = this;
        this.genericDialogService.setRootViewContainerRef(this.viewContainerRef);
        this.toastService.setViewContainerRef(this.viewContainerRef);
    }
    ngAfterViewInit() {
        this.bootstrapService.setExecutorService(this.executorService).bootstrap().subscribe();
    }
}
tslib_1.__decorate([
    BlockUI('app-container')
], AppComponent.prototype, "blockUI", void 0);
