
        <div class="ui-g-12">
            <app-multi-upload-button
                #uploadButton
                [mode]="'advanced'"
                [disabled]="disabled"
                [filePath]="element.filePath"
                [fileContext]="element.fileContext"
            ></app-multi-upload-button>
        </div>
    