import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { environment } from "../../../../../../../../environments";
import { AuthenticationService } from "../../../../../../../core/authentication/authentication.service";
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {DoubleClickService} from '../../../../../services/double-click.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-questionnaire-by-phone-project-group',
  template: ''
})
export class ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent extends ToolbarItemAbstract {

  public isDialogVisible = false;

  protected phoneProjectGroup: any;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
    protected messageGrowlService: MessageGrowlService,
    protected translateService: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent && component.getSelectedEntity()) {
      this.handleOpenModuleInGridContext(component);
    }

  }

  protected handleOpenModuleInGridContext(grid: AbstractGenericGridComponent){
    this.phoneProjectGroup = grid.getSelectedEntity();
    this.openQuestionnaire(grid);
  }

  protected openQuestionnaire(grid: AbstractGenericGridComponent) {
    let urlParams = {
      'phoneProjectState.code':'OFFEN',
      'phoneProjectGroup': this.phoneProjectGroup.id,
      embedded:'phoneProjectState'
    };

    this.genericCrudService.getEntities('phoenix/phoneprojects/offset/0/limit/1/orderby/id/asc','', urlParams).subscribe((entities) => {

      if(entities.data.length > 0) {
        this.doubleClickService.setGrid(grid)
          .setDialogOptions({
            height: +this.getToolbarItem().targetDialogHeight,
            width: +this.getToolbarItem().targetDialogWidth
          })
          .handleOpenModule(grid, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, entities.data[0]);
      }else{
        this.messageGrowlService.info(
          this.translateService.instant('COMMON.NO_RECORDS_FOUND'),
          this.translateService.instant('COMMON.ERROR')
        );
      }
    });
  }

}
