import { Component } from '@angular/core';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ToolbarItemOpenModule} from '../toolbar-item-open-module.component';
import { cloneDeep } from 'lodash';
import {EntityManagerService} from '../../../../../../../../core/service/entity-manager/entity-manager.service';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../../services/double-click.service';
import {GenericDialogModuleService} from '../../../../../generic-dialog/service/generic-dialog-module.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';

@Component({
  selector: 'app-toolbar-item-open-module-assignments-selected-leased-employee',
  template: ''
})
export class ToolbarItemOpenModuleAssignmentsSelectedLeasedEmployeeComponent extends ToolbarItemOpenModule {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService
  ) {
    super(messageGrowlService, doubleClickService, genericDialogModuleService, genericCrudService, entityManager);
  }

  protected doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();

    if (this.getToolbarItem().onclick === 'edit') {
      gridComponent.triggerEditEntityActions();
      this.entity = gridComponent.selectedEntity;
    } else {
      gridComponent.triggerAddEntityActions();
      this.entity = cloneDeep(gridComponent.emptyEntity);
    }

    if (!this.validateInGridContext(gridComponent)) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }
    const masterEntity: any = {leasedEmployees: []};
    for (const entity of gridComponent.entities){
      masterEntity.leasedEmployees.push(entity['leasedEmployee']);

      this.entityManager.persist(masterEntity, {property: 'leasedEmployees', newValue: masterEntity.leasedEmployees});
    }

    this.doubleClickService.setGrid(gridComponent)
      .setDialogOptions({
        height: +this.getToolbarItem().targetDialogHeight,
        width: +this.getToolbarItem().targetDialogWidth
      })
      .handleOpenModule(gridComponent, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, this.entity, masterEntity, 'leasedEmployees');
  }

}
