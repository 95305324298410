/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/header/header.component.ngfactory";
import * as i3 from "./core/executor/executor.service";
import * as i4 from "./core/executor/service/executor-actions/executor-actions.service";
import * as i5 from "./core/header/header.component";
import * as i6 from "./core/style.service";
import * as i7 from "./shared/services/generic-crud.service";
import * as i8 from "./core/authentication/authentication.service";
import * as i9 from "@angular/router";
import * as i10 from "./shared/services/language/language.service";
import * as i11 from "./shared/content-renderer/services/entity-sharing.service";
import * as i12 from "@ngx-translate/core";
import * as i13 from "./shared/services/organisation/organisation-crud.service";
import * as i14 from "./shared/services/product/product.crud.service";
import * as i15 from "./shared/services/local-storage-data.service";
import * as i16 from "./shared/content-renderer/services/entity-dirty-store.service";
import * as i17 from "primeng/components/common/confirmationservice";
import * as i18 from "./shared/services/permission/permission.service";
import * as i19 from "./shared/services/request-caching.service";
import * as i20 from "./core/executor/factory/execution-step-factory.service";
import * as i21 from "./core/service/user-session.service";
import * as i22 from "@angular/flex-layout/flex";
import * as i23 from "@angular/flex-layout/core";
import * as i24 from "./core/footer/footer.component.ngfactory";
import * as i25 from "./core/footer/footer.component";
import * as i26 from "./shared/content-renderer/services/logger/logger.service";
import * as i27 from "@angular/common";
import * as i28 from "ng-block-ui";
import * as i29 from "./core/message/message.component.ngfactory";
import * as i30 from "./core/message/message.component";
import * as i31 from "./core/message/message.service";
import * as i32 from "./app.component";
import * as i33 from "./shared/content-renderer/elements/generic-dialog/service/generic-dialog-module.service";
import * as i34 from "./core/service/bootstrap.service";
import * as i35 from "./core/service/toast.service";
import * as i36 from "./core/service/module-content-blocker/module-content-blocker.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-header", [["fxFlex", "nogrow"]], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵprd(512, null, i3.ExecutorService, i3.ExecutorService, [i4.ExecutorActionsService]), i1.ɵdid(2, 245760, null, 0, i5.HeaderComponent, [i6.StyleService, i7.GenericCrudService, i8.AuthenticationService, i9.Router, i9.ActivatedRoute, i10.LanguageService, i11.EntitySharingService, i12.TranslateService, i13.OrganisationCrudService, i14.ProductCrudService, i15.LocalStorageDataService, i16.EntityDirtyStoreService, i12.TranslateService, i17.ConfirmationService, i18.PermissionService, i19.RequestCachingService, i3.ExecutorService, i20.ExecutionStepFactoryService, i21.UserSessionService], null, null), i1.ɵdid(3, 671744, null, 0, i22.DefaultFlexDirective, [i1.ElementRef, i23.StyleUtils, i23.LAYOUT_CONFIG, i22.FlexStyleBuilder, i23.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = "nogrow"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "app-content"], ["fxFlex", ""], ["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i22.DefaultLayoutDirective, [i1.ElementRef, i23.StyleUtils, [2, i22.LayoutStyleBuilder], i23.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i22.DefaultLayoutAlignDirective, [i1.ElementRef, i23.StyleUtils, [2, i22.LayoutAlignStyleBuilder], i23.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(3, 671744, null, 0, i22.DefaultFlexDirective, [i1.ElementRef, i23.StyleUtils, i23.LAYOUT_CONFIG, i22.FlexStyleBuilder, i23.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); _ck(_v, 5, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-footer", [["fxFlex", "nogrow"]], null, null, null, i24.View_FooterComponent_0, i24.RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i25.FooterComponent, [i8.AuthenticationService, i26.LoggerService], null, null), i1.ɵdid(2, 671744, null, 0, i22.DefaultFlexDirective, [i1.ElementRef, i23.StyleUtils, i23.LAYOUT_CONFIG, i22.FlexStyleBuilder, i23.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null)], function (_ck, _v) { var currVal_0 = "nogrow"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "app"], ["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], [[2, "primary-bg-lighter", null]], null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i22.DefaultLayoutDirective, [i1.ElementRef, i23.StyleUtils, [2, i22.LayoutStyleBuilder], i23.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i22.DefaultLayoutAlignDirective, [i1.ElementRef, i23.StyleUtils, [2, i22.LayoutAlignStyleBuilder], i23.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(6, 81920, null, 0, i28.ɵf, [i28.ɵc, i1.ViewContainerRef, i1.TemplateRef, i1.Renderer2, i1.ComponentFactoryResolver], { blockUI: [0, "blockUI"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(8, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-messages", [], null, null, null, i29.View_MessageComponent_0, i29.RenderType_MessageComponent)), i1.ɵdid(10, 114688, null, 0, i30.MessageComponent, [i31.MessageService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "column"; _ck(_v, 1, 0, currVal_1); var currVal_2 = "start stretch"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.authenticationService.authenticated(); _ck(_v, 4, 0, currVal_3); var currVal_4 = "app-container"; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.authenticationService.authenticated(); _ck(_v, 8, 0, currVal_5); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.authenticationService.authenticated(); _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵprd(512, null, i3.ExecutorService, i3.ExecutorService, [i4.ExecutorActionsService]), i1.ɵdid(2, 4308992, null, 0, i32.AppComponent, [i8.AuthenticationService, i12.TranslateService, i10.LanguageService, i1.ViewContainerRef, i33.GenericDialogModuleService, i34.BootstrapService, i3.ExecutorService, i35.ToastService, i36.ModuleContentBlockerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i32.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
