import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';

export class FormActionHandlePrecalculationReferenceAssociationTypeSection extends AbstractFormActionHandler {

  public constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  public handleAction(): void {
    const entity = this.getEntity();

    if (this.isActionValid()) {
      let customerCollectiveAgreement = entity.customerCollectiveAgreement;

      if (!customerCollectiveAgreement && entity._embedded) {
        customerCollectiveAgreement = entity._embedded.customerCollectiveAgreement;
      }

      let workingMode = entity.workingMode;
      if (entity._embedded && entity._embedded.workingMode) {
        workingMode = entity._embedded.workingMode;
      }

      const collectiveAgreement = this.getCollectiveAgreement(customerCollectiveAgreement);

      if (collectiveAgreement && collectiveAgreement.id) {
        this.genericCrudService
          .getEntity('phoenix/collectiveagreements', collectiveAgreement.id)
          .subscribe((collectiveAgreementEntity: any) => {
            this.toggleVisibility(
              this.getReferenceAssociationType(collectiveAgreementEntity),
              workingMode
            );
          });
      }
    }
  }

  private isActionValid(): boolean {
    return this.getEntity() && this.getParamValue('formElement');
  }

  private toggleVisibility(referenceAssociationType: any, workingMode: any): void {
    const form = this.getForm(),
      isVisible = referenceAssociationType
        && referenceAssociationType.code !== 'KEIN'
        && this.isProperReferenceAssociationType(referenceAssociationType, workingMode),
      formElementHashId = this.getParamValue('formElement');

    const formElement = this.formService.getElementBy(form, 'objectHashId', formElementHashId);

    formElement.isHidden = !isVisible;
    this.markElementForCheck(formElement);
  }

  private isProperReferenceAssociationType(referenceAssociationType: any, workingMode: any): boolean {
    if (referenceAssociationType.name === 'Referenzverband' && workingMode && workingMode.code === 'MONTAGE') {
      return false;
    }

    return true;
  }

  private getReferenceAssociationType(collectiveAgreementEntity: any): any {
    return (collectiveAgreementEntity._embedded && collectiveAgreementEntity._embedded.referenceAssociationType)
      ? collectiveAgreementEntity._embedded.referenceAssociationType : null;
  }

  private getCollectiveAgreement(customerCollectiveAgreement: any) {
    if (!customerCollectiveAgreement || !(customerCollectiveAgreement instanceof Object)) {
      return null;
    }
    let collectiveAgreement = customerCollectiveAgreement.collectiveAgreement;

    if (!collectiveAgreement && customerCollectiveAgreement._embedded && customerCollectiveAgreement._embedded.collectiveAgreement) {
      collectiveAgreement = customerCollectiveAgreement._embedded.collectiveAgreement;
    }

    return collectiveAgreement;
  }
}
