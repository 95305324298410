<div class="login-container" #loginContainer>
  <div class="login-header-bar">
    <img src="./assets/images/logo.png" alt="HR Puzzle" class="header-logo" />
  </div>
  <div class="block-nobg block-blurry block-dark-spinner" [class.block-active]="blockActive" *blockUI="'ui-login-block'">
    <form class="placeholder-label" [formGroup]="form" (ngSubmit)="doLogin($event, form)">
      <div class="ui-g-12 form-input">
        <input type="email" formControlName="email" [ngClass]="getInputClass(form.value.email)" required>
        <label for="email">E-Mail</label>
      </div>
      <div class="ui-g-12 form-input">
        <input type="password" formControlName="password" [ngClass]="getInputClass(form.value.password)" required>
        <label for="userPass">Passwort</label>
      </div>
      <div class="ui-g-12 form-input">
        <p-dropdown
          [style]="{width: '100%'}"
          [options]="organisations | toSelectItem:'name'"
          [disabled]="!organisations"
          [ngModel]="selectedOrganisation"
          formControlName="organisation"
          [required]="true"
          placeholder="{{ 'USER_MANAGEMENT.NO_ORG_AND_BRANCH' | translate }}"
          (onChange)="onSelectOrganisation($event)">
        </p-dropdown>
        <label for="userOrganisation">Organisation</label>
      </div>
      <button pButton type="submit" label="Login" [disabled]="!form.valid"></button>
      <div class="clearfix"></div>
    </form>
  </div>
</div>
<div class="login-error" *ngIf="loginError">
  Fehler beim Login
</div>
