import {Injectable} from '@angular/core';
import {ModuleElementFieldDataConfig, ModuleElementFieldFilter} from '../../../../../../services/module/module-element-field';
import {AbstractGenericGridComponent} from '../../../../../elements/abstract-generic-grid.component';
import {GenericElementAbstract} from '../../../../../elements/generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';

@Injectable()
export class MasterEntityFilterService {

  /**
   *
   * @param {ModuleElementFieldDataConfig} fieldDataConfig
   * @param {AbstractGenericGridComponent} grid
   * @returns {ModuleElementFieldFilter | null}
   */
  public static createFilter(
    fieldDataConfig: ModuleElementFieldDataConfig,
    grid: AbstractGenericGridComponent
  ): ModuleElementFieldFilter|null {
    const filter = new ModuleElementFieldFilter();

    filter.column = fieldDataConfig.values.split('.').shift();
    filter.value = MasterEntityFilterService.figureOutValue(fieldDataConfig.values, grid);

    return filter.value ? filter : null;
  }

  /**
   *
   * @param {string} field
   * @param {AbstractGenericGridComponent} grid
   * @returns {any}
   */
  protected static figureOutValue(field: string, grid: AbstractGenericGridComponent): any {
    if (grid.getElementContext().getMasterElementContext()
      && grid.getElementContext().getMasterElementContext().component instanceof GenericElementAbstract) {

      /**
       *
       * @type {GenericElementAbstract} masterComponent
       */
      const masterComponent = grid.getElementContext().getMasterElementContext().component;
      const masterEntity = masterComponent.getSelectedEntity();

      if (masterEntity) {
        return FieldMetadataGrid.getPropertyValue(masterEntity, field);
      }
    }

    return null;
  }
}
