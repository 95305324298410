import { ModuleElement } from './module-element';
import { Template } from './../template/template';
import { ToolbarItem } from '../element/toolbar-item';

interface EmbeddedEntities {
  template?: Template;
}

export class Module {
  id: number;
  name: string;
  template: Template;
  isLocked: boolean = false;
  moduleElements: ModuleElement[];
  toolbarItems: ToolbarItem[];
  label?: string;

  _embedded?: EmbeddedEntities;

  constructor() { }
}
