import {AbstractFormActionHandler} from './abstract-form-action-handler';
import {LocationService} from '../../../services/location.service';
import {ModuleNavigationService} from '../../../content-renderer/services/navigation/module-navigation.service';

export class SetIdToUrlActionHandler extends AbstractFormActionHandler {

    public constructor(
        private locationService: LocationService,
        private moduleNavigation: ModuleNavigationService
    ) {
        super();
    }

    public handleAction(): void {
        const entity = this.getEntity();

        if (entity && entity.id && !this.locationService.hasParam('id')) {
          this.moduleNavigation.getModuleRouter().replaceRouteEntity(entity);
        }
    }

    private isActionValid(): boolean {
      return true;
    }
}
