import {Injectable} from '@angular/core';
import {ToolbarItemDisabledCheckInterface} from './toolbar-item-disabled-check-factory.service';
import {ToolbarItemSaveDisabledCheckService} from './toolbar-item-save-disabled-check.service';

@Injectable()
export class ToolbarItemAddPreCalculationDisabledCheckService extends ToolbarItemSaveDisabledCheckService
  implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    const currentModule = this.modulesStateService.getCurrent();
    const masterEntity = currentModule ? currentModule.entity : null;

    return masterEntity && masterEntity.fqn == 'PhoenixBundle\\Entity\\Customer' && masterEntity['hasFrameAgreement'];
  }
}
