import {InquiryPositionActionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/inquiry/inquiry-position-action-column.component';
import {PrecalculationArticleGlobalFieldsColumnComponent} from '../../content-renderer/elements/generic-grid/columns/precalculation-article/precalculation-article-global-fields-column.component';
import {SelectionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/selection/selection-column.component';
import {CollectiveAgreementValidToColumnComponent} from '../../content-renderer/elements/generic-grid/columns/collective-agreement/collective-agreement-valid-to-column.component';
import {InquiryPositionCounterColumnComponent} from '../../content-renderer/elements/generic-grid/columns/inquiry/inquiry-position-counter-column/inquiry-position-counter-column.component';
import {DispositionActionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/disposition/disposition-action-column.component';
import {WorkingHourActionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/working-hour/working-hour-action-column.component';
import {ParentOnlySelectionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/selection/parent-only-selection-column.component';
import {ConditionTreeComparatorColumnComponent} from '../../content-renderer/elements/generic-grid/columns/collective-agreement/condition-tree/comparator/condition-tree-comparator-column.component';
import {ConditionTreeParameterColumnComponent} from '../../content-renderer/elements/generic-grid/columns/collective-agreement/condition-tree/parameter/condition-tree-parameter-column.component';
import {TreeAddChildNodeComponent} from '../../content-renderer/elements/generic-grid/columns/tree/tree-add-child-node.component';
import {CurriculumActionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/curriculum/curriculum-action-column.component';
import {AbsenceActionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/absence/absence-action-column.component';
import {LockColumnComponent} from '../../content-renderer/elements/generic-grid/columns/lock/lock-column.component';
import {UserResponsibleGroupSelectionComponent} from '../../content-renderer/elements/generic-grid/columns/user-responsible-group-selection/user-responsible-group-selection.component';
import {WorkflowActionParameterColumnComponent} from '../../content-renderer/elements/generic-grid/columns/workflow/workflow-action-parameter-column.component';
import {PrinterSelectionColumnComponent} from '../../content-renderer/elements/generic-grid/columns/printer-selection-column/printer-selection-column.component';

export interface ModuleElementColumnRegisterConfiguration {
    field: string;
    type: string;
}

export interface ModuleElementColumnRegister {
    name?: string;
    columnComponent: any;
    configuration?: ModuleElementColumnRegisterConfiguration[];
}

export class ModuleElementColumnRegistry {

    private configurations: ModuleElementColumnRegister[] = [];

    public constructor() {

    }

    public getConfigurations(): ModuleElementColumnRegister[] {
        this.initConfigurations();
        return this.configurations;
    }

    public getConfiguration(columnType: string): ModuleElementColumnRegister|null {
        let found = null;

        this.initConfigurations();

        for (const config of this.configurations) {
            const name = config.name;

            if (columnType === name) {
                found = config;
                break;
            }
        }

        return found;
    }

    public getColumnConfiguration(columnType: string): ModuleElementColumnRegisterConfiguration[] {
        const config = this.getConfiguration(columnType);

        return config.configuration;
    }

    private initConfigurations(): void {
      if (this.configurations.length === 0) {
        this.configurations = [
          {
            'columnComponent': InquiryPositionActionColumnComponent,
            'configuration': [
              { 'field': 'order', 'type': 'modulePicker' },
              { 'field': 'offer', 'type': 'modulePicker' },
              { 'field': 'assignment', 'type': 'modulePicker' },
              { 'field': 'preCalculation', 'type': 'modulePicker' },
              { 'field': 'workplace', 'type': 'modulePicker' },
              { 'field': 'selectedWorkingTimeModel', 'type': 'modulePicker' }
            ],
            'name': 'InquiryPositionActionColumnComponent'
          },
          {
            'columnComponent': WorkflowActionParameterColumnComponent,
            'name': 'WorkflowActionParameterColumnComponent'
          },
          {
            'columnComponent': DispositionActionColumnComponent,
            'configuration': [
              { 'field': 'open_details', 'type': 'modulePicker' }
            ],
            'name': 'DispositionActionColumnComponent'
          },
          {
            'columnComponent': PrecalculationArticleGlobalFieldsColumnComponent,
            'name': 'PrecalculationArticleGlobalFieldsColumnComponent'
          },
          {
            'columnComponent': WorkingHourActionColumnComponent,
            'name': 'WorkingHourActionColumnComponent'
          },
          {
            'columnComponent': CollectiveAgreementValidToColumnComponent,
            'name': 'CollectiveAgreementValidToColumnComponent'
          },
          {
            'columnComponent': SelectionColumnComponent,
            'name': 'SelectionColumnComponent'
          },
          {
            'columnComponent': ConditionTreeComparatorColumnComponent,
            'name': 'ConditionTreeComparatorColumnComponent'
          },
          {
            'columnComponent': ConditionTreeParameterColumnComponent,
            'name': 'ConditionTreeParameterColumnComponent'
          },
          {
            'columnComponent': TreeAddChildNodeComponent,
            'name': 'TreeAddChildNodeComponent'
          },
          {
            'columnComponent': CurriculumActionColumnComponent,
            'name': 'CurriculumActionColumnComponent',
            'configuration': [
              { 'field': 'targetModule', 'type': 'modulePicker'},
              { 'field': 'dialogHeight', 'type': 'text' },
              { 'field': 'dialogWidth', 'type': 'text' }
            ]
          },
          {
            'columnComponent': LockColumnComponent,
            'name': 'LockColumnComponent',
          },
          {
            'columnComponent': AbsenceActionColumnComponent,
            'name': 'AbsenceActionColumnComponent',
            'configuration': [
              { 'field': 'isPdfVisible', 'type': 'boolean'},
              { 'field': 'isDocxVisible', 'type': 'boolean' }
            ]
          },
          {
            'columnComponent': InquiryPositionCounterColumnComponent,
            'configuration': [
              { 'field': 'colorValue', 'type': 'colorPicker' },
              { 'field': 'preText', 'type': 'text' },
              { 'field': 'entityProperty', 'type': 'text' }
            ],
            'name': 'InquiryPositionCounterColumnComponent'
          },
          {
            'columnComponent': ParentOnlySelectionColumnComponent,
            'name': 'ParentOnlySelectionColumnComponent'
          },
          {
            'columnComponent': UserResponsibleGroupSelectionComponent,
            'name': 'UserResponsibleGroupSelectionComponent'
          },
          {
            'columnComponent': PrinterSelectionColumnComponent,
            'name': 'PrinterSelectionColumnComponent'
          }];
      }
    }
}
