import {Component, Input, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef} from '@angular/core';

import {ComponentSelectedOptionAware} from './abstract-element.component';
import {ElementInputListBox} from '../models/element-input-list-box';
import {ElementInputDropdownComponent, SelectItemTmpIsDeleted} from './element-input-dropdown.component';
import {FormControl} from '@angular/forms';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {GenericCrudService} from '../../services/generic-crud.service';
import {ElementService} from '../../../form-editor/shared/services';
import {DatamodelCrudService} from '../../services/datamodel/datamodel.crud.service';
import {TranslateService} from '@ngx-translate/core';
import {ElementsStackService} from '../../content-renderer/services/elements-stack.service';
import {FormActionsService} from '../actions/services/form-actions.service';
import {EntityDraftStoreService} from '../../content-renderer/services/entity-draft-store.service';
import {JobContainerService} from '../../../core/job-runner/job-container.service';
import {EntityManagerService} from '../../../core/service/entity-manager/entity-manager.service';

export interface SelectItemWithName {
  name: string;
  id: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-region-chooser',
  template: `
    <div [formGroup]="formGroup" class="ui-g ui-g-12">
      <p-fieldset [legend]="element.label">
        <p-selectButton [options]="buttonOptions" optionLabel="label" optionValue="value" (onChange)="changeOption($event)"></p-selectButton>
        <div class="ui-g-12 ui-md-4" *ngIf="isDeploymentRangeEnabled">
          <div class="ui-inputgroup">
            <input type="text" pInputText placeholder="Arbeiten im Umkreis von" (keyup)="changeDistance($event)" formControlName="deploymentRange">
            <span class="ui-inputgroup-addon">KM</span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="!isDeploymentRangeEnabled">
          <p-multiSelect [options]="countries" formControlName="countries" (onChange)="onChangeCountry($event)" optionLabel="name" appendTo="body"></p-multiSelect>
          <img alt="Österreich auswählen" src="assets/images/austria-flag-icon-16.png" style="padding-bottom: 4px; padding-left: 4px;" (click)="selectAustria()">
          <p-listbox
            appSeleniumDirective
            [element]="element"
            [options]="regions"
            [checkbox]="true" [filter]="true" [multiple]="true"
            formControlName="regions"
            (onChange)="onChangeRegions($event)"
            [listStyle]="{'max-height':'100px', 'min-height':'100px'}"
          >
          </p-listbox>
        </div>
      </p-fieldset>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
      width: 100%;
    }

    .ui-g-12 {
      height: 100%;
    }

    p-listbox {
      width: inherit;
    }

    ::ng-deep .ui-listbox {
      height: 100%;
      position: relative;
    }

    ::ng-deep .ui-listbox-list-wrapper {
      height: calc(100% - 25px);
    }
  `]
})
export class ElementInputRegionChooserComponent extends ElementInputDropdownComponent implements
  OnInit, OnDestroy, AfterViewInit, ComponentSelectedOptionAware {

  @Input() element: ElementInputListBox;

  public selectionMode = 'distance';

  public countries: SelectItemWithName[] = [];

  public regions: SelectItem[] = [];

  public selectedCountries: SelectItemWithName[] = [];

  public selectedRegions: SelectItemWithName[] = [];

  public deploymentRange: string;

  public isDeploymentRangeEnabled = true;

  public buttonOptions: any[] = [
    {
      label: 'Regionauswahl',
      value: 'region'
    },
    {
      label: 'Entfernung',
      value: 'distance'
    },
  ];

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected genericCrudService: GenericCrudService,
    protected elementService: ElementService,
    protected elementRef: ElementRef,
    protected datamodelCrudService: DatamodelCrudService,
    protected translateService: TranslateService,
    protected elementsStackService: ElementsStackService,
    protected formActionsService: FormActionsService,
    protected entityDraftService: EntityDraftStoreService,
    protected jobContainerService: JobContainerService,
    protected entityManager: EntityManagerService
  ) {
    super(formService, cdr, formViewerService, genericCrudService, elementService, elementRef, datamodelCrudService, translateService, elementsStackService, formActionsService, entityDraftService, jobContainerService);
  }

  public onComponentInit(): void {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');

    this.formGroup.addControl('regions', new FormControl(this.selectedRegions));
    this.formGroup.addControl('countries', new FormControl(this.selectedCountries));
    this.formGroup.addControl('deploymentRange', new FormControl(this.deploymentRange));

    this.genericCrudService.getEntities('phoenix/countries').subscribe((countryResponse) => {
      this.countries = countryResponse;
      this.countries = [...this.countries];
      this.setupValueFromEntity();
      this.cdr.detectChanges();
    })
  }

  public onComponentDestroy() { }

  public setupValue(): void {
    if (this.entity) {
      this.isDeploymentRangeEnabled = this.entity.isDeploymentRangeEnabled;
      this.formGroup.get('deploymentRange').setValue(this.entity.deploymentRange);
      this.cdr.detectChanges();
    }
  }

  public changeDistance(event) {
    this.entityManager.persist(this.entity,
      {
        property: 'deploymentRange',
        oldValue: this.entity.deploymentRange,
        newValue: event.target.value,
        force: true
      });
    this.entity.deploymentRange = event.target.value;
    this.formGroup.get('deploymentRange').setValue(event.target.value);
    this.setupValueFromChange([]);
  }

  public changeOption(event) {
    this.selectionMode = event.value.value;
    this.isDeploymentRangeEnabled = this.selectionMode === 'distance';
    this.entityManager.persist(this.entity, {
      property: 'isDeploymentRangeEnabled',
      oldValue: this.entity.isDeploymentRangeEnabled,
      newValue: this.isDeploymentRangeEnabled, force: true
    });
    this.entity.isDeploymentRangeEnabled = this.isDeploymentRangeEnabled;
    this.setupValueFromChange([]);
  }

  public onChangeCountry(event): void {
    this.setupValueFromChange(event.value);
    this.persistSelection(event.value);
  }

  public onChangeRegions(event): void {
    const regions = event.value.filter(region => typeof region !== 'undefined');
    this.entity['_embedded']['regions'] = regions;
    this.selectedRegions = regions;
    this.setupValueFromChange(regions);
    this.entityManager.persist(this.entity, {property: 'regions', oldValue: [], newValue: this.selectedRegions, force: true });
  }

  public setupValueFromChange(selected: SelectItemWithName[] = []): void {
    this.doTriggerElementValueChange([], [], true);
  }

  public setupValueFromEntity(): void {
    if (!this.entity || !this.entity.id) {
      return;
    }

    this.formGroup.get('deploymentRange').setValue(this.entity.deploymentRange);

    this.genericCrudService
      .getEntities('phoenix/leasedemployeeregions', '',
        {
          leasedEmployee: this.entity.id,
          embedded: 'country,region'
        }).subscribe(employeeRegions => {
          this.selectionMode = employeeRegions.length > 0 ? 'region' : 'distance';
          for (const employeeRegion of employeeRegions) {
            if (employeeRegion._embedded.region) {
              this.selectedRegions = [...this.selectedRegions, employeeRegion._embedded.region];
              continue;
            }
            this.selectedCountries = [
              ...this.selectedCountries,
              this.countries.find(country => country.id === employeeRegion._embedded.country.id)
            ];
          }
      this.formGroup.get('regions').setValue(this.selectedRegions);
      this.formGroup.get('countries').setValue(this.selectedCountries);
      this.entity['_embedded']['regions'] = this.selectedRegions;
      this.entity['_embedded']['countries'] = this.selectedCountries;

      if (this.selectedCountries.length > 0) {
        this.loadRegions(this.selectedCountries);
        this.entityManager.persist(this.entity, {property: 'countries', oldValue: [], newValue: this.selectedCountries, force: true });
      }

      this.cdr.detectChanges();
    });
  }

  public doTriggerElementValueChange(formControlValue: any[] = [], entityValue: any[] = [], triggerChange: boolean = true): void {
    this.formService.onFormElementValueChange({
      formControlValue: formControlValue,
      element: this.element,
      entityValue: entityValue,
      formControlName: this.formControlName,
      formControlOptions: {},
      triggerChange: triggerChange,
      entity: this.entity,
      component: this,
      updateFormComponent: true
    });
  }

  public selectAustria() {
    const requestParams = {iso2: 'at'};

    this.genericCrudService.getEntities('phoenix/countries', '', requestParams).subscribe(countryResponse => {
      if (!this.countries.find(country => { return country['iso2'] === 'at'; })) {
        this.countries.push(countryResponse[0]);
        this.countries = [...this.countries];
      }

      this.selectedCountries.push(countryResponse[0]);

      this.persistSelection(this.selectedCountries);

      this.formGroup.get('countries').setValue(countryResponse);
      this.entity['_embedded']['countries'] = countryResponse;
    });
  }

  protected persistSelection(countries: SelectItemWithName[]): void {
    this.selectedCountries = countries;
    this.loadRegions(countries);
    this.entity['_embedded']['countries'] = countries;
    this.entityManager.persist(this.entity, {property: 'countries', oldValue: [], newValue: this.selectedCountries, force: true });
  }

  protected loadRegions(countries: SelectItemWithName[]) {
    const requestParams = {clause: 'orWhere', country: 'in:'};

    requestParams['country'] += countries.map(country => {return country.id;}).join(',');
    requestParams['embedded'] = 'country';
    this.regions = [];

    this.genericCrudService.getEntities('phoenix/regions', '', requestParams).subscribe(regionsResponse => {
      for (const region of regionsResponse) {
        this.regions = [...this.regions, {
          label: region._embedded.country.iso2 + ' - ' + region.name,
          value: region
        }];
      }

      this.selectedRegions = regionsResponse.filter(region => {
        return this.selectedRegions.find(selectedRegion => selectedRegion.id === region.id);
      });
      this.formGroup.get('regions').setValue(this.selectedRegions);
      this.entity['_embedded']['regions'] = this.selectedRegions;
      this.entityManager.persist(this.entity, {property: 'regions', oldValue: [], newValue: this.selectedRegions, force: true });

      this.cdr.detectChanges();
    });
  }
}
