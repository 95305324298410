import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output, QueryList, ViewChildren
} from '@angular/core';
import {TableColumn} from './shared/table-column';
import {TableColumnMenuButton} from './shared/table-column-menu';
import {TableRow} from './shared/table-row';
import {TableHeader} from './shared/table-header';
import {TableButton} from './shared/table-button';
import {ChangeDetectorRefHelper} from '../helpers/change-detector-ref.helper';

@Component({
    selector: 'shared-dynamic-table',
    styleUrls: ['./dynamic-table.component.scss'],
    templateUrl: './dynamic-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTableComponent implements OnInit, OnDestroy {

    @Input() expander = '';
    @Input() expandedRowKeys: { [s: string]: boolean; } = {};
    @Input() inputFilter: {value: string, field: string, table}[];
    @Input() selectFirstEntityWhenNothingIsSelected = true;
    @Input() selectedEntity: any = null;
    @Input() transferKey = '';
    @Input() sortField = 'id';
    @Input() sortDirection = '1';
    @Input() set header(header: TableHeader) {
      this.tableHeader = header;
    }
    @Input() set columns(columns: TableColumn[]) {
        const visibleColumns = [];

        for (const column of columns) {
          if (column.visible !== false) {
            visibleColumns.push(column);
          }
        }

        this.tableColumns = visibleColumns;

        for (const column of columns) {
            column.style = column.style || {};

            column.style.width = column.style.width || '200px';
        }
    }
    @Input() showRows = true;
    @Input() set rows(rows: TableRow[]) {
        this.tableRows = rows;
    }
    @Input() set entities(entities: Object[]) {
        this.tableEntities = entities;

        this.selectFirstEntityIfPossible();
    }
    @Input() totalCount: number = 0;
    @Input() rowsCount: number = 10;
    @Input() paginator = true;
    @Input() isLoadingData: boolean = false;
    @Input() set height(height: number) {
        this.tableHeight = height;

        this.recalculateScrollHeight();
    }
    @Input() set width(width: number) {
        this.tableWidth = width;

        ChangeDetectorRefHelper.detectChanges(this);
    }
    @Input() dataKey = 'uniqueId';
    @Input() showFilters = true;

    @Output() onFilter = new EventEmitter<any>();
    @Output() onCellEdit = new EventEmitter<any>();
    @Output() onCellBlur = new EventEmitter<any>();
    @Output() onCellFocus = new EventEmitter<any>();
    @Output() onLazyLoad = new EventEmitter<TableColumn>();
    @Output() onRowExpanded = new EventEmitter<any>();
    @Output() onEntityTransfer = new EventEmitter<any>();
    @Output() onRowSelected = new EventEmitter<any>();
    @Output() onRowUnselected = new EventEmitter<any>();
    @Output() onExpanderComponentMetaChange = new EventEmitter<any>();

    public expanderComponents = null;
    @ViewChildren('expanderComponent') set expanderComponent(expanderComponent: QueryList<any>) {
      this.expanderComponents = expanderComponent;
    };

    public tableColumns: TableColumn[] = [];
    public tableRows: TableRow[] = [];
    public tableEntities: Object[] = [];
    public tableHeader: TableHeader = null;

    public tableWidth: number;
    public tableHeight: number;

    public spinnerFilterValue: Object = new Object();
    public operatorFilterValue: Object = new Object();

    public constructor(
        private elementRef: ElementRef,
        public cdr: ChangeDetectorRef
    ) {

    }

    public ngOnInit(): void {
    }

    public ngOnDestroy(): void {
    }

    @HostListener('window:keyup', ['$event'])
    public onKeyUp(event): void {
        const menuColumnsButtons = this.getMenuColumnsButtons();

        for (const menuColumnButton of menuColumnsButtons) {
            if (menuColumnButton.hotkey === event.code) {
                menuColumnButton.click(this.selectedEntity);
            }
        }
    }

    public onLazyLoadEvent(event: any): void {
        this.onLazyLoad.emit(event);
    }

    public onRowExpand(event): void {
      ChangeDetectorRefHelper.detectChanges(this);

      this.onRowExpanded.emit();
    }

    public onSpinnerFilterEvent(event: any, column: TableColumn) {
        this.onFilterEvent({
            value: this.spinnerFilterValue[column.key]
        }, column);
    }

    public onFilterEvent(event: any, column: TableColumn, param?: any) {
        this.onFilter.emit({
            originalEvent: event,
            column: column,
            param: param,
            operator: this.operatorFilterValue[column.key]
        });
    }

    public onTableButtonClick(event, button: TableButton): void {
      button.click();
    }

    public onTableColumnButtonClick(entity, button: {click: any, class: string}): void {
      button.click(entity);
    }

    public onFilterOperatorEvent(event: any, column: TableColumn, param?: any) {
        this.onFilterEvent(null, column, param);
    }

    public onCellEditEvent(event: any, column: TableColumn, entity: Object) {
        this.onCellEdit.emit({
            originalEvent: event,
            column: column,
            entity: entity
        });
    }

    public onCellFocusEvent(event: any, column: TableColumn, entity: Object) {
      this.onCellFocus.emit({
        originalEvent: event,
        column: column,
        entity: entity
      });
    }

    public onCellBlurEvent(event: any, column: TableColumn, entity: Object) {
      this.onCellBlur.emit({
        originalEvent: event,
        column: column,
        entity: entity
      });
    }

    public isInlineDisabled(column: TableColumn, entity: any): boolean {
      if (typeof column.edit && typeof column.edit.isDisabled !== 'undefined') {
        return column.edit.isDisabled(entity);
      }

      return false;
    }

    public getColumnEntityValue(column: TableColumn, entity: any): any {
        if (typeof column.getValue !== 'undefined') {
            return column.getValue(entity);
        }

        return entity[column.key];
    }

    public isColumnRendererDefined(column: TableColumn): boolean {
        return typeof column.renderer !== 'undefined';
    }

    public onDragStart(event, entity: any): void {
      this.selectedEntity = entity;

      event.dataTransfer.setData('entity', JSON.stringify(entity));
    }

    public onDrop(event): void {
      const entity = JSON.parse(event.dataTransfer.getData('entity'));

      this.onEntityTransfer.emit(entity);
    }

    public onRowSelect(event): void {
      this.onRowSelected.emit(event);
    }

    public onRowUnselect(event): void {
      this.onRowUnselected.emit(event);
    }

    public onExpanderComponentChange(event): void {
      this.onExpanderComponentMetaChange.emit(event);
    }

    private recalculateScrollHeight(): void {
        const tableBody = this.elementRef.nativeElement.querySelector('.ui-table-scrollable-body');

        if (tableBody) {
            // header, filter, paginator
            // TODO :: calculate this
            const scrollHeight = this.tableHeight - 100;

            tableBody.style.maxHeight = `${scrollHeight}px`;
            tableBody.style.height = `${scrollHeight}px`;
        }
    }

    private selectFirstEntityIfPossible(): void {
        if (this.selectFirstEntityWhenNothingIsSelected && this.selectedEntity === null && this.tableEntities.length > 0) {
            this.selectedEntity = this.tableEntities[0];
        }
    }

    private getMenuColumnsButtons(): TableColumnMenuButton[] {
        const menuColumns = this.tableColumns.filter((aTableColumn: TableColumn) => { return aTableColumn.menu; }),
            menuColumnsButtons = [];

        for (const menuColumn of menuColumns) {
            for (const menuColumnButton of menuColumn.menu.buttons) {
                menuColumnsButtons.push(menuColumnButton);
            }
        }

        return menuColumnsButtons;
    }
}
