
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {GenericDialogModuleService} from "../../../../generic-dialog/service/generic-dialog-module.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-salary-type-create-articles-from-selection',
  template: '',
  providers: []
})
export class ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent extends ToolbarItemAbstract {

  public constructor(
    private messageGrowlService: MessageGrowlService,
    private genericCrudService: GenericCrudService,
    private modulesStateService: ModulesStateService,
    private dialogService: GenericDialogModuleService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleCreate(component);
    }
  }

  private handleCreate(component: AbstractGenericGridComponent) {
    const parentSalaryTypes = this.getParentSalaryTypesForCreatingArticles(component.selectedNodes, component.selectedEntities);

    let preCalculation = null;
    let preCalculationArticle = null;
    if (component.masterElementContext && component.masterElementContext.component &&
      component.masterElementContext.component.selectedMasterEntity
    ) {
      preCalculation = component.masterElementContext.component.selectedMasterEntity;
    }

    if (component.masterElementContext && component.masterElementContext.masterElementContext
      && component.masterElementContext.masterElementContext.component) {
      preCalculationArticle = component.masterElementContext.masterElementContext.component.getSelectedEntity();
    }

    if (parentSalaryTypes.length === 0) {
      return this.messageGrowlService.error('No parent Salary Types are selected!');
    }

    if (!preCalculation || !preCalculationArticle) {
      return this.messageGrowlService.error('PreCalculation|PreCalculationArticle is missing, check you configuration!');
    }

    return this.createArticlesFromParentSalaryTypes(parentSalaryTypes, preCalculation, preCalculationArticle).subscribe((articles: any[] = []) => {



      this.onArticlesCreated(component);
    });
  }

  public onArticlesCreated(component: AbstractGenericGridComponent): void {
    const moduleState = this.modulesStateService.getByComponent(component);

    if (component && component.masterElementContext && moduleState.isDialog) {
      component.masterElementContext.component.loadEntities().subscribe();
      this.dialogService.persistHide();
    }

    this.messageGrowlService.showDataSaved();
  }

  private getParentSalaryTypesForCreatingArticles(selectedNodes: TreeNode[] = [], selectedEntities: any[] = []): any[] {
    const salaryTypes = [];

    for (const selectedNode of selectedNodes) {
      if (selectedNode.data && selectedNode.data.id) {
        const salaryType = selectedNode.data;

        if (!selectedNode.parent) {
          salaryTypes.push(salaryType);
        }
      }
    }

    return salaryTypes;
  }

  private createArticlesFromParentSalaryTypes(salaryTypes: any[] = [], preCalculation: any, preCalculationArticle: any): Observable<any[]> {
    const observables = [];

    for (const salaryType of salaryTypes) {
      observables.push(
        this.genericCrudService.createEntity(
          `phoenix/precalculationotherarticles/createfromsalarytype/${salaryType.id}/precalculation/${preCalculation.id}/article/${preCalculationArticle.id}`,
          salaryType
        )
      );
    }

    return Observable.create((observer) => {
      if (observables.length === 0) {
        observer.next([]);
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(results => {
          observer.next({
            status: true,
            content: results
          });
          observer.complete();
        });
    });
  }
}
