import { ModuleElementField } from './module-element-field';
import { Entity } from "../../helpers/entity";
export class FieldMetadataGrid extends ModuleElementField {
    constructor() {
        super(...arguments);
        this.usePrimaryAssociationEndpoint = false;
        this.firstAssociationOrderByOrientation = 'asc';
        this.secondAssociationOrderByOrientation = 'asc';
    }
    static isFieldRequiringOptionsForDefaultValue(field) {
        return field.associationEndpoint && field.entityName && (field.fieldType === FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE
            || field.fieldType === FieldMetadataGrid.TYPE_ASSOCIATION_MANY);
    }
    static getOptionLabel(entity, field) {
        return field && field.displayConfig && field.displayConfig.length > 0 ?
            FieldMetadataGrid.getLabelFromConfig(entity, field.displayConfig) : FieldMetadataGrid.getLabelSimple(entity, field, field.entityFieldName);
    }
    static getLabelFromConfig(entity, config) {
        let displayLabel = '';
        for (const configOption of config) {
            if (!configOption || !configOption.fieldName) {
                continue;
            }
            const fieldLabel = this.getPropertyValue(entity, configOption.fieldName);
            if (fieldLabel) {
                displayLabel += configOption.preChacarter + fieldLabel.toString() + configOption.postCharacter;
            }
        }
        return displayLabel;
    }
    static getLabelSimple(entity, field, fieldName) {
        let entityPart = entity;
        if (field && field.name && field.name.indexOf('.') !== field.name.lastIndexOf('.')) {
            const fieldLabelParts = field.name.split('.');
            fieldLabelParts.shift();
            for (const labelPart of fieldLabelParts) {
                let tempEntityPart = Entity.getValue(entityPart, labelPart);
                if (tempEntityPart === null) {
                    tempEntityPart = Entity.getValueInEmbedded(entityPart, labelPart);
                }
                if (tempEntityPart && typeof tempEntityPart === 'object') {
                    entityPart = tempEntityPart;
                }
                fieldName = labelPart;
            }
        }
        if (!fieldName || !entityPart.hasOwnProperty(fieldName)) {
            fieldName = FieldMetadataGrid.getLabelFieldName(entityPart);
        }
        return (entityPart.hasOwnProperty(fieldName)) ? entityPart[fieldName] : entity.id;
    }
    static getPropertyValue(entity, propertyName) {
        let splitParts = propertyName.split('.'), valuePart = entity;
        for (let part of splitParts) {
            let propertyPart = valuePart[part], entityPart = valuePart && valuePart['_embedded'] ? valuePart['_embedded'][part] : null;
            if (propertyPart) {
                valuePart = propertyPart;
            }
            if (entityPart) {
                valuePart = entityPart;
            }
            if (!propertyPart && !entityPart) {
                valuePart = null;
                break;
            }
        }
        return valuePart;
    }
    static getLabelFieldName(entity) {
        let labelFieldName = 'id';
        const entityLabelFields = ['title', 'name', 'code'];
        for (const entityFieldLabel of entityLabelFields) {
            if (entity.hasOwnProperty(entityFieldLabel)) {
                labelFieldName = entityFieldLabel;
                break;
            }
        }
        return labelFieldName;
    }
}
FieldMetadataGrid.TYPE_STRING = 'string';
FieldMetadataGrid.TYPE_HTML = 'html';
FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE = 'dropdown'; // BAD, change to 'association' once we refactor entire FieldMetadata
FieldMetadataGrid.TYPE_ASSOCIATION_MANY = 'associationMany';
FieldMetadataGrid.TYPE_DATE = 'date';
FieldMetadataGrid.TYPE_DATE_MONTH_AND_YEAR = 'dateMonthAndYear';
FieldMetadataGrid.TYPE_TIME = 'time';
FieldMetadataGrid.DATE_TIME_TYPE = 'dateTime';
FieldMetadataGrid.TYPE_CHECKBOX = 'checkbox';
FieldMetadataGrid.TYPE_TRICHECKBOX = 'tricheckbox';
FieldMetadataGrid.TYPE_COMPANY_SETTING = 'company-setting';
FieldMetadataGrid.TYPE_COMPANY_SETTING_VALUE = 'company-setting-value';
FieldMetadataGrid.TYPE_NUMBER = 'number';
FieldMetadataGrid.TYPE_DECIMAL = 'decimal';
FieldMetadataGrid.TYPE_LINK = 'link';
FieldMetadataGrid.TYPE_PERMISSION_INFORMATION = 'permissionInformation';
FieldMetadataGrid.TYPE_OPENMODULE = 'openmodule';
FieldMetadataGrid.TYPE_COLOR = 'color';
FieldMetadataGrid.TYPE_IMAGE = 'image';
FieldMetadataGrid.TYPE_PHONE_NUMBER = 'phoneNumber';
FieldMetadataGrid.TYPE_DOCUMENT_DOWNLOAD = 'documentDownload';
FieldMetadataGrid.TYPE_ELDA_DOWNLOAD = 'eldaDownload';
FieldMetadataGrid.TYPE_EXTERNAL_LINK = 'externalLink';
FieldMetadataGrid.TYPE_LEASED_EMPLOYEE_ABSCENCE_DATE = 'leasedEmployeeAbsenceDate';
FieldMetadataGrid.TYPE_WORK_HOUR_TIME = 'workHourTime';
FieldMetadataGrid.TYPE_EMAIL = 'email';
FieldMetadataGrid.TYPE_LOCK_STATE = 'lockState';
FieldMetadataGrid.TYPE_CURRENT_BRANCHOFFICE = 'currentBranchOffice';
FieldMetadataGrid.FILTER_TYPE_CONTAINS = 'contains';
FieldMetadataGrid.FILTER_TYPE_EQUALS = 'equals';
FieldMetadataGrid.FILTER_TYPE_STARTS_WITH = 'startsWith';
FieldMetadataGrid.FILTER_TYPE_ENDS_WITH = 'endsWith';
FieldMetadataGrid.FILTER_TYPE_IN = 'in';
FieldMetadataGrid.FILTER_TYPE_DATE = 'date';
FieldMetadataGrid.FILTER_TYPE_RANGE_DATE = 'range_date';
FieldMetadataGrid.FILTER_TYPE_CHECKBOX = 'checkbox';
FieldMetadataGrid.FILTER_TYPE_TRICHECKBOX = 'tricheckbox';
FieldMetadataGrid.FILTER_TYPE_LOCK_STATE = 'lockState';
FieldMetadataGrid.FILTER_TYPE_DROPDOWN = 'dropdown';
FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN = 'multidropdown';
FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE = 'autocomplete';
FieldMetadataGrid.FILTER_TYPE_MONTHS = 'months';
FieldMetadataGrid.FILTER_TYPE_CUSTOM = 'custom';
FieldMetadataGrid.FILTER_TYPE_DISTANCE = 'distance';
FieldMetadataGrid.FILTER_GRID_FIELD_TYPE_MASTER_ENTITY = 'filterBasedOnMasterEntityField';
FieldMetadataGrid.FILTER_GRID_FIELD_BY_ANOTHER_FIELD = 'filterBasedOnAnotherEntityField';
FieldMetadataGrid.FILTER_GRID_COLLECTIVE_AGREEMENT_CONTRACT_TYPE = 'filterBasedOnCollectiveContractType';
FieldMetadataGrid.FILTER_GRID_INQUIRY_POSITION_CONDITION = 'filterInquiryPositionConditions';
