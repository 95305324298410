<div #wizardContainer id="wizard-container" class="fx-row" fxLayout="row" fxLayoutAlign="start stretch">

  <app-generic-loading *ngIf="!wizard"></app-generic-loading>

  <div fxFlex="100" *ngIf="wizard && wizardElement">

    <div id="buttons-container" class="ui-g">
      <div *ngFor="let button of wizardElement.buttons">
        <button (click)="onWizardElementButtonClick($event, wizardElement, button)" [disabled]="button.isDisabled" pButton type="button" label="{{button.label}}"></button>
      </div>
    </div>

    <div *ngIf="wizardElement" #wizardElementModuleContainer class="ui-g" [style.height.px]="wizardElementModuleContainerHeight">
      <app-content-renderer
        *ngIf="wizardElement.module"
        [isDialog]="true"
        [moduleId]="wizardElement.module.id"
        [entity]="wizardService.getElementDetails(wizardElement).inputDetails.entity"
        [masterEntity]="wizardService.getElementDetails(wizardElement).inputDetails.masterEntity"
        [masterField]="wizardService.getElementDetails(wizardElement).inputDetails.masterEntityField"
        [masterFilterField]="wizardService.getElementDetails(wizardElement).inputDetails.masterFilterField"
        [masterFilterValue]="wizardService.getElementDetails(wizardElement).inputDetails.masterFilterValue"
        [masterEntityEditingField]="wizardService.getElementDetails(wizardElement).inputDetails.masterEntityEditingField"
      ></app-content-renderer>
    </div>

  </div>

</div>
