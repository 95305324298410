import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Location } from '@angular/common';
import { Element } from '../../services/element/element';
import { ModuleElement } from '../../services/module/module-element';
import { ModuleElementSelectionCommunicationService } from '../services/module.element.selection.communication.service';
import { GenericElementAbstract } from '../elements/generic-element-abstract.component';
import {Subject, Subscription} from 'rxjs';
import { ComponentService } from '../services/component-highlight-stack.service';
import { LayoutService } from '../../services/layout-service';
import { LocationService } from '../../services/location.service';
import { ModuleElementPart } from '../../services/module/module-element-part';
import { ModuleElementPartCrudService } from '../../services/module/module-element-part.crud.service';
import { ModulesStateService } from '../services/modules-state.service';
import { ModuleState } from 'app/shared/content-renderer/services/module-state';
import { ElementContext } from 'app/shared/content-renderer/services/ElementContext';
import {ActivatedRoute} from '@angular/router';
import {ElementsSubscriptionService} from '../services/elements-subscription.service';
import {takeUntil} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {ModuleNavigationService} from '../services/navigation/module-navigation.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-split-template',
  templateUrl: './split-template.component.html',
  styleUrls: ['./split-template.component.scss'],
})
export class SplitTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('partContainer', {static: false}) set partContainer(partContainer: ElementRef) {
    this._partContainer = partContainer;

    this.setPartContainerHeightAndWidth();
  }

  @Input() set entity(entity: any) {
    this.selectedEntity = entity;
  }
  @Input() moduleElement: ModuleElement;
  @Input() isPreview ?= false;
  @Input() masterElementContext: ElementContext = null;
  @Input() masterField?: string;
  @Input() masterEntity?: any;
  @Input() masterEntityEditingField?: string;
  @Input() isPart = false;
  @Input() isDialog = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  @Input() justAFilter?: any;

  protected _partContainer: ElementRef;

  public partContainerHeight = 10;
  public partContainerWidth = 10;
  public moduleElementParts: ModuleElementPart[] = [];
  public unsubscribe = new Subject<void>();

  public part: any = null;

  public genericComponent: GenericElementAbstract;

  public parentComponent: GenericElementAbstract;

  private subscriptions: Subscription[] = [];

  public selectedEntity: any = null;
  public leftRightToolbarSize = 5;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.handleParts();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layoutService.onLayoutSizeChanged();
  }

  constructor(
    private moduleElementSelectionService: ModuleElementSelectionCommunicationService,
    private componentService: ComponentService,
    private layoutService: LayoutService,
    private locationService: LocationService,
    private elementRef: ElementRef,
    private moduleElementPartCrudService: ModuleElementPartCrudService,
    private modulesStateService: ModulesStateService,
    private route: ActivatedRoute,
    private location: Location,
    private elementsSubscriptionService: ElementsSubscriptionService,
    private moduleNavigation: ModuleNavigationService,
    public cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    if (this.moduleElement) {
      this.subscriptions.push(
        this.moduleElementPartCrudService.getActiveParts(this.moduleElement.id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
          (parts) => {
            this.moduleElementParts = parts.filter(moduleElementPart => {
              return moduleElementPart.isGranted && moduleElementPart.isGranted['view'];
            });
            this.handleParts();
          }
        )
      );
    }

    this.subscriptions.push(
      this.layoutService.layoutSizeChanged$.subscribe(() => {
        this.setPartContainerHeightAndWidth();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public onSplitLayoutChanged(event) {
    this.layoutService.onLayoutSizeChanged();
  }

  protected handleParts(): void {
    if (this.hasParts()) {
      let part = this.moduleElementParts[0];

      this.subscriptions.push(
        this.route.params.subscribe((params) => {
          let partModuleId = params ? params.partModuleId : null;

          if (!partModuleId && this.locationService.hasParam('part-module')) {
            partModuleId = this.locationService.getParam('part-module');
          }

          if (partModuleId && this.partExists(+partModuleId)) {
            part = this.moduleElementParts.find((modulePart) => {
              return modulePart && modulePart.module && modulePart.module.id === +partModuleId;
            });
          }

          if (part && part.module && part.module.id) {
            this.onPartTabChange(part);
          }
        })
      );
    }
  }

  public partExists(partModuleId: number): boolean {
    return this.moduleElementParts.findIndex((modulePart) => { return modulePart.module.id === partModuleId; }) !== -1;
  }

  public leftToolbarExists(element: Element) {
    return element.leftToolbarItems && element.leftToolbarItems.length > 0;
  }

  public rightToolbarExists(element: Element) {
    return element.rightToolbarItems && element.rightToolbarItems.length > 0;
  }

  public onGenericComponentInstantiated(genericComponent) {
    this.genericComponent = genericComponent;
  }

  public getToolbarExtraParams() {
    const extraParams = {};

    if (this.genericComponent) {
      extraParams[this.genericComponent.getToolbarContextName()] = this.genericComponent;
    }

    return extraParams;
  }

  public onPartTabChange(part, destroySubscriptions = false) {
    const mainModuleState = this.modulesStateService.getCurrent();

    if (mainModuleState && part.module) {
      mainModuleState.addPart(new ModuleState(part.module.id, part.module, '', {}, false, this.entity, true));
    }

    this.part = part;

    if (destroySubscriptions) {
      this.elementsSubscriptionService.destroyPartsByCurrentModule().subscribe();
    }

    this.replaceRoute();
  }

  private hasParts() {
    return this.moduleElementParts && this.moduleElementParts.length > 0;
  }

  private setPartContainerHeightAndWidth() {
    if (this._partContainer) {
      this.partContainerHeight = this.elementRef.nativeElement.clientHeight;
      this.partContainerWidth = this.elementRef.nativeElement.clientWidth;
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private replaceRoute(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        const mainModuleState = this.modulesStateService.getCurrent(),
          isInCorrectModule = +mainModuleState.id === +params.moduleId;

        if (isInCorrectModule && mainModuleState.isDetailsView &&
          (this.part && !params.partModuleId || (+params.partModuleId !== +this.part.module.id))
        ) {
          mainModuleState.url = this.moduleNavigation.getModuleRouter().replaceRoutePart(this.part.module);
        }

        ChangeDetectorRefHelper.detectChanges(this);
      })
    );
  }

}
