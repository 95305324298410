import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { DoubleClickService } from '../../../../../services/double-click.service';
import { cloneDeep } from 'lodash';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {Guid} from 'guid-typescript';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {GenericElementAbstract} from '../../../../generic-element-abstract.component';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-module',
  templateUrl: './toolbar-item-open-module.component.html'
})
export class ToolbarItemOpenModule extends ToolbarItemAbstract {

  protected toolbarItem: ToolbarItem;
  protected entity: any;

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService
  ) {
    super();
  }

  ngOnInit() {

  }

  public click() {
    const component = this.getComponent();

    if (this.getToolbarItem().actionModuleId && component instanceof AbstractGenericGridComponent) {
        this.doOpenModuleInGridContext(component);
    }else if (this.getToolbarItem().actionModuleId && component instanceof FormViewerComponent) {
        this.doOpenModuleInFormContext(component);
    }
  }

  protected doOpenModuleInFormContext(formComponent: FormViewerComponent) {
    this.toolbarItem = this.getToolbarItem();

    if (this.getToolbarItem().onclick === 'edit') {
      formComponent.triggerEditEntityActions();
      this.entity = formComponent.entity;
    } else {
      formComponent.triggerAddEntityActions();
      this.entity = {
        id: null,
        uniqueId: Guid.create().toString(),
        _links: {
          self: {
            href: formComponent.getElementDataModelApiRoute()
          }
        },
        fqn:formComponent.entity.fqn
      }
    }

    if (!this.validateInGridContext(formComponent)) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }

    this.doubleClickService.setForm(formComponent)
      .setDialogOptions({
        height: +this.getToolbarItem().targetDialogHeight,
        width: +this.getToolbarItem().targetDialogWidth,
        isModal: this.getToolbarItem().isTargetDialogModal,
        maximized: this.getToolbarItem().isTargetDialogMaximized
      })
      .handleOpenModule(formComponent, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, this.entity);
  }

  protected doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();

    if (this.getToolbarItem().onclick === 'edit') {
      gridComponent.triggerEditEntityActions();
      this.entity = gridComponent.selectedEntity;
    } else {
      gridComponent.triggerAddEntityActions();
      this.entity = cloneDeep(gridComponent.emptyEntity);
    }

    if (!this.validateInGridContext(gridComponent)) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }

    this.doubleClickService.setGrid(gridComponent)
      .setDialogOptions({
        height: +this.getToolbarItem().targetDialogHeight,
        width: +this.getToolbarItem().targetDialogWidth,
        isModal: this.getToolbarItem().isTargetDialogModal,
        maximized: this.getToolbarItem().isTargetDialogMaximized
      })
      .handleOpenModule(gridComponent, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, this.entity);
  }

  protected validateInGridContext(gridComponent) {
    let isValid = true;

    if (this.getToolbarItem().onclick === 'edit' && !this.entity) {
      this.setValidateMessage('Edit called but no entity has been selected!');

      isValid = false;
    } else if (!this.toolbarItem.actionModuleId) {
      this.setValidateMessage('Toolbar Item action module not assigned.');

      isValid = false;
    }

    return isValid;
  }
}
