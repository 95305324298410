import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  Renderer,
  Optional,
  Injector, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { environment } from './../../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';

import { DateHelper } from '../../helpers/date.helper';

import { FormService } from './../form.service';
import { FormViewerService } from './../form-viewer.service';

import { ElementLabelComponent } from './element-label.component';
import { ElementInput, Form } from '../models/index';
import { FormAction, FormElementAction, FormActions } from '../models/form';
import {DatamodelCrudService} from "../../services/datamodel/datamodel.crud.service";
import {Entity} from '../../helpers/entity';

export interface InputFocusAware {
  focusInput();
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input',
  templateUrl: './element-input.component.html',
  styles: [`
    :host {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: stretch;
      align-items: center;
    }

    .prefix-item,
    .suffix-item {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      white-space: nowrap;
      padding: 0.25em .5em;
      border-top: 1px solid #a8a8a8;
      border-bottom: 1px solid #a8a8a8;
      background: #a8a8a8;
      color: #fff;
    }

    .prefix-item {
      border-left: 1px solid #a8a8a8;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .suffix-item {
      border-right: 1px solid #a8a8a8;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .middle-item {
      height: 100%;
      width: 100%;
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
    }

    .middle-item.remove-input-border-radius-left ::ng-deep input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .middle-item.remove-input-border-radius-right ::ng-deep input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
`]
})
export class ElementInputComponent extends ElementLabelComponent implements OnInit, OnDestroy, AfterViewInit, InputFocusAware {
  @Input() element: ElementInput;

  @ViewChild('inputElement', {static: false}) inputElement;

  iconBaseUrl: string = environment.baseUrl;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService,
    protected datamodelCrudService?: DatamodelCrudService
  ) {
    super(formService, cdr, formViewerService, translateService, datamodelCrudService);
  }

  onComponentInit() {
    this.setFormControlName();
  }

  onComponentDestroy() {

  }

  onComponentAfterViewInit() {
  }

  public onElementValueChange(event) {
  }

  onFormAction(action: FormAction) {

    switch (action.className) {

        case FormActions.FOCUS_INPUT_ELEMENT:
            if (action.params &&
              action.params['formElement'] &&
              action.params['formElement'].value === this.element.objectHashId
            ) {
              this.focusInput();
            }
            break;
        default:
            console.error('No idea what to do', action, event);
            break;
    }
  }

  onActionSelect(event?: any) {
    this.filterActionAndHandleIt('onselect');
  }

  onActionUnselect(event?: any) {
    this.filterActionAndHandleIt('onunselect');
  }

  protected getEntityValue(useEmbedded: boolean = true): any|null {
    let value = null;

    if (this.entity && this.element.datamodelField) {
      value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, this.element.datamodelField, false, useEmbedded);
    }

    return value;
  }

  getValueFormatted() {
    if ((this.formGroup.get(this.formControlName) &&
      this.formGroup.get(this.formControlName).value !== null &&
      this.formGroup.get(this.formControlName).value !== '')
      || (this.formGroup.get(this.formControlName) &&  this.element.typeElement === 'switch')) {
      switch (this.element.typeElement) {
        case 'dropdown':
          if (this.element['dropdownFieldLabel']
            && this.formGroup.get(this.formControlName).value
            && this.formGroup.get(this.formControlName).value[this.element['dropdownFieldLabel']]) {
            return this.formGroup.get(this.formControlName).value[this.element['dropdownFieldLabel']];
          }

          return this.formGroup.get(this.formControlName).value;
        case 'date':
          let value = Entity.getValue(this.entity, this.element.datamodelField) ||
            Entity.getValueInEmbedded(this.entity, this.element.datamodelField);

          if (typeof value === 'string' && value !== '' && value !== null) {
            value = DateHelper.removeTimezone(value);
          }

          const dateTranslations: DateTranslations = this.translateService.instant('COMMON.DATE');
          const dateLocales: DateLocales = {
            firstDayOfWeek: dateTranslations.FIRST_DAY_OF_WEEK,
            dayNames: dateTranslations.DAY_NAMES,
            dayNamesShort: dateTranslations.DAY_NAMES_SHORT,
            dayNamesMin: dateTranslations.DAY_NAMES_MIN,
            monthNames: dateTranslations.MONTH_NAMES,
            monthNamesShort: dateTranslations.MONTH_NAMES_SHORT
          };
          let dateStr = '';

          if (this.element['dateFormat'] === 'date' || this.element['dateFormat'] === 'date+time') {
            dateStr += DateHelper.formatDate(
              new Date(value),
              this.element.inputFormat.date.format,
              dateLocales
            );
          }

          if (this.element['dateFormat'] === 'date+time') {
            dateStr += ` ${DateHelper.formatTime(
              new Date(value),
              this.element.inputFormat.date.hourFormatPmAm
            )}`;
          }

          if (this.element['dateFormat'] === 'time') {
            dateStr = DateHelper.formatTime(
              new Date(value),
              this.element.inputFormat.date.hourFormatPmAm
            );
          }

          return dateStr;
        case 'password':
          return '****';
        case 'switch':
        //   console.log('getformatted forswitch', this.element);
          return this.formGroup.get(this.formControlName).value ? this.element['onLabel'] : this.element['offLabel'];
        default:
          return this.formGroup.get(this.formControlName).value;
      }

    }

    return '';
  }

  getInputTooltip() {
    let errText = '';

    if (this.formGroup && this.formGroup.get(this.formControlName) && this.formGroup.get(this.formControlName).errors) {
      const errors = this.formGroup.get(this.formControlName).errors;

      for (const key in errors) {
        if (errors.hasOwnProperty(key) && typeof errors[key] !== 'function') {
          const err = errors[key];

          switch (key.toLowerCase()) {
            case 'required':
              errText += 'Pflichtfeld, ';
              break;
            case 'minlength':
              errText += `Mindestens ${err.requiredLength} Zeichen, `;
              break;
            case 'maxlength':
              errText += `Maximal ${err.requiredLength} Zeichen, `;
              break;
            case 'nowhitespice':
              errText += `Keine Leerzeichen erlaubt, `;
              break;
            case 'normalemailrule':
              errText += `Keine gültige E-Mail Adresse, `;
              break;
            case 'pattern':
              switch (err.requiredPattern) {
                case '^[0-9]*$':
                  errText += `Nur Zahlen erlaubt, `;
                  break;
              }
              break;
          }
        }
      }
    }

    if (errText) {
      errText = errText.substr(0, errText.length - 2);
    }

    return errText;
  }

  isValid() {
    if (this.formGroup && this.formGroup.get(this.formControlName)) {
      return this.formGroup.get(this.formControlName).valid;
    }
  }

    // @todo make this a bit better...
    hasEmailValidation() {
        if (this.element.validators && this.element.validators.length > 0) {
            const html5Validator = this.element.validators.find((validator) => {
                return (validator.key.toLowerCase() === 'html5.email');
            });

            if (html5Validator) {
                return true;
            }
        }
        return false;
    }

    isReadOnly() {
      return this.element.readOnly || !this.element.isGranted;
    }

    isReadOnlyOrDisabled(): boolean {
      return this.isReadOnly() || this.element.disabled || this.formGroup.get(this.formControlName).disabled;
    }

    getFontWeight() {
      return this.element.boldText ? 'bold' : 'normal';
    }

    hasUrlValidation() {
        if (this.element.validators && this.element.validators.length > 0) {
            const html5Validator = this.element.validators.find((validator) => {
                return (validator.key.toLowerCase() === 'html5.url');
            });

            if (html5Validator) {
                return true;
            }
        }
        return false;
    }

    onClickOpenUrl() {
        if (!this.getValue() || !this.isValid()) {
            return;
        }

        let href = '';

        if ((this.getValue().match(/http:\/\//g) || []).length > 0) {
            href = this.getValue();
        } else {
            href = `http://${this.getValue()}`;
        }

        const a = document.createElement('a');
        a.target = '_blank';
        a.href = href;
        a.click();
        a.remove();
    }

    onClickOpenEmail() {
        if (!this.getValue() || !this.isValid()) {
            return;
        }

        const a = document.createElement('a');
        a.target = '_blank';
        a.href = `mailto:${this.getValue()}`;
        a.click();
        a.remove();
    }

    onClickIcon() {
        this.filterActionAndHandleIt('onclickicon');
    }

    public figureOutLabel(record): string {
        if (this.element.displayConfig && this.element.displayConfig.length > 0) {
            return this.getLabelBasedOnConfig(record) || this.getLabelSimple(record);
        }

        return this.getLabelSimple(record);
    }

    private getLabelSimple(record): string {
      let result = '';

      if (record) {
        if (this.element['dropdownFieldLabel'] && record[this.element['dropdownFieldLabel']]) {
          result = record[this.element['dropdownFieldLabel']];
        } else if (record['name']) {
          result = record['name'];
        } else if (record['id']) {
          result = record['id'].toString();
        }
      }

      return result;
    }

    private getLabelBasedOnConfig(record): string {
        let displayLabel = '';

        for (const configOption of this.element.displayConfig) {
            if (!configOption || !configOption.fieldName) {
              continue;
            }

            const fieldLabel = this.formService.getEntityHydrator().getEntityPropertyValue(record, configOption.fieldName);

            if (fieldLabel) {
                displayLabel += configOption.preChacarter + fieldLabel.toString() + configOption.postCharacter;
            }
        }

        return displayLabel;
    }

  protected setFormControlName() {
    const fieldName = this.element['datamodelField'] && this.element['datamodelField'].replace(/\./g, '-p-') || '';
    const elObjectId: string = this.element.objectHashId;

    if (this.formGroup && fieldName) {
      this.formControlName = `${fieldName}_h_r_f_e_${elObjectId}`;
    } else if (this.formGroup && !fieldName) {
      const elLabel: string = this.element.label || '';
      this.formControlName = `no-dm_h_r_f_e_${elObjectId}`.trim();
    }

    if (!this.formGroup || !this.formGroup.get(this.formControlName)) {
      this.formControlName = '';
    }
  }

  public setupValue(): void {
    if (this.entity && !this.entity[this.element.datamodelField]) {
      this.setupDefaultValue();
    }
  }

  protected setupDefaultValue(): void {
    if (this.entity && this.element.defaultValue) {
      this.entity[this.element.datamodelField] = this.element.defaultValue;
    }
  }

  public focusInput() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }
}
