
    <div [formGroup]="formGroup" class="ui-g ui-g-12">

      <p-listbox
        appSeleniumDirective
        [element]="element"
        [options]="pulldownOptions"
        [formControlName]="formControlName"
        multiple="multiple"
        checkbox="checkbox"
        filter="filter"
        (onChange)="onChange($event)"
      >

      </p-listbox>
    </div>
  