import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {ElementContext, RuntimeFlagName} from '../../content-renderer/services/ElementContext';
import {ModuleState} from '../../content-renderer/services/module-state';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {GenericDialogModuleService} from '../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {TodoFormComponent} from '../../content-renderer/elements/custom/todo-form/todo-form.component';

export class ComponentCloseDialogExecutionStep extends AbstractExecutionStep {

  public static isApplicable(component: GenericElementAbstract, moduleState: ModuleState): boolean {
    const masterElementContext: ElementContext = component.masterElementContext;

    return (component instanceof FormViewerComponent || component instanceof TodoFormComponent) &&
      masterElementContext && masterElementContext.component instanceof AbstractGenericGridComponent &&
      moduleState.isDialog;
  }

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doClose(payload.getValue());
  }

  private doClose(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const masterElementContext: ElementContext = component.masterElementContext,
      moduleState: ModuleState = this.modulesStateService.getCurrent(),
      isDialogHideDisabled = component.getElementContext().isRuntimeFlagActive(RuntimeFlagName.DisableDialogPersistHideAfterSave);

    if (ComponentCloseDialogExecutionStep.isApplicable(component, moduleState) && !isDialogHideDisabled) {
      masterElementContext.component.moduleElementTargetElementDialogHide();

      const dialogService = this.injector.get(GenericDialogModuleService, null);
      dialogService.persistHide();

      if (component.getElementDatamodelEntityName() === masterElementContext.component.getElementDatamodelEntityName()) {
        const entity = masterElementContext.component.findEntity(component.getSelectedEntity());

        if (entity) {
          masterElementContext.component.setSelectedEntity(entity);
        }
      }

      masterElementContext.component.onAfterSave().subscribe(() => {
        masterElementContext.component.onRefresh().subscribe();
      });
    }

    return observableOf({status: true, content: null});
  }
}
