/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-pdf-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ng2-pdf-viewer/ng2-pdf-viewer.ngfactory";
import * as i3 from "ng2-pdf-viewer";
import * as i4 from "@angular/common";
import * as i5 from "../../../../core/executor/executor.service";
import * as i6 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i7 from "../../services/generic/generic-element-validation-execution-steps-factory";
import * as i8 from "../../../../core/executor/factory/execution-step-factory.service";
import * as i9 from "./generic-pdf-viewer.component";
import * as i10 from "../../services/component-highlight-stack.service";
import * as i11 from "../../services/entity-data-store.service";
import * as i12 from "../../services/modules-state.service";
import * as i13 from "../../../validators/services/entity-validator";
import * as i14 from "../../../services/generic-crud.service";
import * as i15 from "../../../../core/service/user-session.service";
import * as i16 from "../../../services/permission/permission.service";
import * as i17 from "../../services/elements-stack.service";
var styles_GenericPdfViewerComponent = [i0.styles];
var RenderType_GenericPdfViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericPdfViewerComponent, data: {} });
export { RenderType_GenericPdfViewerComponent as RenderType_GenericPdfViewerComponent };
function View_GenericPdfViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "pdf-viewer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onPageResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PdfViewerComponent_0, i2.RenderType_PdfViewerComponent)), i1.ɵdid(2, 9158656, null, 0, i3.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], renderText: [1, "renderText"], showAll: [2, "showAll"], autoresize: [3, "autoresize"], fitToPage: [4, "fitToPage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_GenericPdfViewerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { gridContainer: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericPdfViewerComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GenericPdfViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-pdf-viewer", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GenericPdfViewerComponent_0, RenderType_GenericPdfViewerComponent)), i1.ɵprd(512, null, i5.ExecutorService, i5.ExecutorService, [i6.ExecutorActionsService]), i1.ɵprd(512, null, i7.GenericElementValidationExecutionStepsFactory, i7.GenericElementValidationExecutionStepsFactory, [i8.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i9.GenericPdfViewerComponent, [i10.ComponentService, i1.ViewContainerRef, i11.EntityDataStoreService, i12.ModulesStateService, i5.ExecutorService, i7.GenericElementValidationExecutionStepsFactory, i13.EntityValidator, i14.GenericCrudService, i15.UserSessionService, i16.PermissionService, i1.ElementRef, i1.ChangeDetectorRef, i17.ElementsStackService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var GenericPdfViewerComponentNgFactory = i1.ɵccf("app-pdf-viewer", i9.GenericPdfViewerComponent, View_GenericPdfViewerComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", entity: "entity" }, { componentInstantiated: "componentInstantiated" }, []);
export { GenericPdfViewerComponentNgFactory as GenericPdfViewerComponentNgFactory };
