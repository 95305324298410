/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../p-button/p-button.directive";
import * as i4 from "./element-button-default.component";
import * as i5 from "../form.service";
import * as i6 from "../form-viewer.service";
var styles_ElementButtonDefaultComponent = ["[_nghost-%COMP%]   .button-wrapper[_ngcontent-%COMP%] {\n    padding: .5em;\n  }"];
var RenderType_ElementButtonDefaultComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementButtonDefaultComponent, data: {} });
export { RenderType_ElementButtonDefaultComponent as RenderType_ElementButtonDefaultComponent };
export function View_ElementButtonDefaultComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "button-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["pButton", ""]], [[4, "width", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickElement($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(3, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵdid(4, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { label: [0, "label"], iconUrl: [1, "iconUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getButtonLabel(_co.element.label); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.getButtonLabel(_co.element.label); var currVal_4 = _co.getButtonImageSource(_co.element); _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getButtonWidth(); var currVal_1 = (_co.isReadOnlyOrDisabled() || !_co.entity); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ElementButtonDefaultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-element-button-default", [], null, null, null, View_ElementButtonDefaultComponent_0, RenderType_ElementButtonDefaultComponent)), i0.ɵdid(1, 4964352, null, 0, i4.ElementButtonDefaultComponent, [i5.FormService, i0.ChangeDetectorRef, i6.FormViewerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementButtonDefaultComponentNgFactory = i0.ɵccf("app-form-element-button-default", i4.ElementButtonDefaultComponent, View_ElementButtonDefaultComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementButtonDefaultComponentNgFactory as ElementButtonDefaultComponentNgFactory };
