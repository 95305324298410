<div class="buttons-container" *ngIf="items">
  <div class="item-spacer"></div>
  <!-- toolbar items -->
  <div *ngFor="let item of items" [ngClass]="{ 'item-left': item.isLeft, 'item-right': !item.isLeft }">
    <!-- Text -->
    <span
      *ngIf="item.isText"
      appSeleniumDirective
      [toolbarItem]="item"
      style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
    >{{item.label}}</span>
    <!-- seperator -->
    <span *ngIf="!item.isText && item.isSeparator"
      appSeleniumDirective
      [toolbarItem]="item"
      class="item-vertical-line-container"
    ></span>
    <!-- checkbox button -->
    <!--
      we also could use plain html for this cause we are using this component only two times at the whole application.
      Only ngModel has to be an object reference instead of a function call.
      <div *ngIf="!item.isText && item.onclick === 'softDeleteableFilter' || item.onclick === 'expireableFilter'" class="checkbox-button" [pTooltip]="item.onmouseover" tooltipPosition="top">
        <img *ngIf="getToolbarItemImageSource(item)" class="image flex-item" [src]="getToolbarItemImageSource(item)" [alt]="getToolbarItemImageSource(item)" />
        <p-checkbox [label]="item.label" [(ngModel)]="isItemCheckboxEnabled(item)" class="flex-item" binary="true"></p-checkbox>
      </div>
    -->
    <app-generic-checkbox
      *ngIf="!item.isText && item.onclick && item.onclick.length > 0 && item.onclick === 'withoutMasterFilterFieldFilter'"
      appSeleniumDirective
      [toolbarItem]="item"
      (click)="onItemClick(item)" [title]="item.label" [imageSource]="getToolbarItemImageSource(item)" [tooltipText]="item.onmouseover" [isChecked]="!isItemCheckboxEnabled(item)"></app-generic-checkbox>
    <app-generic-toggle-button
      *ngIf="!item.isText && (item.onclick === 'softDeleteableFilter' || item.onclick === 'expireableFilter' || item.onclick === 'ignoreBranchOfficeFilter')"
      appSeleniumDirective
      [toolbarItem]="item"
      (click)="onItemClick(item)" [title]="item.label" [imageSource]="getToolbarItemImageSource(item)" [tooltipText]="item.onmouseover" [isChecked]="isItemCheckboxEnabled(item)"></app-generic-toggle-button>
    <!--<app-generic-checkbox *ngIf="!item.isText && item.onclick === 'withoutMasterFilterFieldFilter' || item.onclick === 'softDeleteableFilter' || item.onclick === 'expireableFilter'" (click)="onItemClick(item)" [title]="item.label" [imageSource]="getToolbarItemImageSource(item)" [tooltipText]="item.onmouseover" [isChecked]="isItemCheckboxEnabled(item)"></app-generic-checkbox>-->
    <!-- button / extended pButton, take a look at /shared/p-button/ -->
    <button
      pButton
      appSeleniumDirective
      [toolbarItem]="item"
      *ngIf="!item.isText && item.onclick !== 'form_counter' && item.onclick !== 'withoutMasterFilterFieldFilter' && item.onclick !== 'softDeleteableFilter' && item.onclick !== 'expireableFilter' && item.onclick !== 'ignoreBranchOfficeFilter' && !item.isSeparator"
      (click)="onItemClick(item)"
      [label]="item.label"
      [iconUrl]="getToolbarItemImageSource(item)"
      [pTooltip]="item.onmouseover"
      tooltipPosition="top"
      style="margin-left: .2em; margin-right: .2em; white-space: nowrap"
      [disabled]="item.disabled"
      [style.background-color]="item.backgroundColor"
    ></button>
    <!-- form counter -->
    <ng-container *ngIf="!item.isText && item.onclick === 'form_counter'">
      {{getText(item)}}
    </ng-container>
  </div>

  <!-- additional items -->
  <div class="item-right" *ngFor="let item of additionalItems">
    <!-- Text -->
    <span *ngIf="item.isText" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      appSeleniumDirective
      [toolbarItem]="item"
    >{{item.label}}</span>
    <!-- checkbox button -->
    <app-generic-checkbox
      *ngIf="!item.isText && item.onclick && item.onclick.length > 0 && (item.onclick === 'withoutMasterFilterFieldFilter')"
      appSeleniumDirective
      [toolbarItem]="item"
      (click)="onItemClick(item)" [title]="item.label" [imageSource]="getToolbarItemImageSource(item)" [tooltipText]="item.onmouseover" [isChecked]="!isItemCheckboxEnabled(item)"></app-generic-checkbox>
    <app-generic-toggle-button
      *ngIf="!item.isText && (item.onclick === 'softDeleteableFilter' || item.onclick === 'expireableFilter')"
      appSeleniumDirective
      [toolbarItem]="item"
      (click)="onItemClick(item)" [title]="item.label" [imageSource]="getToolbarItemImageSource(item)" [tooltipText]="item.onmouseover" [isChecked]="isItemCheckboxEnabled(item)"></app-generic-toggle-button>
    <!-- button / extended pButton, take a look at /shared/p-button/ -->
    <button
      pButton
      *ngIf="!item.isText && item.onclick && item.onclick.length > 0 && item.onclick !== 'withoutMasterFilterFieldFilter' && item.onclick !== 'softDeleteableFilter' && item.onclick !== 'expireableFilter'"
      (click)="onItemClick(item)"
      appSeleniumDirective
      [toolbarItem]="item"
      [label]="item.label"
      [iconUrl]="getToolbarItemImageSource(item)"
      [pTooltip]="item.onmouseover"
      tooltipPosition="top"
      style="margin-left: .2em; margin-right: .2em; white-space: nowrap"
      [disabled]="item.disabled"
      [style.background-color]="item.backgroundColor"
    ></button>
  </div>

  <!-- admin items -->
  <div class="item-right" *ngIf="adminItems && adminItems.length > 0">
    <app-generic-split-button id="ToolbarItemSplit" [items]="adminItems"></app-generic-split-button>
  </div>
</div>
