/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../generic-loading/generic-loading.component.ngfactory";
import * as i3 from "../../generic-loading/generic-loading.component";
import * as i4 from "primeng/components/dom/domhandler";
import * as i5 from "primeng/components/button/button";
import * as i6 from "../../../../p-button/p-button.directive";
import * as i7 from "../../../content-renderer.component.ngfactory";
import * as i8 from "../../../content-renderer.component";
import * as i9 from "../../../../services/generic-crud.service";
import * as i10 from "../../../../services/generic-crud-bulk.service";
import * as i11 from "../../../../services/layout-service";
import * as i12 from "@angular/common";
import * as i13 from "@angular/flex-layout/flex";
import * as i14 from "@angular/flex-layout/core";
import * as i15 from "./service/wizard.service";
import * as i16 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i17 from "../../../services/modules-state.service";
import * as i18 from "../../../../../core/executor/executor.service";
import * as i19 from "../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i20 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i21 from "./wizard.component";
import * as i22 from "../../../services/component-highlight-stack.service";
import * as i23 from "../../../services/entity-data-store.service";
import * as i24 from "../../../../validators/services/entity-validator";
import * as i25 from "../../../../../core/service/user-session.service";
import * as i26 from "../../generic-toolbar/services/check/toolbar-item-check.service";
import * as i27 from "../../../../../core/job-runner/job-container.service";
import * as i28 from "../../../../services/permission/permission.service";
var styles_WizardComponent = [i0.styles];
var RenderType_WizardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardComponent, data: {} });
export { RenderType_WizardComponent as RenderType_WizardComponent };
function View_WizardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, i2.View_GenericLoadingComponent_0, i2.RenderType_GenericLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WizardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onWizardElementButtonClick($event, _co.wizardElement, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(4608, null, i4.DomHandler, i4.DomHandler, []), i1.ɵdid(3, 4341760, null, 0, i5.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵdid(4, 4341760, null, 0, i6.PButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.label, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.label, ""); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.isDisabled; _ck(_v, 1, 0, currVal_0); }); }
function View_WizardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-renderer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ContentRendererComponent_0, i7.RenderType_ContentRendererComponent)), i1.ɵdid(1, 245760, null, 0, i8.ContentRendererComponent, [i9.GenericCrudService, i10.GenericCrudBulkService, i1.ElementRef, i11.LayoutService, i1.ChangeDetectorRef], { entity: [0, "entity"], masterField: [1, "masterField"], masterEntity: [2, "masterEntity"], masterEntityEditingField: [3, "masterEntityEditingField"], isDialog: [4, "isDialog"], masterFilterField: [5, "masterFilterField"], masterFilterValue: [6, "masterFilterValue"], moduleId: [7, "moduleId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wizardService.getElementDetails(_co.wizardElement).inputDetails.entity; var currVal_1 = _co.wizardService.getElementDetails(_co.wizardElement).inputDetails.masterEntityField; var currVal_2 = _co.wizardService.getElementDetails(_co.wizardElement).inputDetails.masterEntity; var currVal_3 = _co.wizardService.getElementDetails(_co.wizardElement).inputDetails.masterEntityEditingField; var currVal_4 = true; var currVal_5 = _co.wizardService.getElementDetails(_co.wizardElement).inputDetails.masterFilterField; var currVal_6 = _co.wizardService.getElementDetails(_co.wizardElement).inputDetails.masterFilterValue; var currVal_7 = _co.wizardElement.module.id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_WizardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["wizardElementModuleContainer", 1]], null, 2, "div", [["class", "ui-g"]], [[4, "height", "px"]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardComponent_5)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.wizardElement.module; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wizardElementModuleContainerHeight; _ck(_v, 0, 0, currVal_0); }); }
function View_WizardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["fxFlex", "100"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i13.DefaultFlexDirective, [i1.ElementRef, i14.StyleUtils, i14.LAYOUT_CONFIG, i13.FlexStyleBuilder, i14.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g"], ["id", "buttons-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardComponent_3)), i1.ɵdid(4, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardComponent_4)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "100"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.wizardElement.buttons; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.wizardElement; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_WizardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { wizardContainer: 0 }), i1.ɵqud(671088640, 2, { wizardElementModuleContainer: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["wizardContainer", 1]], null, 6, "div", [["class", "fx-row"], ["fxLayout", "row"], ["fxLayoutAlign", "start stretch"], ["id", "wizard-container"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i13.DefaultLayoutDirective, [i1.ElementRef, i14.StyleUtils, [2, i13.LayoutStyleBuilder], i14.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i13.DefaultLayoutAlignDirective, [i1.ElementRef, i14.StyleUtils, [2, i13.LayoutAlignStyleBuilder], i14.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardComponent_1)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardComponent_2)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.wizard; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.wizard && _co.wizardElement); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_WizardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "app-custom-wizard", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_WizardComponent_0, RenderType_WizardComponent)), i1.ɵprd(512, null, i15.WizardService, i15.WizardService, [i16.ExecutionStepFactoryService, i17.ModulesStateService]), i1.ɵprd(512, null, i18.ExecutorService, i18.ExecutorService, [i19.ExecutorActionsService]), i1.ɵprd(512, null, i20.GenericElementValidationExecutionStepsFactory, i20.GenericElementValidationExecutionStepsFactory, [i16.ExecutionStepFactoryService]), i1.ɵdid(4, 245760, null, 0, i21.WizardComponent, [i15.WizardService, i22.ComponentService, i1.ViewContainerRef, i17.ModulesStateService, i9.GenericCrudService, i23.EntityDataStoreService, i18.ExecutorService, i20.GenericElementValidationExecutionStepsFactory, i24.EntityValidator, i25.UserSessionService, i26.ToolbarItemCheckService, i27.JobContainerService, i16.ExecutionStepFactoryService, i11.LayoutService, i19.ExecutorActionsService, i28.PermissionService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var WizardComponentNgFactory = i1.ɵccf("app-custom-wizard", i21.WizardComponent, View_WizardComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", fields: "fields", masterEntity: "masterEntity", masterField: "masterField" }, { componentInstantiated: "componentInstantiated" }, []);
export { WizardComponentNgFactory as WizardComponentNgFactory };
