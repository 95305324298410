import { EventEmitter } from '@angular/core';
import { Datamodel } from './../../services/datamodel/datamodel';
import {noUndefined} from "@angular/compiler/src/util";
import {FieldDisplayConfig} from "../../services/module/field-display-config";
import {Guid} from 'guid-typescript';
import {Icon} from '../../models/Icon';

// @todo Model Classes should only contain the model... a note to myself cs

/********** Classes **********/
export abstract class Element implements App.Form.Element {

  id: number;
  objectHashId: string;
  elementId: number;
  datamodelId: number;
  parent: Element;
  type: string;
  typeElement: string;
  label: string;
  canHaveChildren: boolean;
  children: Element[] = [];
  isHidden: boolean;
  actions: ElementAction[];
  displayConfig: ElementDisplayConfig[];
  isValid: boolean = true;
  value: any;
  datamodel: Datamodel;
  validators: any[];
  datamodelField: string;
  collectiveAgreementField: string;
  isCollectiveAgreementFromLocalStorage: boolean;
  salaryClassificationField: string;
  fieldReferenceAssociationType: string;
  fieldReferenceAssociation: string;
  fieldWorkingMode: string;
  fieldHasCompanyAgreement: string;
  globalFieldWageVisible: boolean;
  globalFieldFactorVisible: boolean;
  globalFieldFactorisedWageVisible: boolean;
  globalFieldWageLabel: string;
  globalFieldFactorLabel: string;
  globalFieldFactorisedWageLabel: string;
  globalFieldSection: string;
  validationsError: string;
  showLabel: boolean;
  inputFormat: any;
  staticFilterValue: any;

  entity: any;

  constructor(
    initElement?: Object
  ) {

    this.id = 0;
    this.objectHashId = undefined;
    this.elementId = 0;
    this.datamodelId = 0;
    this.parent = undefined;
    this.type = undefined;
    this.typeElement = undefined;
    this.label = undefined;
    this.canHaveChildren = false;
    this.isHidden = false;
    this.actions = [];
    this.displayConfig = [];
    if (initElement && typeof (initElement) === 'object') {
      this.setValuesFromObject(initElement);
    }

    if (!this.objectHashId) {
      this.objectHashId = Guid.create().toString();
    }
  }

  public setValue(value: any): this {
    this.value = value;
    return this;
  }

  public getValue(): any {
    return this.value;
  }

  public setEntity(entity: any): this {
    this.entity = entity;
    return this;
  }

  public getEntity(): any {
    return this.entity;
  }

  public setValuesFromObject(object: Object): Element {
    for (const key in object) {
      if (object.hasOwnProperty(key) && typeof object[key] !== 'function') {
        if (key === '_embedded') {
          if (object[key]['datamodel'] && +object[key]['datamodel']['id'] && this.hasOwnProperty('datamodel')) {
              this['datamodel'] = {
                  id: +object[key]['datamodel']['id'],
                  name: object[key]['datamodel']['name'],
                  apiRoute: object[key]['datamodel']['apiRoute'],
                  fullApiRoute: object[key]['datamodel']['fullApiRoute'],
                  entityCollectionName: object[key]['datamodel']['entityCollectionName'],
                  isIndependent: object[key]['datamodel']['isIndependent']
              };
          }
        }
        // @todo next silly hack cause of the api return..
        if (key === 'validators' || key === 'actions' || key === 'displayConfig') {
          if (object[key].length > 0) {
            object[key].map((obj) => {
              if (obj.hasOwnProperty('_links')) {
                delete obj._links;
              }
              if (obj.hasOwnProperty('fqn')) {
                delete obj.fqn;
              }
              if (obj.hasOwnProperty('params') && typeof (obj.params) === 'string') {
                let paramsObj;
                try {
                  paramsObj = JSON.parse(obj.params);
                } catch (error) {
                  paramsObj = undefined;
                }
                obj.params = paramsObj;
              }
            });
          }
        }

        if ((
          (key === 'inputFormat' && this.hasOwnProperty('inputFormat')) ||
          (key === 'assoc' && this.hasOwnProperty('assoc')) ||
          (key === 'icon' && this.hasOwnProperty('icon'))
        ) && object[key] && typeof (object[key]) === 'string') {
          let obj;
          try {
            obj = <{}>JSON.parse(object[key]);
          } catch (error) {
            obj = undefined;
          }
          object[key] = obj;
        }

        // @todo remove this hack later
        if (this.typeElement === 'date') {
          if (key === 'inputFormat' && object[key]['date'] === undefined) {
            object[key]['date'] = {
              format: 'dd.mm.yy',
              hourFormatPmAm: false
            };
          }
          if (key === 'dateFormat' && object[key] && object[key] === 'dd.mm.yy') {
            object[key] = 'date';
          }
        }

        if (this.hasOwnProperty(key)) {
          if ((key === 'id' || key === 'elementId' || key === 'datamodelId') && isNaN(object[key])) {
            object[key] = 0;
          }

          if (typeof (this[key.replace('_', '')]) === 'boolean' && typeof (object[key]) === 'number') {
            object[key] = object[key] === 1 ? true : false;
          }

          this[key.replace('_', '')] = object[key];
        }
      }
    }

    return this.setLabelsFromObject(object);
  }

  public setLabelsFromObject(object: Object): Element {
    const name = object['name'] || object['_name'];
    const label = object['label'] || object['_label'];
    const fieldLabel = object['fieldLabel'] || object['_fieldLabel'];

    if (label) {
      this.label = label;
    }

    if (name && !label) {
      this.label = name;
    }

    if (fieldLabel && !name && !label) {
      this.label = fieldLabel;
    }

    if (this.label === '') {
      this.label = '-';
    }

    return this;
  }

  public prepareForApi(): Element {
    if (this['dropdownFieldLabel'] && typeof (this['dropdownFieldLabel']) !== 'string') {
      this['dropdownFieldLabel'] = JSON.stringify(this['dropdownFieldLabel']);
    }

    if (this['dropdownFieldValue'] && typeof (this['dropdownFieldValue']) !== 'string') {
      this['dropdownFieldValue'] = JSON.stringify(this['dropdownFieldValue']);
    }

    delete this.parent;

    for (const key in this) {
      if (this.hasOwnProperty(key) && this.hasOwnProperty(key) && typeof this[key] !== 'function') {
        if (this[key] === null) {
          this[key] = undefined;
        }
      }
    }

    if (this['inputFormat'] !== undefined && typeof (this['inputFormat']) !== 'string') {
      this['inputFormat'] = JSON.stringify(this['inputFormat']);
    }

    if (this['assoc'] !== undefined && typeof (this['assoc']) !== 'string') {
      this['assoc'] = JSON.stringify(this['assoc']);
    }

    if (this['icon'] !== undefined && typeof (this['icon']) !== 'string') {
      this['icon'] = JSON.stringify(this['icon']);
    }

    return this;
  }

  public isNew() {
    return !+this.id;
  }
}

export class ElementLabel extends Element {
  textOnly?: boolean;

  constructor(
    public orientation = 'left',
    public showLabel = true,
    public textAlign = 'left',
    public inputAlign: string = 'flex-start',
    public labelWidth: string = undefined,
    public inputWidth: string = undefined,
    public labelStyle: string = undefined,
    public labelPadding: string = undefined,
    public inputPadding: string = undefined,
    public inputHeight: string = undefined
  ) {
    super();
  }
}

export class ElementAction implements App.Form.Element.Action {

  label: string;
  command: string;
  className: string;
  params: any;

  constructor(
  ) {
    this.label = undefined;
    this.command = undefined;
    this.className = undefined;
    this.params = undefined;
  }
}

export class ElementDisplayConfig implements App.Form.Element.DisplayConfig{

    fieldName: string;

    postCharacter: string;

    preChacarter: string;

    constructor(
    ) {
        this.fieldName = null;
        this.preChacarter = '';
        this.postCharacter = '';
    }
}

export class ElementInputValidator implements App.Form.Element.Input.Validator {

  public static REQUIRED_KEY = 'Validators.required';
  public static DATE_SMALLER_THAN = 'Validators.dateSmallerThan';

  constructor(
    public key: string,
    public params?: Object
  ) { }
}

export abstract class ElementInput extends ElementLabel {

  icon: Icon;

  constructor(
    public datamodelField: string = undefined,
    public defaultValue: any = undefined,
    public disabled: boolean = false,
    public readOnly: boolean = false,
    public boldText: boolean = false,
    public isGranted: boolean = true,
    public textOnly: boolean = false,
    public inputFormat: any = undefined,
    public validators: ElementInputValidator[] = [],
    public validationsError: string = ''
  ) {
    super();
    this.type = 'input';
    this.orientation = 'left';

    this.inputFormat = {
      prefix: '',
      suffix: ''
    };
  }
}

export abstract class ElementLayout extends Element {
  constructor() {
    super();
    this.type = 'layout';
    this.validators = [];
    this.validationsError = '';
  }
}

export abstract class ElementButton extends Element {
  constructor() {
    super();
    this.type = 'button';
  }
}

export class ElementPlaceholder extends ElementLabel {
  constructor() {
    super();
    this.type = 'placeholder';
    this.typeElement = 'placeholder';
    this.showLabel = false;
  }
}

export class ElementModuleElement extends ElementLabel {
  constructor(
    public modulElement: any = undefined,
    public datamodelField: string = undefined,
    public datamodel: Datamodel = undefined,
    public openingModuleId: number = null,
    public dialogWidth: number = undefined,
    public dialogHeight: number = undefined,
    public collapseDirection: string = ''
  ) {
    super();
    this.type = 'module-element';
  }
}

export class ElementAssociationData extends ElementLabel {

  staticFilterField: string;
  staticFilterValue: string;

  constructor(
    public datamodelField: string = undefined,
    public datamodel: Datamodel = undefined,
    public datamodelFieldDisplay: string = undefined,
    public datamodelFieldFilter: string = undefined,
    public datamodelFieldDisplay2: string = undefined,
    public datamodelFieldDisplay3: string = undefined,
    public fieldBinder: string = undefined,
    public recordBinder: string = undefined,
    public takeOnlyFirst: string = undefined
  ) {
    super();
    this.type = 'association-data';
    this.staticFilterField = null;
    this.staticFilterValue = null;
  }
}

export abstract class ElementInputAssociation extends ElementInput {}
