import { ActionsAction, ActionsEvent } from './action';
import { FieldActionOpenElementComponent } from '../../content-renderer/elements/generic-grid/field-actions/open-element-action/field-action-open-element.component';
import { FieldActionOpenModuleComponent } from '../../content-renderer/elements/generic-grid/field-actions/open-module-action/field-action-open-module.component';
import { Injectable } from '@angular/core';
import { FieldActionSetValueToAnotherFieldComponent } from '../../content-renderer/elements/generic-grid/field-actions/set-value-to-another-field-action/field-action-set-value-to-another-field.component';
import { FieldActionSetExtraParamFromAnotherFieldComponent } from '../../content-renderer/elements/generic-grid/field-actions/set-extra-param-from-another-field-action/field-action-set-extra-param-from-another-field.component';
import {FieldActionSetOverallWorkingTimeComponent} from "../../content-renderer/elements/generic-grid/field-actions/set-overall-working-time/field-action-set-overall-working-time.component";
import {FieldActionSetPlannedWorkingTimeComponent} from "../../content-renderer/elements/generic-grid/field-actions/set-planned-working-time/field-action-set-planned-working-time.component";
import {FieldActionDisableAnotherFieldComponent} from '../../content-renderer/elements/generic-grid/field-actions/disable-another-field-action/field-action-disable-another-field.component';
import {FieldActionDisableSelfBaseOnAnotherFieldComponent} from '../../content-renderer/elements/generic-grid/field-actions/disable-self-base-on-another-field/field-action-disable-self-base-on-another-field.component';
import {FieldActionDisableSelfBasedOnOpenQuantityComponent} from '../../content-renderer/elements/generic-grid/field-actions/disable-self-based-on-open-quantity/field-action-disable-self-based-on-open-quantity.component';
import {FieldActionGetCollectiveAgreementValidToComponent} from '../../content-renderer/elements/generic-grid/field-actions/CollectiveAgreement/field-action-get-collective-agreement-valid-to.component';
import {FieldActionOpenConditionModuleComponent} from '../../content-renderer/elements/generic-grid/field-actions/open-module-action/field-action-open-condition-module.component';
import {FieldActionExecuteStepComponent} from '../../content-renderer/elements/generic-grid/field-actions/execute-step/field-action-execute-step.component';
import {FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent} from '../../content-renderer/elements/generic-grid/field-actions/set-extra-param-from-one-multiselect-filter-to-another/field-action-set-extra-param-from-one-multiselect-filter-to-another.component';
import {FieldActionSetExtraParamFromOneMultiselectEditToAnotherComponent} from '../../content-renderer/elements/generic-grid/field-actions/set-extra-param-from-one-multiselect-edit-to-another/field-action-set-extra-param-from-one-multiselect-edit-to-another.component';
import {FieldActionCalculateSalaryComponent} from "../../content-renderer/elements/generic-grid/field-actions/workhour-calculate-salary/field-action-calculate-salary.component";
import {FieldActionSetValueToAnotherFieldFromEntityComponent} from "../../content-renderer/elements/generic-grid/field-actions/set-value-to-another-field-action/field-action-set-value-to-another-field-from-entity.component";

@Injectable()
export class ActionRegistryService {

    private actions = [];

    private events = [
        ActionsEvent.DoubleClick,
        ActionsEvent.IconClick,
        ActionsEvent.Click,
        ActionsEvent.EntityValueChange,
        ActionsEvent.CustomButtonClick,
        ActionsEvent.PhoneIconClick,
        ActionsEvent.FilterValueChange,
        ActionsEvent.BeforeFocus,
        ActionsEvent.Focus
    ];

    public constructor() {
        this.actions = [...this.actions,
        {
            'action': ActionsAction.OpenElement,
            'component': FieldActionOpenElementComponent
        }, {
            'action': ActionsAction.OpenModule,
            'component': FieldActionOpenModuleComponent
        }, {
            'action': ActionsAction.OpenConditionModule,
            'component': FieldActionOpenConditionModuleComponent
        }, {
            'action': ActionsAction.SetValueToAnotherField,
            'component': FieldActionSetValueToAnotherFieldComponent
        }, {
            'action': ActionsAction.SetValueToAnotherFieldFromEntity,
            'component': FieldActionSetValueToAnotherFieldFromEntityComponent
        }, {
            'action': ActionsAction.SendExtraParamFromAnotherField,
            'component': FieldActionSetExtraParamFromAnotherFieldComponent
        }, {
            'action': ActionsAction.SetOverallWorkingTime,
            'component': FieldActionSetOverallWorkingTimeComponent
        }, {
            'action': ActionsAction.SetPlannedWorkingTime,
            'component': FieldActionSetPlannedWorkingTimeComponent
        }, {
            'action': ActionsAction.FieldActionCalculateSalaryComponent,
            'component': FieldActionCalculateSalaryComponent
        }, {
            'action': ActionsAction.DisableAnotherField,
            'component': FieldActionDisableAnotherFieldComponent
        }, {
            'action': ActionsAction.DisableSelfBaseOnAnotherField,
            'component': FieldActionDisableSelfBaseOnAnotherFieldComponent
        }, {
            'action': ActionsAction.GetCollectiveAgreementValidTo,
            'component': FieldActionGetCollectiveAgreementValidToComponent
        }, {
            'action': ActionsAction.DisableSelfBasedOnOpenQuantity,
            'component': FieldActionDisableSelfBasedOnOpenQuantityComponent
        }, {
          'action': ActionsAction.ExecuteStep,
          'component': FieldActionExecuteStepComponent
        }, {
          'action': ActionsAction.SetExtraParamFromOneMultiSelectFilterToAnother,
          'component': FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent
        }, {
            'action': ActionsAction.SetExtraParamFromOneMultiSelectEditToAnother,
            'component': FieldActionSetExtraParamFromOneMultiselectEditToAnotherComponent
        }];
    }

    public getActions() {
        return this.actions;
    }

    public getEvents() {
        return this.events;
    }
}
