
    <div [formGroup]="formGroup">
      <p-dropdown
        appDropdownLoadingIndicator
        appSeleniumDirective
        [element]="element"
        [formControlName]="formControlName"
        [options]="pulldownOptions"
        [autoWidth]="false"
        [placeholder]="getPlaceholder()"
        [disabled]="checkIfDisabled()"
        [readonly]="isReadOnly()"
        (onChange)="onActionSelect($event)"
        [ngClass]="{'readonly-style': isReadOnly()}"
        [styleClass]="!element.isValid ? 'is-not-valid' : ''"
        (onClick)="onDropdownClick()"
        appendTo="body"
        #inputElement
      >
        <ng-template let-item pTemplate="item">
          <span
            [id]="'FormElementInput-' + element.label + '-Value-' + item.label"
          >
            {{ item.label }}
          </span>
        </ng-template>
      </p-dropdown>
    </div>
  