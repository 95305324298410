
    <div class="button-wrapper">
      <button
        pButton
        appSeleniumDirective
        [element]="element"
        [disabled]="getIsDisabled()"
        [style.width]="getButtonWidth()"
        [iconUrl]="getButtonImageSource(element)"
        [label]="getButtonLabel(element.label)"
        (click)="onOpenModule()"
      ></button>
    </div>
  