import {LocationService} from '../../../services/location.service';
import {Module} from '../../../services/module/module';

export class ModuleRouter {

  public static buildRoute(module: Module, parentModuleId: number, entity: any, partModuleId: number = null): string {
    // TODO :: fix product id
    let path = `phoenix/18/module/${module.id}`;

    if (parentModuleId) {
      path += `/parent-module/${parentModuleId}`;
    }

    if (entity && entity.fqn) {
      path += `/fqn/${entity.fqn.split('\\').join('-')}`;
    }

    if (entity && entity.id) {
      path += `/id/${entity.id}`;
    }

    if (entity && entity.fqn) {
      path += `/master-entity/${ModuleRouter.getMasterEntityRouteValue(entity)}`;
    }

    if (partModuleId) {
      path += `/part-module/${partModuleId}`;
    }

    return path;
  }

  private static getMasterEntityRouteValue(entity: {fqn: string}): string {
    const entityName = entity.fqn.split('\\').pop();

    return entityName.charAt(0).toLowerCase() + entityName.slice(1);
  }

  public constructor(
    private location: LocationService
  ) {

  }

  public replaceRouteEntity(entity: {id: number, fqn: string}): string {
    let route = window.location.pathname.substr(1);

    if (this.location.hasParam('id')) {
      route = this.location.setParam('id', entity.id);
    } else {
      const searchValue = 'master-entity',
        replaceValue = `id/${entity.id}/master-entity`;

      route = route.replace(searchValue, replaceValue);

      this.location.getLocation().replaceState(route);
    }

    return route;
  }

  public replaceRoutePart(partModule: {id: number}): string {
    let route = window.location.pathname.substr(1);

    if (this.location.windowLocationHasParam('part-module')) {
      route = this.location.setWindowLocationParam('part-module', partModule.id);
    } else {
      route += '/part-module/' + partModule.id;

      this.location.getLocation().replaceState(route);
    }

    return route;
  }
}
