import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ElementComponent } from './element.component';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-layout-fieldset',
  template: `
    <div fxLayout="row" (click)="onClickElement($event)">
      <p-fieldset [legend]="element.label" [styleClass]="!element.showLabel ? 'hide-fieldset-label' : ''" #elementItem>
        <ng-container *ngFor="let elementChild of element.children">
          <app-form-element
            [(selectedElement)]="selectedElement"
            [(actionParams)]="actionParams"
            [(showDialogAddAddress)]="showDialogAddAddress"
            [form]="form"
            [formGroup]="formGroup"
            [element]="elementChild"
            [moduleElement]="moduleElement"
            [entity]="entity"
            [editMode]="editMode"
          ></app-form-element>
        </ng-container>
      </p-fieldset>
    </div>
  `,
  styles: [`
    :host > div {
      padding: .5em 0;
    }

    :host > div > p-fieldset {
      width: 100%;
    }

    :host > div > ::ng-deep p-fieldset fieldset {
      border-radius: 15px;
    }
  `]
})

export class ElementLayoutFieldsetComponent extends ElementComponent implements OnInit, AfterViewInit {
  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  onComponentDestroy() {

  }

  ngAfterViewInit() {
  }
}

