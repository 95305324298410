<ng-container *ngIf="!element.datamodel">
  <div class="error-container">
    <div class="error-text">
      <div class="error-message">
        <b><span class="text-red">{{element.label}} has no datamodel assigned</span></b>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="element.datamodel"
  #gridContainer
  (click)="highlight()"
  class="tree-grid-container"
>
  <app-generic-filter *ngIf="isFilterContainerVisible || element.isFilterContainerAlwaysVisible" [component]="this"></app-generic-filter>

  <p-treeTable
    [value]="entities"
    [columns]="columns"
    styleClass="generic-tree-grid"
    selectionMode="single"
    [(selection)]="selectedNode"
    [loading]="isDataLoading"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [resizableColumns]="true"
    [customSort]="true"
    (sortFunction)="onSort($event)"
    #dt
  >
    <ng-template pTemplate="caption" *ngIf="element.topToolbarItems && element.topToolbarItems.length > 0" class="header">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [entity]="selectedNode ? selectedNode.data : null" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
    </ng-template>

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let aColumn of columns" [style.width.px]="aColumn.width">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let aColumn of columns"
          [style.width]="aColumn.Style.width"
          [style.textOverflow]="'ellipsis'"
          [style.whiteSpace]="'nowrap'"
          [style.overflow]="'hidden'"
          ttResizableColumn
          [ttSortableColumn]="aColumn.id"
        >
          <span>
            {{aColumn.header}}
            <p-treeTableSortIcon [field]="aColumn.id"></p-treeTableSortIcon>
          </span>
        </th>
      </tr>
      <tr>
        <th *ngFor="let aColumn of columns"
            [style.width]="aColumn.Style.width"
            [style.textOverflow]="'ellipsis'"
            [style.whiteSpace]="'nowrap'"
            [style.overflow]="'hidden'"
            ttResizableColumn
        >
          <div [ngSwitch]="aColumn.filterType">
            <input
              *ngSwitchCase="'contains'"
              [column]="aColumn"
              [componentState]="componentState"
              (search)="onFilter($event, aColumn)"
              (keyup)="onFilter($event, aColumn)"
              gridFilter
              [ngModel]=""
              type="search"
              pInputText
              styleClass="ui-column-filter"
              placeholder="Contains"
              class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
            />
            <input
              *ngSwitchCase="'equals'"
              [column]="aColumn"
              [componentState]="componentState"
              (search)="onFilter($event, aColumn)"
              (keyup)="onFilter($event, aColumn)"
              gridFilter
              [ngModel]=""
              type="search"
              pInputText
              styleClass="ui-column-filter"
              placeholder="Equals"
              class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
            />
            <input
              *ngSwitchCase="'startsWith'"
              [column]="aColumn"
              [componentState]="componentState"
              (search)="onFilter($event, aColumn)"
              (keyup)="onFilter($event, aColumn)"
              gridFilter
              [ngModel]=""
              type="search"
              pInputText
              styleClass="ui-column-filter"
              placeholder="Starts with"
              class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
            />
            <input
              *ngSwitchCase="'endsWith'"
              [column]="aColumn"
              [componentState]="componentState"
              (search)="onFilter($event, aColumn)"
              (keyup)="onFilter($event, aColumn)"
              gridFilter
              [ngModel]=""
              type="search"
              pInputText
              styleClass="ui-column-filter"
              placeholder="Ends with"
              class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
            />
            <input
              *ngSwitchCase="'in'"
              [column]="aColumn"
              [componentState]="componentState"
              (search)="onFilter($event, aColumn)"
              (keyup)="onFilter($event, aColumn)"
              gridFilter
              [ngModel]=""
              type="search"
              pInputText
              styleClass="ui-column-filter"
              placeholder="In"
              class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
            />
            <input
              *ngSwitchCase="'date'"
              [column]="aColumn"
              [componentState]="componentState"
              (search)="onFilter($event, aColumn)"
              (keyup)="onFilter($event, aColumn)"
              gridFilter
              [ngModel]=""
              type="search"
              pInputText
              styleClass="ui-column-filter"
              placeholder="Date"
              class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
            />
            <p-slider
              *ngSwitchCase="'range_date'"
              [componentState]="componentState"
              [column]="aColumn"
              [range]="true"
              [min]="dateRangeMinValue"
              [max]="dateRangeMaxValue"
              [ngModel]="dateRangeValues"
              (onSlideEnd)="onFilter($event, aColumn)"
              gridFilter
              styleClass="ui-column-filter"
            ></p-slider>
            <span *ngSwitchCase="'range_date'">{{dateRangeValues[0]}} - {{dateRangeValues[1]}}</span>
            <p-checkbox
              *ngSwitchCase="'checkbox'"
              (onChange)="onFilter($event, aColumn)"
              [ngModel]=""
              binary="true"
            ></p-checkbox>
            <p-triStateCheckbox
              *ngSwitchCase="'tricheckbox'"
              (onChange)="onFilter($event, aColumn)"
              [ngModel]=""
            ></p-triStateCheckbox>
            <p-dropdown
              *ngSwitchCase="'dropdown'"
              appDropdownLoadingIndicator
              gridFilter
              [column]="aColumn"
              [componentState]="componentState"
              [options]="associatedFilterOptions[aColumn.entityName]"
              [style]="{'width':'100%'}"
              [ngModel]=""
              (onChange)="onFilter($event, aColumn)"
              (onFocus)="onFilterCellFocus($event, aColumn)"
              appendTo="body"
              styleClass="ui-column-filter"
            ></p-dropdown>
            <p-multiSelect
              *ngSwitchCase="'multidropdown'"
              [column]="aColumn"
              [componentState]="componentState"
              [options]="associatedFilterOptions[aColumn.entityName]"
              (onChange)="onFilter($event, aColumn)"
              (onFocus)="onFilterCellFocus($event, aColumn)"
              appendTo="body"
              gridFilter
              selectedItemsLabel="Alle ({0})"
              maxSelectedLabels="2"
              defaultLabel="All"
              [style]="{'width':'100%'}"
              styleClass="ui-column-filter"
            ></p-multiSelect>
            <p-autoComplete
              *ngSwitchCase="'autocomplete'"
              appAutocomplete
              [column]="aColumn"
              [componentState]="componentState"
              [dropdown]="true"
              [ngModel]=""
              [suggestions]="associatedFilterOptions[aColumn.entityName]"
              [multiple]="true"
              [style]="{'width':'100%', 'white-space': 'nowrap'}"
              (onSelect)="onFilter($event, aColumn)"
              (onUnselect)="onFilter($event, aColumn)"
              (completeMethod)="onFilterAutocompleteSearch($event, aColumn)"
              (onDropdownClick)="onFilterAutocompleteSearch($event, aColumn)"
              appendTo="body"
              field="label"
              gridFilter
              styleClass="ui-column-filter ui-column-filter-autocomplete"
            ></p-autoComplete>
            <ng-template ngSwitchDefault="contains">
              <input
                *ngIf="aColumn.fieldType !== 'moduleElementColumn'"
                [column]="aColumn"
                [componentState]="componentState"
                (search)="onFilter($event, aColumn)"
                (keyup)="onFilter($event, aColumn)"
                gridFilter
                [ngModel]=""
                type="search"
                pInputText
                styleClass="ui-column-filter"
                placeholder="Contains"
                class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-input-filter"
              />
            </ng-template>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowNode let-entity="rowData">
      <tr
        [ttSelectableRow]="rowNode"
        [style.backgroundColor]="entity.backgroundColor"
        [ngClass]="getRowStyleClass(entity)"
        (dblclick)="onRowDblclick($event)"
        (click)="onNodeSelect(rowNode.node)"
      >
        <td *ngFor="let aColumn of columns; let columnIndex = index"
          class="ui-resizable-column {{ isEditCellDisabled(entity, aColumn) ? uiCellDisabledFlag : '' }}"
          appTreeEditableColumn
          [style.textAlign]="aColumn.Style.textAlign"
          [style.width]="aColumn.Style.width"
          [style.textOverflow]="aColumn.Style.textOverflow"
          [style.whiteSpace]="aColumn.Style.whiteSpace"
          [style.overflow]="'hidden'"
          [style.backgroundColor]="getCellBackgroundColor(entity, aColumn)"
        >
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="columnIndex == 0"></p-treeTableToggler>

          <!-- NON-EDIT MODE -->
          <ng-template [ngIf]="!element.isInlineEditable">
            <span
              pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) }}" tooltipPosition="top"
              [attr.columnIndex]="columnIndex"
              *ngIf="
              !aColumn.isAssociatedField &&
              aColumn.fieldType !== 'dateTime' &&
              aColumn.fieldType !== 'date' &&
              aColumn.fieldType !== 'dateMonthAndYear' &&
              aColumn.fieldType !== 'checkbox' &&
              aColumn.fieldType !== 'tricheckbox' &&
              aColumn.fieldType !== 'leasedEmployeeAbsenceDate' &&
              aColumn.fieldType !== 'decimal' &&
              aColumn.fieldType !== 'link' &&
              aColumn.fieldType !== 'image' &&
              aColumn.fieldType !== 'phoneNumber'"
            >
              {{getColumnEntityValue(aColumn, rowNode.node)}}
            </span>

            <span
              [attr.columnIndex]="columnIndex"
              *ngIf="aColumn.isAssociatedField"
              pTooltip="{{ getEntityAssociatedOneEntityValue(entity, aColumn) }}" tooltipPosition="top"
            >
                {{ getEntityAssociatedOneEntityValue(entity, aColumn) }}
            </span>

            <span
              [attr.columnIndex]="columnIndex"
              *ngIf="aColumn.fieldType === 'associationMany'"
              pTooltip="{{ getEntityAssociatedManyEntityValue(entity, aColumn) }}" tooltipPosition="top"
            >
                {{ getEntityAssociatedManyEntityValue(entity, aColumn) }}
            </span>

            <span
              [attr.columnIndex]="columnIndex"
              *ngIf="aColumn.fieldType === 'date'"
              pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) | date:'dd.MM.yyyy' }}" tooltipPosition="top"
            >
              {{ getColumnEntityValue(aColumn, rowNode.node) | date:'dd.MM.yyyy' }}
            </span>

            <span
              [attr.columnIndex]="columnIndex"
              *ngIf="aColumn.fieldType === 'dateTime'"
              pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) | date:'dd.MM.yyyy HH:mm' }}" tooltipPosition="top"
            >
              {{ getColumnEntityValue(aColumn, rowNode.node) | date:'dd.MM.yyyy HH:mm' }}
            </span>

            <span
              [attr.columnIndex]="columnIndex"
              *ngIf="aColumn.fieldType === 'decimal'"
              pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) | decimal: ',':'.':aColumn?.field?.inputFormat?.number?.decimalLimit }}" tooltipPosition="top"
            >
              {{ getColumnEntityValue(aColumn, rowNode.node) | decimal: ',':'.':aColumn?.field?.inputFormat?.number?.decimalLimit }}
            </span>

            <div *ngIf="aColumn.fieldType === 'moduleElementColumn'">
              <app-generic-column [entity]="entity" [node]="rowNode.node" [component]="this" [column]="aColumn.moduleElementColumn"></app-generic-column>
            </div>

            <span *ngIf="aColumn.fieldType === 'lockState'">
              <i class="pi pi-lock" style="font-size: 1.5em" *ngIf="getColumnEntityValue(aColumn, rowNode.node) === true"></i>
              <i class="pi pi-lock-open" style="font-size: 1.5em" *ngIf="getColumnEntityValue(aColumn, rowNode.node) === false"></i>
            </span>

            <p-checkbox
              *ngIf="aColumn.fieldType === 'checkbox'"
              [disabled]="isEditCellDisabled(entity, aColumn)"
              (onChange)="onEditCell($event, entity, aColumn)"
              [ngModel]="getColumnEntityValue(aColumn, rowNode.node)"
              binary="true"
            ></p-checkbox>

            <p-triStateCheckbox
              *ngIf="aColumn.fieldType === 'tricheckbox'"
              [disabled]="isEditCellDisabled(entity, aColumn)"
              (onChange)="onEditCell($event, entity, aColumn)"
              [ngModel]="getColumnEntityValue(aColumn, rowNode.node)"
            ></p-triStateCheckbox>

            <div *ngIf="aColumn.fieldType === 'color'">
              <div class="generic-grid-color-picker-color">
                <p-colorPicker [disabled]="isEditCellDisabled(entity, aColumn)"
                               (onChange)="onEditCell($event, entity, aColumn)"
                               [ngModel]="getColumnEntityValue(aColumn, rowNode.node)"
                               appendTo="body"
                ></p-colorPicker>
              </div>
              <div class="generic-grid-color-picker-input">
                <input
                  type="text"
                  [disabled]="isEditCellDisabled(entity, aColumn)"
                  (keyup)="onEditCell($event, entity, aColumn)"
                  pInputText
                  [ngModel]="getColumnEntityValue(aColumn, rowNode.node)"
                  (focus)="onEditCellFocus($event, entity, aColumn)"
                />
              </div>
            </div>

            <a
              [attr.columnIndex]="columnIndex"
              *ngIf="aColumn.fieldType === 'link'"
              class="link-entity-cell"
              (click)="onEditCellFocus($event, entity, aColumn)"
              pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) }}" tooltipPosition="top"
            >
              {{getColumnEntityValue(aColumn, rowNode.node)}}
            </a>

            <a
              *ngIf="aColumn.fieldType === 'phoneNumber'"
              (click)="onEditCellFocus($event, entity, aColumn)"
              pTooltip="{{ entity[aColumn.property] }}" tooltipPosition="top"
            >
              <span>{{ entity[aColumn.property] }}</span>
              <i
                *ngIf="entity[aColumn.property]"
                (click)="onEditCellPhoneIconList($event, entity, aColumn)"
                class="fa fa-phone phone-number-entity-cell"
              ></i>
            </a>
          </ng-template>

          <p-treeTableCellEditor *ngIf="element.isInlineEditable">
            <!-- EDIT MODE -->
            <ng-template pTemplate="input">
              <input
                *ngIf="isEditCellTextEditor(entity, aColumn)"
                type="text"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                (keyup)="onEditCell($event, entity, aColumn)"
                pInputText
                [ngModel]="entity[aColumn.property]"
                (focus)="onEditCellFocus($event, entity, aColumn)"
                (blur)="onEditCellBlur($event, entity, aColumn)"
              />

              <div *ngIf="aColumn.fieldType === 'moduleElementColumn'">
                <app-generic-column [entity]="entity" [node]="rowNode.node" [component]="this" [column]="aColumn.moduleElementColumn"></app-generic-column>
              </div>

              <span *ngIf="aColumn.fieldType === 'lockState'">
                <i class="pi pi-lock" style="font-size: 1.5em" *ngIf="getColumnEntityValue(aColumn, rowNode.node) === true"></i>
                <i class="pi pi-lock-open" style="font-size: 1.5em" *ngIf="getColumnEntityValue(aColumn, rowNode.node) === false"></i>
              </span>

              <app-decimal-column
                *ngIf="aColumn.fieldType === 'decimal'"
                [entity]="entity"
                [component]="this"
                [column]="aColumn"
                [editMode]="true"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                (onKeyUp)="onEditCell($event, entity, aColumn)"
                (onBlur)="onEditCellBlur($event, entity, aColumn)"
                (onFocus)="onEditCellFocus($event, entity, aColumn)"
              ></app-decimal-column>

              <p-autoComplete
                *ngIf="aColumn.filterType === 'autocomplete'"
                appAutocomplete
                appendTo="body"
                field="label"
                [dropdown]="true"
                [ngModel]="getEditCellValue(entity, aColumn)"
                [placeholder]="getEntityAssociatedOneEntityValue(entity, aColumn)"
                [suggestions]="associatedOptions[aColumn.entityName]"
                (completeMethod)="onEditCellSearch($event, entity, aColumn)"
                (onDropdownClick)="onEditCellSearch($event, entity, aColumn)"
                styleClass="ui-column-editor-autocomplete"
                (onSelect)="onEditCell($event, entity, aColumn)"
                [disabled]="isEditCellDisabled(entity, aColumn)"
              >
                <ng-template let-item pTemplate="item">
                  <span class="ui-state-highlight" *ngIf="entity[aColumn.entityName] == item.id">
                      {{item.label}}
                  </span>

                  <span *ngIf="entity[aColumn.entityName] != item.id">
                      {{item.label}}
                  </span>
                </ng-template>
              </p-autoComplete>

              <p-dropdown
                *ngIf="
                (aColumn.fieldType === 'association' || aColumn.fieldType === 'dropdown') &&
                aColumn.filterType !== 'autocomplete'"
                appendTo="body"
                appDropdownLoadingIndicator
                [disabled]="isEditCellDisabled(entity, aColumn)"
                (onChange)="onEditCell($event, entity, aColumn)"
                [ngModel]="getEditCellValue(entity, aColumn)"
                (onFocus)="onEditCellFocus($event, entity, aColumn)"
                placeholder=" --- Bitte wählen --- "
                [options]="associatedOptions[aColumn.entityName]"
                [autoWidth]="false" [style]="{'width':'100%'}">
              </p-dropdown>

              <p-multiSelect
                *ngIf="aColumn.fieldType === 'associationMany'"
                appendTo="body"
                (onChange)="onEditCell($event, entity, aColumn)"
                [ngModel]="associatedOptionsValues[aColumn.entityName] && associatedOptionsValues[aColumn.entityName][entity.uniqueId] ?
                  associatedOptionsValues[aColumn.entityName][entity.uniqueId] : []"
                (onFocus)="onEditCellFocus($event, entity, aColumn)"
                [options]="associatedOptions[aColumn.entityName]"
                [style]="{'width':'100%'}"
                [disabled]="isEditCellDisabled(entity, aColumn)"
              ></p-multiSelect>

              <app-generic-calendar
                *ngIf="aColumn.fieldType === 'date'"
                (onInputChangeValid)="onEditCell($event, entity, aColumn)"
                (onSelect)="onEditCell($event, entity, aColumn)"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                [value]="entity[aColumn.property]"
                [yearRange]="'1950:2050'"
                [mask]="'99.99.9999'"
                [dateFormat]="'dd.mm.yy'"
                [showOnFocus]="true"
              ></app-generic-calendar>

              <app-generic-calendar
                *ngIf="aColumn.fieldType === 'dateMonthAndYear'"
                (onInputChangeValid)="onEditCell($event, entity, aColumn)"
                (onSelect)="onEditCell($event, entity, aColumn)"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                [value]="entity[aColumn.property]"
                [yearRange]="'1950:2050'"
                [mask]="'99.9999'"
                [dateFormat]="'mm.yy'"
                [showOnFocus]="true"
                [view]="'month'"
              ></app-generic-calendar>

              <app-generic-calendar
                *ngIf="aColumn.fieldType === 'dateTime'"
                (onInputChangeValid)="onEditCell($event, entity, aColumn)"
                (onSelect)="onEditCell($event, entity, aColumn)"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                [value]="entity[aColumn.property]"
                [yearRange]="'1950:2050'"
                [mask]="'99.99.9999 99:99'"
                [dateFormat]="'dd.mm.yy'"
                [showOnFocus]="true"
              ></app-generic-calendar>

              <app-generic-calendar
                *ngIf="aColumn.fieldType === 'time'"
                [value]="entity[aColumn.property]"
                (onInputChangeValid)="onEditCell($event, entity, aColumn)"
                (onSelect)="onEditCell($event, entity, aColumn)"
                [timeOnly]="true"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                dataType="text"
              ></app-generic-calendar>

              <a
                *ngIf="aColumn.fieldType === 'link'"
                class="link-entity-cell"
                (click)="onEditCellFocus($event, entity, aColumn)"
              >{{ entity[aColumn.property] }}</a>

              <a
                *ngIf="aColumn.fieldType === 'phoneNumber'"
                (click)="onEditCellFocus($event, entity, aColumn)"
              >
                <span>{{ entity[aColumn.property] }}</span>
                <i
                  *ngIf="entity[aColumn.property]"
                  (click)="onEditCellPhoneIconList($event, entity, aColumn)"
                  class="fa fa-phone phone-number-entity-cell"
                ></i>
              </a>

              <p-checkbox
                *ngIf="aColumn.fieldType === 'checkbox'"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                (onChange)="onEditCell($event, entity, aColumn)"
                [(ngModel)]="entity[aColumn.property]"
                binary="true"
                class="ui-column-editor-checkbox"
              ></p-checkbox>

              <p-triStateCheckbox
                *ngIf="aColumn.fieldType === 'tricheckbox'"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                (onChange)="onEditCell($event, entity, aColumn)"
                [(ngModel)]="entity[aColumn.property]"
                class="ui-column-editor-checkbox"
              ></p-triStateCheckbox>
            </ng-template>

            <!-- NON-EDIT MODE -->
            <ng-template pTemplate="output">
              <span
                pTooltip="{{getColumnEntityValue(aColumn, rowNode.node)}}" tooltipPosition="top"
                [attr.columnIndex]="columnIndex"
                *ngIf="
                    !aColumn.isAssociatedField &&
                    aColumn.fieldType !== 'date' &&
                    aColumn.fieldType !== 'dateMonthAndYear' &&
                    aColumn.fieldType !== 'checkbox' &&
                    aColumn.fieldType !== 'tricheckbox' &&
                    aColumn.fieldType !== 'decimal' &&
                    aColumn.fieldType !== 'link' &&
                    aColumn.fieldType !== 'html'"
              >
                  {{getColumnEntityValue(aColumn, rowNode.node)}}
              </span>

              <span
                *ngIf="aColumn.fieldType === 'html'"
                [attr.columnIndex]="columnIndex"
                [innerHTML]="entity[aColumn.property]"
              >
              </span>

              <span
                pTooltip="{{ getEntityAssociatedOneEntityValue(entity, aColumn) }}" tooltipPosition="top"
                [attr.columnIndex]="columnIndex"
                *ngIf="aColumn.isAssociatedField"
              >
                  {{ getEntityAssociatedOneEntityValue(entity, aColumn) }}
              </span>

              <span
                pTooltip="{{ getEntityAssociatedManyEntityValue(entity, aColumn) }}" tooltipPosition="top"
                [attr.columnIndex]="columnIndex"
                *ngIf="aColumn.fieldType === 'associationMany'"
              >
                  {{ getEntityAssociatedManyEntityValue(entity, aColumn) }}
              </span>

              <span
                pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) | date:'dd.MM.yyyy' }}" tooltipPosition="top"
                [attr.columnIndex]="columnIndex"
                *ngIf="aColumn.fieldType === 'date'"
              >
                {{ getColumnEntityValue(aColumn, rowNode.node) | date:'dd.MM.yyyy' }}
              </span>

              <span
                pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) | date:'MM.yyyy' }}" tooltipPosition="top"
                [attr.columnIndex]="columnIndex"
                *ngIf="aColumn.fieldType === 'dateMonthAndYear'"
              >
                {{ getColumnEntityValue(aColumn, rowNode.node) | date:'MM.yyyy' }}
              </span>

              <span
                pTooltip="{{ getColumnEntityValue(aColumn, rowNode.node) | decimal: ',':'.':aColumn?.field?.inputFormat?.number?.decimalLimit }}" tooltipPosition="top"
                [attr.columnIndex]="columnIndex"
                *ngIf="aColumn.fieldType === 'decimal'"
              >
                {{ getColumnEntityValue(aColumn, rowNode.node) | decimal: ',':'.':aColumn?.field?.inputFormat?.number?.decimalLimit }}
              </span>

              <div *ngIf="aColumn.fieldType === 'moduleElementColumn'">
                <app-generic-column [entity]="entity" [node]="rowNode.node" [component]="this" [column]="aColumn.moduleElementColumn"></app-generic-column>
              </div>

              <p-checkbox
                *ngIf="aColumn.fieldType === 'checkbox'"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                (onChange)="onEditCell($event, entity, aColumn)"
                [ngModel]="getColumnEntityValue(aColumn, rowNode.node)"
                binary="true"
              ></p-checkbox>

              <p-triStateCheckbox
                *ngIf="aColumn.fieldType === 'tricheckbox'"
                [disabled]="isEditCellDisabled(entity, aColumn)"
                (onChange)="onEditCell($event, entity, aColumn)"
                [ngModel]="getColumnEntityValue(aColumn, rowNode.node)"
              ></p-triStateCheckbox>

              <a
                pTooltip="{{getColumnEntityValue(aColumn, rowNode.node)}}" tooltipPosition="top"
                [attr.columnIndex]="columnIndex"
                *ngIf="aColumn.fieldType === 'link'"
                class="link-entity-cell"
                (click)="onEditCellFocus($event, entity, aColumn)"
              >
                {{getColumnEntityValue(aColumn, rowNode.node)}}
              </a>
            </ng-template>
          </p-treeTableCellEditor>

        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary" class="footer" *ngIf="element.statusBarItems && element.statusBarItems.length > 0">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [entity]="selectedNode ? selectedNode.data : null" [items]="element.mergedStatusBarItems"></app-generic-toolbar>
    </ng-template>

  </p-treeTable>

  <p-paginator
    *ngIf="element.isPaginable"
    #paginator
    [rows]="element.pageSize"
    [totalRecords]="totalCount"
    dropdownAppendTo="body"
    [rowsPerPageOptions]="[5,10,20,50,100]"
    (onPageChange)="onPageChange($event)"
  ></p-paginator>

  <!--
  <app-generic-detailed-overview [grid]="this" [element]="element" (close)="onGoBackToTable()" *ngIf="moduleElement.onDoubleClick && moduleElement.onDoubleClick === 'details' && showDetailedOverview"></app-generic-detailed-overview>
  -->

</div>
