import {Observable, of as observableOf} from 'rxjs';
import {ChangeDetectorRef, Component, ElementRef, Input, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {LayoutService} from '../../../../services/layout-service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {Entity} from '../../../../helpers/entity';
import {TranslateService} from '@ngx-translate/core';
import {ElementSubTypes} from '../../../../services/element/element-type';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

const COLORS = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff'
];

interface ChartData {
  labels: string[];
  datasets: ChartDataSetLine[]|ChartDataSetPie[];
}

interface ChartDataSetLine {
  label: string,
  data: number[],
  backgroundColor: string,
  borderColor: string
}

interface ChartDataSetPie {
  data: number[],
  backgroundColor: string[];
}

interface ChartResponseData {
  value: number;
  year: string;
  name: string;
}

@Component({
  selector: 'app-custom-chart',
  styleUrls: ['./chart.component.scss'],
  templateUrl: './chart.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})
export class ChartComponent extends GenericElementAbstract {
  public isLoadingData = false;
  public errorMessages = [];
  public toolbarContextName = 'chartComponent';
  public chartData: ChartData = null;

  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() entity: any = null;

  public constructor(
    public elementRef: ElementRef,
    public executorActionsService: ExecutorActionsService,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected jobContainerService: JobContainerService,
    protected stepFactory: ExecutionStepFactoryService,
    protected layoutService: LayoutService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected translate: TranslateService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    const dataSource = Entity.getValue(this.element, 'dataSource') ||
      Entity.getValueInEmbedded(this.element, 'dataSource')

    if (this.validateElementConfiguration()) {
      this.isLoadingData = true;
      this.genericCrudService.getEntities(`superadmin/datasources/${dataSource.id}/data`)
        .subscribe((response: {data: ChartResponseData[]}) => {
          if (this.validateData(response.data)) {
            this.chartData = this.buildChartData(response.data);
          }

          this.isLoadingData = false;
          ChangeDetectorRefHelper.detectChanges(this);
      })
    }

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.elementContext = this.createContext();

    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .subscribe();
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public getToolbarItemsExtraParams() {
    return {
      'chartComponent': this
    };
  }

  private createContext(): ElementContext {
    return new ElementContext(
      this.moduleElement.id,
      ElementType.Chart,
      this,
      this.moduleElement,
      true,
      false,
      false,
      false,
      false
    );
  }

  private buildChartData(data: any[]): ChartData {
    const chartData = {
      labels: [],
      datasets: []
    };

    chartData.labels = this.buildChartLabels(data);
    chartData.datasets = this.buildChartDataSets(data);

    return chartData;
  }

  private buildChartLabels(data: ChartResponseData[]): string[] {
    const labels = [];

    for (const d of data) {
      if (!labels.includes(d.name)) {
        labels.push(d.name)
      }
    }

    return labels;
  }

  private buildChartDataSets(data: ChartResponseData[]): ChartDataSetLine[]|ChartDataSetPie[] {
    if (this.isTypeBarOrLine()) {
      return this.buildChartDataSetsLine(data);
    }

    return this.buildChartDataSetsPie(data);
  }

  private buildChartDataSetsLine(data: ChartResponseData[]): ChartDataSetLine[] {
    const dataSets = [];

    let i = 0;
    for (const d of data) {
      const existing: ChartDataSetLine|null = dataSets.find((aDataSet: ChartDataSetLine) => {
        return d.year === aDataSet.label;
      });

      if (existing) {
        existing.data.push(d.value);
      } else {
        dataSets.push({
          label: d.year,
          backgroundColor: COLORS[i],
          data: [d.value]
        })
      }
      i++;
    }

    return dataSets;
  }

  private buildChartDataSetsPie(data: any[]): ChartDataSetPie[] {
    const aData = [],
      backgroundColor = [];

    let i = 0;
    for (const d of data) {
      aData.push(d.value);
      backgroundColor.push(
        COLORS[i]
      );
      i++;
    }

    return [{
      data: aData,
      backgroundColor
    }];
  }

  private validateElementConfiguration(): boolean {
    const dataSource = Entity.getValue(this.element, 'dataSource') ||
      Entity.getValueInEmbedded(this.element, 'dataSource');

    if (!dataSource) {
      this.errorMessages = [
        this.translate.instant('CHART.NO_DATA_SOURCE_DEFINED')
      ];
    }

    if (!this.element.elementSubType) {
      this.errorMessages = [
        this.translate.instant('CHART.NO_ELEMENT_SUB_TYPE_DEFINED')
      ];
    }

    return dataSource && !!this.element.elementSubType;
  }

  private validateData(data: ChartResponseData[]): boolean {
    this.errorMessages = [];

    if (data.length > 0) {
      const first = data[0];

      if (!first.year && this.isTypeBarOrLine()) {
        this.errorMessages.push(
          this.translate.instant('CHART.YEAR_MISSING')
        );
      }

      if (!first.name) {
        this.errorMessages.push(
          this.translate.instant('CHART.NAME_MISSING')
        );
      }

      if (!first.value) {
        this.errorMessages.push(
          this.translate.instant('CHART.VALUE_MISSING')
        );
      }
    }

    return this.errorMessages.length === 0;
  }

  private isTypeBarOrLine() {
    return [ElementSubTypes.LineChart, ElementSubTypes.BarChart].includes(this.element.elementSubType as ElementSubTypes);
  }
}
