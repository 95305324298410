import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AbstractUploadButtonComponent} from '../abstract-upload-button.component';
import {FileUpload} from 'primeng/fileupload';
import {File, File as FileModel} from '../../../models/file';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

interface MenuItemExtended extends MenuItem {
  file: FileModel;
}

@Component({
  selector: 'app-multi-upload-button',
  templateUrl: './multi-upload-button.component.html',
  styleUrls: ['./multi-upload-button.component.scss']
})
export class MultiUploadButtonComponent extends AbstractUploadButtonComponent {

  @ViewChild(FileUpload, {static: false}) public fileUpload: FileUpload = null;
  public selectedFiles: any[] = [];
  public items: MenuItemExtended[] = [];
  public isUploadVisible = false;

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected message: MessageGrowlService,
    protected translate: TranslateService,
    protected cdr: ChangeDetectorRef
  ) {
    super(genericCrudService, message, translate, cdr);
  }

  public onOpenUpload(event): void {
    this.isUploadVisible = true;
  }

  public onDropdownClick(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public onItemRemoved(menuItem: { file: File }): void {
    const index = this.items.findIndex((aItem: MenuItemExtended) => {
      return aItem.file.id === menuItem.file.id ||
        aItem.file.name === menuItem.file.name;
    })

    if (index !== -1) {
      this.items.splice(index, 1);
    }

    ChangeDetectorRefHelper.detectChanges(this);

    event.preventDefault();
    event.stopPropagation();
  }

  public onUpload(event: { files: File[] }): void {
    this.selectedFiles = event.files;

    this.upload().subscribe(() => {
      this.isUploadVisible = false;
    });
  }

  public onSelect(event: { files: File[] }): void {
    for (const file of event.files) {
      file.uploadedFileName = file.name;
    }
  }

  public onClear(): void {
    this.isUploadVisible = false;
  }

  public loadFiles(): Observable<any> {
    const params = {
        fileClassName: this.fileClassName,
        owner: this.fileEntity.id,
        context: this.fileContext
      };

    return this.genericCrudService.getEntities('phoenix/files', '', params)
      .pipe(
        map((files: FileModel[]) => {
          this.items = [];

          for (const file of files) {

            this.items.push({
              label: file.name,
              icon: 'pi pi-file',
              command: () => {
                this.onItemDownload({file: file});
              },
              file
            });
          }

          this.cdr.detectChanges();

          return files;
        })
      );
  }
}
