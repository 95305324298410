import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Optional,
  Renderer,
  Injector,
  ChangeDetectionStrategy
} from '@angular/core';
import {SelectItem} from 'primeng/api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-element-input-global-field-period',
  template: `
    <div [hidden]="hidden">
      <input
        type="number"
        [style]="{'width':'80%'}"
        (change)="onChangePeriod()"
        [(ngModel)]="periodValue"
      >
      <p-dropdown
        [options]="periodOptions"
        [autoWidth]="false"
        [autoDisplayFirst]="true"
        (onChange)="onChangePeriod()"
        [(ngModel)]="periodUnit"
        [style]="{'width':'20%'}"
        appendTo="body"
        #inputElement
      ></p-dropdown>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }
  `]
})
export class ElementInputGlobalFieldPeriodComponent implements OnInit, AfterViewInit {
  @Input() period: string;
  @Input() hidden: boolean;
  @Output() onChange = new EventEmitter<any>();

  public periodUnit = 'D';
  public periodValue: number = null;

  public periodOptions: SelectItem[] = [
    { value: 'D', label: 'Days' },
    { value: 'M', label: 'Months' },
    { value: 'Y', label: 'Years' }
  ];

  onComponentDestroy() {

  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    if (this.period) {
      this.periodUnit = this.period.substr(-1);
      this.periodValue = parseInt(this.period.substr(1, this.period.length - 1));
    }
  }

  onChangePeriod() {
    if (this.periodValue !== null) {
      this.onChange.emit('P' + this.periodValue + this.periodUnit);
    } else {
      this.onChange.emit(null);
    }
  }
}
