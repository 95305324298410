import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import { ElementType } from './../../services/element/element-type';
import { Datamodel } from './../../services/datamodel/datamodel';
import { ElementForm } from './../../../shared/services/element-form/element-form.model';
import { Element, ElementAction } from './element';
import { ElementLabelOutputDatamodelText } from './element-label-output-datamodel-text';
import { ElementLabelOutputCollectiveAgreementAkuText } from './element-label-output-collective-agreement-aku-text';
import { ElementButtonDefault } from './element-button-default';
import { ElementButtonModule } from './element-button-module';
import { ElementInputText } from './element-input-text';
import { ElementInputTextarea } from './element-input-textarea';
import { ElementInputSwitch } from './element-input-switch';
import { ElementInputRadio } from './element-input-radio';
import { ElementInputCheckbox } from './element-input-checkbox';
import { ElementInputPassword } from './element-input-password';
import { ElementInputNumber } from './element-input-number';
import { ElementInputDropdown } from './element-input-dropdown';
import { ElementInputDate } from './element-input-date';
import { ElementInputUpload } from './element-input-upload';
import { ElementInputImageUpload } from './element-input-image-upload';
import { ElementLayoutTabs, ElementLayoutTabsItem } from './element-layout-tabs';
import { ElementLayoutColumns, ElementLayoutColumnsItem } from './element-layout-columns';
import { ElementLayoutFieldset } from './element-layout-fieldset';
import { ElementModuleElementGrid } from './element-module-element-grid';
import { ElementPlaceholder } from './element';
import {ElementAssociationDatamodelData} from "./element-association-datamodel-data";
import { ElementInputAutocomplete } from './element-input-autocomplete';
import {ElementLayoutGlobalFields} from "./element-layout-global-fields";
import { ElementInputFileMultiUpload } from 'app/shared/form-viewer/models/element-input-file-multi-upload';
import {HandleCountryMainFaxActionHandler} from '../actions/handlers/address/handle-country-main-fax-action-handler';
import {HandleCountryMainPhoneActionHandler} from '../actions/handlers/address/handle-country-main-phone-action-handler';
import {ElementInputListBox} from './element-input-list-box';
import {HandleCountrySecondaryMobileActionHandler} from '../actions/handlers/address/handle-country-secondary-mobile-action-handler';
import {SetEntityFieldWithSelectedBranchofficeActionHandler} from '../actions/handlers/set-entity-field-with-selected-branchoffice-action-handler';
import {ElementInputLink} from './element-input-link';
import {ElementInputMultiAutocomplete} from './element-input-multi-autocomplete';
import {ElementInputAlgoliaPlaces} from './element-input-algolia-places';
import {ElementInputRegionChooser} from './element-input-region-chooser';
import {ElementInputFileUpload} from './element-input-file-upload';

export class Form extends ElementForm {

  title: string;
  width: string;
  height: string;
  maxWidth: string;
  inputWidth: string;
  inputHeight: string;
  labelPadding: string;
  inputPadding: string;
  align: string;
  labelWidth: string;
  showTitle: boolean;
  elements: Element[];
  actions: FormAction[];
  embedded: string;

  /**
   * Creates an instance of Form.
   * @param {Object} [initForm] Optional object to set class init params
   * @param {string} [initForm.title] Optional default form title
   * @param {string} [initForm.width] Optional default form width
   * @param {string} [initForm.height] Optional default form height
   * @memberof Form
   */
  constructor(initForm?: Object, createForSave?: boolean) {
    super();

    this.title = undefined;
    // this.width = undefined;
    this.width = '100%';
    // this.height = undefined;
    this.height = '100%';
    this.maxWidth = undefined;
    this.labelPadding = undefined;
    this.inputPadding = undefined;
    // this.align = undefined;
    this.align = 'left';
    this.labelWidth = undefined;
    // this.showTitle = undefined;
    this.showTitle = true;
    // this.elements = undefined;
    this.elements = [];
    this.actions = [];

    if (initForm && typeof (initForm) === 'object') {
      if (typeof createForSave === 'undefined') {
        createForSave = false;
      }
      this.setValuesFromObject(initForm, createForSave);
    }
  }

  public setValuesFromObject(object: Object, createForSave?: boolean): Form {
    for (const key in object) {
      if (object.hasOwnProperty(key) && typeof object[key] !== 'function') {
        // @todo Extract datamodel... take a look for a nicier way
        if (key === '_embedded') {
          if (object[key] && object[key]['datamodel'] && +object[key]['datamodel']['id']) {
            this.datamodel = {
              id: +object[key]['datamodel']['id'],
              name: object[key]['datamodel']['name'],
              apiRoute: object[key]['datamodel']['apiRoute'],
              fullApiRoute: object[key]['datamodel']['fullApiRoute'],
              entityCollectionName: object[key]['datamodel']['entityCollectionName'],
              isIndependent: object[key]['datamodel']['isIndependent']
            };
            this.datamodelId = this.datamodel.id;
          }
          if (object[key] && object[key]['elementType'] && +object[key]['elementType']['id']) {
            this.elementType = {
              id: +object[key]['elementType']['id'],
              name: object[key]['elementType']['name'],
              value: object[key]['elementType']['value'],
              label: object[key]['elementType']['label']
            };
            this.elementTypeId = this.elementType.id;
          }
        }

        if (this.hasOwnProperty(key)) {
          if (key === 'elements' || key === '_elements') {
            const createInstances = (element, parent) => {
              const type = element.type || element._type;
              const elementType = element.typeElement || element._typeElement;
              let elementChildren = element['children'] || element['_children'];

              if (type === 'button' && elementType === 'default') {
                element = new ElementButtonDefault().setValuesFromObject(element);
              }
              if (type === 'button' && elementType === 'module') {
                element = new ElementButtonModule().setValuesFromObject(element);
              }
              if (elementType === 'text') {
                element = new ElementInputText().setValuesFromObject(element);
              }
              if (elementType === 'date') {
                element = new ElementInputDate().setValuesFromObject(element);
              }
              if (elementType === 'dropdown') {
                element = new ElementInputDropdown().setValuesFromObject(element);
              }
              if (elementType === 'number') {
                element = new ElementInputNumber().setValuesFromObject(element);
              }
              if (elementType === 'password') {
                element = new ElementInputPassword().setValuesFromObject(element);
              }
              if (elementType === 'switch') {
                element = new ElementInputSwitch().setValuesFromObject(element);
              }
              if (elementType === 'radio') {
                element = new ElementInputRadio().setValuesFromObject(element);
              }
              if (elementType === 'checkbox') {
                element = new ElementInputCheckbox().setValuesFromObject(element);
              }
              if (elementType === 'textarea') {
                element = new ElementInputTextarea().setValuesFromObject(element);
              }
              if (elementType === 'upload') {
                element = new ElementInputUpload().setValuesFromObject(element);
              }
              if (elementType === 'image-upload') {
                element = new ElementInputImageUpload().setValuesFromObject(element);
              }
              if (elementType === 'file-upload') {
                element = new ElementInputFileUpload().setValuesFromObject(element);
              }
              if (elementType === 'file-multi-upload') {
                element = new ElementInputFileMultiUpload().setValuesFromObject(element);
              }
              if (elementType === 'fieldset') {
                element = new ElementLayoutFieldset().setValuesFromObject(element);
              }
              if (elementType === 'globalFields') {
                element = new ElementLayoutGlobalFields().setValuesFromObject(element);
              }
              if (elementType === 'columns') {
                element = new ElementLayoutColumns().setValuesFromObject(element);
              }
              if (elementType === 'columns-item') {
                element = new ElementLayoutColumnsItem().setValuesFromObject(element);
              }
              if (elementType === 'tabs') {
                element = new ElementLayoutTabs().setValuesFromObject(element);
              }
              if (elementType === 'tabs-item') {
                element = new ElementLayoutTabsItem().setValuesFromObject(element);
              }
              if (elementType === 'placeholder') {
                element = new ElementPlaceholder().setValuesFromObject(element);
              }
              if (elementType === 'grid') {
                element = new ElementModuleElementGrid().setValuesFromObject(element);
              }
              if (elementType === 'association-data') {
                element = new ElementAssociationDatamodelData().setValuesFromObject(element);
              }
              if (elementType === 'datamodel-text') {
                element = new ElementLabelOutputDatamodelText().setValuesFromObject(element);
              }
              if (elementType === 'collective-agreement-aku-text') {
                element = new ElementLabelOutputCollectiveAgreementAkuText().setValuesFromObject(element);
              }
              if (elementType === 'autocomplete') {
                element = new ElementInputAutocomplete().setValuesFromObject(element);
              }
              if (elementType === 'multi-autocomplete') {
                element = new ElementInputMultiAutocomplete().setValuesFromObject(element);
              }
              if (elementType === 'region-chooser') {
                element = new ElementInputRegionChooser().setValuesFromObject(element);
              }
              if (elementType === 'algoliaPlaces') {
                element = new ElementInputAlgoliaPlaces().setValuesFromObject(element);
              }
              if (elementType === 'listBox') {
                element = new ElementInputListBox().setValuesFromObject(element);
              }
              if (elementType === 'link') {
                element = new ElementInputLink().setValuesFromObject(element);
              }

              if (elementChildren) {
                elementChildren = elementChildren.map(elementChild => {
                  elementChild = createInstances(elementChild, element);
                  return elementChild;
                });

                element.children = elementChildren;
              }

              if (parent) {
                element.parent = parent;
              }
              return element;
            };

            object[key] = object[key].map(element => {
              element = createInstances(element, undefined);
              return element;
            });
          }

            if (key === 'actions') {
                if (object[key].length > 0) {
                    object[key].map((obj) => {
                        if (obj.hasOwnProperty('_links')) {
                            delete obj._links;
                        }
                        if (obj.hasOwnProperty('fqn')) {
                            delete obj.fqn;
                        }
                        if (obj.hasOwnProperty('params') && typeof (obj.params) === 'string') {
                            let paramsObj;
                            try {
                                paramsObj = JSON.parse(obj.params);
                            } catch (error) {
                                paramsObj = undefined;
                            }
                            obj.params = paramsObj;
                        }

                        if (obj.hasOwnProperty('conditions') && obj.conditions instanceof Array) {
                          for (let condition of obj.conditions) {
                            if (condition.params && typeof (condition.params) === 'string') {
                              condition.params = JSON.parse(condition.params);
                            }
                          }
                        }
                    });
                }
            }

          if (typeof (this[key.replace('_', '')]) === 'boolean' && typeof (object[key]) === 'number') {
            object[key] = object[key] === 1 ? true : false;
          }

          this[key.replace('_', '')] = object[key];
        }
      }
    }

    if (!this.title && object['name']) {
      this.title = object['name'];
    }
    if (!this.title && object['label']) {
      this.title = object['label'];
    }
    if (!this.name && this.title) {
      this.name = this.title;
    }
    if (!this.label && this.title) {
      this.label = this.title;
    }

    if (createForSave) {
        this.setupProperties(object);
    } else {
        let x;
        try {
            x = JSON.parse(object['content']);
            this.content = JSON.stringify(x);
            this.showTitle = x.showTitle;
            this.width = x.width;
            this.height = x.height;
            this.maxWidth = x.maxWidth;
            this.labelWidth = x.labelWidth;
            this.inputWidth = x.inputWidth;
            this.labelPadding = x.labelPadding;
            this.inputPadding = x.inputPadding;
            this.inputHeight = x.inputHeight;
        } catch (e) {
            x = {
                showTitle: this.showTitle,
                width: this.width,
                height: this.height,
                maxWidth: this.maxWidth,
                labelWidth: this.labelWidth,
                inputWidth: this.inputWidth,
                labelPadding: this.labelPadding,
                inputPadding: this.inputPadding,
                inputHeight: this.inputHeight
            };
            this.content = JSON.stringify(x);
        }
    }

    return this;
  }

  private setupProperties(object) {
      if ('showTitle' in object) {
          this.showTitle = object['showTitle'];
      }
      if ('width' in object) {
          this.width = object['width'];
      }
      if ('height' in object) {
          this.height = object['height'];
      }
      if ('maxWidth' in object) {
          this.maxWidth = object['maxWidth'];
      }
      if ('labelWidth' in object) {
          this.labelWidth = object['labelWidth'];
      }
      if ('inputWidth' in object) {
          this.inputWidth = object['inputWidth'];
      }
      if ('inputHeight' in object) {
        this.inputHeight = object['inputHeight'];
      }
      if ('labelPadding' in object) {
          this.labelPadding = object['labelPadding'];
      }
      if ('inputPadding' in object) {
          this.inputPadding = object['inputPadding'];
      }
  }

  public clearObjectForApi(): Form {
    const clearObject = (element: Element) => {
      if (element['children']) {
        element['children'] = element['children'].map(elementChildren => {
          elementChildren = clearObject(elementChildren);
          return elementChildren;
        });
      }

      element = element.prepareForApi();

      return element;
    };

    this.elements = this.elements.map(element => clearObject(element));

    if (+this.id === 0) {
      this.id = '';
      this.elementType = new ElementType(3);
      this.elementTypeId = this.elementType.id;
    }

    const x = {
      showTitle: this.showTitle,
      width: this.width,
      height: this.height,
      maxWidth: this.maxWidth,
      labelWidth: this.labelWidth,
      inputWidth: this.inputWidth,
      labelPadding: this.labelPadding,
      inputPadding: this.inputPadding,
      inputHeight: this.inputHeight
    };
    this.content = JSON.stringify(x);

    return this;
  }

  public isNew() {
    if (+this.id === 0) {
      return true;
    } else {
      return false;
    }
  }

  public isDraft() {
    if (+this.id <= 0) {
      return true;
    } else {
      return false;
    }
  }
}

export class FormActions {
  public static readonly FORM_COLOR_FROM_DATAMODEL_SOURCE: string = 'FormActionFormColorFromDatamodelSource';
  public static readonly FOCUS_INPUT_ELEMENT: string = 'FormActionFocusInputElement';
  public static readonly SET_FIELD_VALUE_FROM_FIELD_DATAMODEL: string = 'FormActionSetFieldValueFromFieldDatamodel';
  public static readonly SET_ENTITY_FIELD_WITH_SELECTED_BRANCHOFFICE: string = 'FormActionSetEntityFieldWithSelectedBranchofficeActionHandler';
  public static readonly SET_FIELD_VALUE_FROM_FIELD_OPTIONS: string = 'FormActionSetFieldValueFromFieldOptions';
  public static readonly REMOVE_FIELD_VALUE: string = 'FormActionRemoveFieldValue';
  public static readonly CHECK_FIELDS_MESSAGE: string = 'FormActionCheckFieldsMessage';
  public static readonly HANDLE_COUNTRY_PHONE: string = 'FormActionHandleCountryPhone';
  public static readonly HANDLE_COUNTRY_FAX: string = 'FormActionHandleCountryFax';
  public static readonly HANDLE_MAIN_COUNTRY_PHONE: string = 'HandleCountryMainPhoneActionHandler';
  public static readonly HANDLE_MAIN_COUNTRY_FAX: string = 'HandleCountryMainFaxActionHandler';
  public static readonly HANDLE_COUNTRY_MOBILE: string = 'HandleCountryMobileActionHandler';
  public static readonly HANDLE_COUNTRY_SECONDARY_MOBILE: string = 'HandleCountrySecondaryMobileActionHandler';
  public static readonly CALCULATE_REFERENCE_ASSOCIATION_FACTOR: string = 'CalculateReferenceAssociationFactor';
  public static readonly HANDLE_GENERIC_PHONE: string = 'HandleGenericPhoneActionHandler';
  public static readonly HANDLE_ALGOLIA_PLACE_CHANGE = 'HandleAlgoliaPlaceChangeActionHandler';
  public static readonly HANDLE_ALGOLIA_PLACE_ENTITY_CHANGED = 'HandleAlgoliaPlaceEntityChangedActionHandler';
  public static readonly DUPLICATE_ENTITY_CHECK = 'DuplicateEntityCheckFormActionHandler';

  public static readonly LOAD_ENTITY_FROM_API: string = 'FormActionLoadEntityFromApi';
  public static readonly LOCK_FIELDS: string = 'FormActionLockFields';

  public static readonly SET_ID_URL = 'SetIdToUrlActionHandler';

  public static readonly HANDLE_PHONE_COUNTRY_CODE_CONTACT_PERSON: string = 'FormActionHandlePhoneCountryCodeContactPerson';
  public static readonly HANDLE_SECONDARY_PHONE_COUNTRY_CODE_CONTACT_PERSON: string = 'FormActionHandleSecondaryPhoneCountryCodeContactPerson';
  public static readonly HANDLE_FAX_COUNTRY_CODE_CONTACT_PERSON: string = 'FormActionHandleFaxCountryCodeContactPerson';
  public static readonly HANDLE_MOBILE_COUNTRY_CODE_CONTACT_PERSON: string = 'FormActionHandleMobileCountryCodeContactPerson';
  public static readonly HANDLE_SECONDARY_MOBILE_COUNTRY_CODE_CONTACT_PERSON: string = 'FormActionHandleSecondaryMobileCountryCodeContactPerson';
  public static readonly HANDLE_PRECALCULATION_REFERENCE_ASSOCIATION_TYPE_SECTION: string = 'FormActionHandlePrecalculationReferenceAssociationTypeSection';
  public static readonly HANDLE_LEASED_EMPLOYEE_DRIVING_LICENCE: string = 'FormActionHandleLeasedEmployeeDrivingLicence';
  public static readonly HANDLE_CONTRACT_OF_EMPLOYMENT = 'HandleContractOfEmploymentFormActionHandler';

  public static readonly SETUP_PHONE_PROJECT_FORM: string = 'FormActionSetupPhoneProjectForm';
  public static readonly SETUP_SUB_ASSIGNMENT_FORM: string = 'FormActionSetupSubAssignmentForm';
  public static readonly SETUP_ADVANCE_FORM: string = 'FormActionSetupAdvanceFormActionHandler';
  public static readonly SETUP_ADDRESS_FORM: string = 'HandleAddressSelectionActionHandler';
  public static readonly SETUP_MEMO_FORM: string = 'FormActionSetupMemoForm';
  public static readonly SETUP_WORKPLACE_FORM: string = 'FormActionSetupWorkplaceForm';

  public static readonly SETUP_INVOICE_FORM: string = 'FormActionSetupInvoiceForm';
  public static readonly SETUP_INVOICE_POSSIBILITY_FORM = 'FormActionSetupInvoicePossibilityForm';

  public static readonly SETUP_CONTACT_PERSON_FORM = 'FormActionSetupContactPersonForm';

  public static readonly SHOW_FIELD: string = 'FormActionShowField';
  public static readonly HIDE_FIELD: string = 'FormActionHideField';
}

export class FormActionConditions {
  public static readonly IS_FORM_VALUE_EMPTY: string = 'IsFormValueEmpty';
  public static readonly IS_FORM_VALUE_NOT_EMPTY: string = 'IsFormValueNotEmpty';
  public static readonly IS_CHANGING_ELEMENT: string = 'IsChangingElement';
  public static readonly IS_NOT_CHANGING_ELEMENT: string = 'IsNotChangingElement';
  public static readonly IS_ELEMENT_VALUE_EQUAL: string = 'IsElementValueEqual';
  public static readonly IS_ELEMENT_VALUE_NOT_EQUAL: string = 'IsElementValueNotEqual';
  public static readonly IS_ENTITY_VALUE_EQUAL: string = 'IsEntityValueEqual';
  public static readonly IS_ENTITY_VALUE_NOT_EQUAL: string = 'IsEntityValueNotEqual';
  public static readonly IS_ENTITY_NEW: string = 'IsEntityNew';
  public static readonly IS_EXISTING_ENTITY: string = 'IsExistingEntity';
}

export class FormActionsEvents {
  public static readonly ON_INIT: string = 'onInit';
  public static readonly ON_ENTITY_CHANGED: string = 'onEntityChanged';
  public static readonly ON_DATAMODEL_LOAD: string = 'onDatamodelLoad';
  public static readonly ON_VALUE_CHANGED: string = 'onValueChanged';
  public static readonly ON_RUNTIME_VALUE_CHANGED: string = 'onRuntimeValueChanged';
}

export class FormAction implements App.Form.Element.Action {

    label: string;
    command: string;
    className: string;
    params: Object;
    conditions: FormActionCondition[];
    additionalData: any;

    constructor(
    ) {
        this.label = undefined;
        this.command = undefined;
        this.className = undefined;
        this.params = undefined;
        this.conditions = [];
        this.additionalData = {};
    }
}

export class FormActionConditionParam {
  key: string;
  value: any;
}

export class FormActionCondition {

  label: string;
  className: string;
  params: FormActionConditionParam[];

  constructor(
  ) {
      this.label = undefined;
      this.className = undefined;
      this.params = undefined;
  }
}

export class FormElementAction extends ElementAction {

}
