/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "../../generate-hash.pipe";
import * as i4 from "./element-input-image-upload.component";
import * as i5 from "../form.service";
import * as i6 from "../form-viewer.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@ngx-translate/core";
var styles_ElementInputImageUploadComponent = ["[_nghost-%COMP%] {\n      height: 100%;\n    }\n\n      .form-element-input-image-upload {\n      height: 100%;\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      flex-wrap: nowrap;\n      justify-content: center;\n      align-content: stretch;\n      align-items: center;\n      border-radius: 3px;\n    }\n\n      .form-element-input-image-upload .simple-upload {\n        text-align: center;\n    }\n\n      .form-element-input-image-preview {\n      position: relative;\n      border: 1px solid #a8a8a8;\n      border-radius: 3px;\n    }\n\n      .form-element-input-image-preview .image-action {\n        position: absolute;\n        cursor: pointer;\n        text-align: center;\n        font-size: .9em;\n        background: rgba(255, 255, 255, .5);\n        padding: .15rem 0;\n        bottom: 0;\n        width: 100%;\n        border-bottom-left-radius: 3px;\n        border-bottom-right-radius: 3px;\n      }\n\n        .form-element-input-image-preview .image-action:hover {\n        background: rgba(255, 255, 255, 1);\n      }\n    }"];
var RenderType_ElementInputImageUploadComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputImageUploadComponent, data: {} });
export { RenderType_ElementInputImageUploadComponent as RenderType_ElementInputImageUploadComponent };
function View_ElementInputImageUploadComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵppd(1, 1), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "fa fa-fw fa-upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "upload-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Bild hochladen"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 0), _co.hashIdForUpload)), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ElementInputImageUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "form-element-input-image-upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "simple-upload"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "input", [["type", "file"]], [[8, "readOnly", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(6, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputImageUploadComponent_2)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 2, 0, currVal_7); var currVal_10 = !_co.isReadOnly(); _ck(_v, 8, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.isReadOnly(); var currVal_9 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 5, 1, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), _co.hashIdForUpload)), ""); _ck(_v, 5, 0, currVal_8, currVal_9); }); }
function View_ElementInputImageUploadComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "image-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" entfernen "]))], null, null); }
function View_ElementInputImageUploadComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "form-element-input-image-preview"], ["style", "width: 100px; height: 120px; display: grid; position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Bild Vorschau"], ["style", "max-height: 100%; max-width: 100%;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputImageUploadComponent_4)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isReadOnly(); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSrc; _ck(_v, 1, 0, currVal_0); }); }
export function View_ElementInputImageUploadComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.GenerateHashPipe, []), i0.ɵqud(671088640, 1, { inputElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputImageUploadComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputImageUploadComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.imageSrc; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.imageSrc; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ElementInputImageUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-image-upload", [], null, null, null, View_ElementInputImageUploadComponent_0, RenderType_ElementInputImageUploadComponent)), i0.ɵdid(1, 6012928, null, 1, i4.ElementInputImageUploadComponent, [i5.FormService, i0.ChangeDetectorRef, i6.FormViewerService, i7.DomSanitizer, i8.TranslateService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputImageUploadComponentNgFactory = i0.ɵccf("app-form-element-input-image-upload", i4.ElementInputImageUploadComponent, View_ElementInputImageUploadComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputImageUploadComponentNgFactory as ElementInputImageUploadComponentNgFactory };
