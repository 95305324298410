import {Component, Input, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy} from '@angular/core';

import {ComponentSelectedOptionAware} from './abstract-element.component';
import {ElementInputListBox} from '../models/element-input-list-box';
import {ElementInputDropdownComponent, SelectItemTmpIsDeleted} from './element-input-dropdown.component';
import {Entity} from '../../helpers/entity';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-list-box',
  template: `
    <div [formGroup]="formGroup" class="ui-g ui-g-12">

      <p-listbox
        appSeleniumDirective
        [element]="element"
        [options]="pulldownOptions"
        [formControlName]="formControlName"
        multiple="multiple"
        checkbox="checkbox"
        filter="filter"
        (onChange)="onChange($event)"
      >

      </p-listbox>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
      width: 100%;
    }

    .ui-g-12 {
      height: 100%;
    }

    p-listbox {
      width: inherit;
    }

    ::ng-deep .ui-listbox {
      height: 100%;
      position: relative;
    }

    ::ng-deep .ui-listbox-list-wrapper {
      height: calc(100% - 25px);
    }
  `]
})
export class ElementInputListBoxComponent extends ElementInputDropdownComponent implements
  OnInit, OnDestroy, AfterViewInit, ComponentSelectedOptionAware {

  @Input() element: ElementInputListBox;

  public onComponentInit(): void {
    this.setFormControlName();
    this.loadDropdownOptions();
    this.filterActionAndHandleIt('oninit');
  }

  public onComponentDestroy() { }

  public setupValue(): void {
    this.setupValueFromEntity();
  }

  public setValue(value: any, triggerChange: boolean = true): this {

    return this;
  }

  public onChange(event): void {
    this.setupValueFromChange(event.value);
  }

  public onComponentChanges() {
    this.setupValueFromEntity();
  }

  public setupValueFromChange(selected: SelectItemTmpIsDeleted[] = []): void {
    const selectedOptions = this.getSelectedOption(selected);

    const formControlValue = [],
      entityValue = [];

    for (const selectedOption of selectedOptions) {
      formControlValue.push({
        id: selectedOption.value.id
      });
      entityValue.push(selectedOption.entity);
    }

    this.doTriggerElementValueChange(formControlValue, entityValue, true);
  }

  public setupValueFromEntity(): void {
    const entityEmbeddedValues = this.getEntityValue();

    const formControlValue = [],
      entityValue = [];

    if (entityEmbeddedValues instanceof Array) {
      for (const entity of entityEmbeddedValues) {
        formControlValue.push({
          id: entity.id
        });
        entityValue.push(entity);
      }
    }

    this.doTriggerElementValueChange(formControlValue, entityValue, false);
  }

  public doTriggerElementValueChange(formControlValue: any[] = [], entityValue: any[] = [], triggerChange: boolean = true): void {
    this.formService.onFormElementValueChange({
      formControlValue: formControlValue,
      element: this.element,
      entityValue: entityValue,
      formControlName: this.formControlName,
      formControlOptions: {},
      triggerChange: triggerChange,
      entity: this.entity,
      component: this,
      updateFormComponent: true
    });
  }

  public getSelectedOption(selectedOptions: any[] = []) {
    this.selectedOption = [];

    for (const value of selectedOptions) {
      const optionValue = this.pulldownOptions.find((option) => {
        if (option.entity && value.entity) {
          return option.entity.id === value.entity.id;
        } else if (option.value && option.value.id && value.id) {
          return option.value.id === value.id;
        } else {
          return option.value === value.value;
        }
      });

      if (optionValue) {
        this.selectedOption.push(optionValue);
      }
    }

    return this.selectedOption;
  }

  protected getEntityValue(useEmbedded: boolean = true): any | null {
    return Entity.getValue(this.entity, this.element.datamodelField) ||
      Entity.getValueInEmbedded(this.entity, this.element.datamodelField);
  }
}
