import {ChangeDetectionStrategy, Component} from '@angular/core';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ToolbarItemOpenModule} from '../toolbar-item-open-module.component';
import { cloneDeep } from 'lodash';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-open-module-selected-skills-of-leased-employee',
  template: ''
})
export class ToolbarItemOpenModuleSelectedSkillsOfLeasedEmployeeComponent extends ToolbarItemOpenModule {

  protected doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();

    this.entity = cloneDeep(gridComponent.emptyEntity);

    if (!this.validateInGridContext(gridComponent)) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }
    let masterEntity = {skills:[]};
    for(let entity of gridComponent.entities){
      masterEntity.skills.push(entity['skill']);
    }

    this.doubleClickService.setGrid(gridComponent)
      .setDialogOptions({
        height: +this.getToolbarItem().targetDialogHeight,
        width: +this.getToolbarItem().targetDialogWidth
      })
      .handleOpenModule(gridComponent, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, this.entity, masterEntity, 'skills');
  }

}
