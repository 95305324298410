import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import { Datamodel } from '../../services/datamodel/datamodel';
import {ElementInputFileUpload} from '../models/element-input-file-upload';
import {ElementInputFileMultiUploadComponent} from './element-input-file-multi-upload.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-file-upload',
  template: `
        <div class="ui-g-12">
          <app-multi-upload-button
            #uploadButton
            [mode]="'advanced'"
            [disabled]="disabled"
            [filePath]="element.filePath"
            [fileContext]="element.fileContext"
            [fileLimit]="1"
          ></app-multi-upload-button>
        </div>
    `,
    styles: [`
        :host {
          height: 100%;
        }
    `]
})
export class ElementInputFileUploadComponent extends ElementInputFileMultiUploadComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() element: ElementInputFileUpload;
  @ViewChild('uploadButton', {static: false}) uploadButton;
}
