import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Optional,
  Renderer,
  Injector,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';

import { environment } from './../../../../environments/environment';

import { ElementLabelComponent } from './element-label.component';
import { ElementLabelOutputDatamodelText } from '../models/index';

import { FormService } from './../form.service';
import { FormViewerService } from './../form-viewer.service';
import {Constants} from '../../../constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-label-output-datamodel-text',
  template: `
    <div fxLayout="row">
        <div fxFlex="80"
           appSeleniumDirective
           [element]="element"
        >
            {{getTemplateText()}}
        </div>
        <div fxFlex="20" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
            <img height="16" width="16"
              appSeleniumDirective
              [element]="element"
              [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
              [alt]="element?.icon?.name"
              (click)="onClickIcon()"
            />
        </div>
    </div>
    <span class="hidden-content"></span>
  `,
  styles: [`
    :host {
      width: 100%;
      padding: .334rem 0;
    }
  `]
})
export class ElementLabelOutputDatamodelTextComponent extends ElementLabelComponent implements OnInit, AfterViewInit {
  @Input() element: ElementLabelOutputDatamodelText;

    iconBaseUrl: string = environment.baseUrl;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  onComponentDestroy() {

  }

  ngAfterViewInit() {
  }

    onClickIcon() {
        this.filterActionAndHandleIt('onclickicon');
    }

  getTemplateText(): string {
      let templateText = this.element.templateText;
      const regex = /\[\[([^\[\]]+)\]\]/g;

      if (templateText) {
        templateText.match(regex).map((placeHolder: string) => {
          const entityKey = placeHolder.replace(/[\[\]]*/g, '');

          if (entityKey && !this.element.isCollectiveAgreementFromLocalStorage) {
              let replaceWith = '';
              const findEl = this.getElementByDatamodelField(entityKey);

              if (findEl) {
                  const fieldName = findEl['datamodelField'] && findEl['datamodelField'].replace(/\./g, '-p-') || '';
                  const elObjectId: string = findEl.objectHashId;
                  let formControlName: string;

                  if (this.formGroup && fieldName) {
                      formControlName = `${fieldName}_h_r_f_e_${elObjectId}`;
                  }

                  if (this.formGroup && this.formGroup.get(formControlName) && this.formGroup.get(formControlName).value !== null) {
                      replaceWith = this.formGroup.get(formControlName).value;
                  }
              }

              if (this.entity && entityKey.search('/\.\/g') !== 0) {
                  const formControlNames = entityKey.split('.');
                  let entityValue: string;

                  formControlNames.map((controlName) => {
                      if (this.entity['_embedded'] && this.entity['_embedded'][controlName] && entityValue === undefined) {
                          entityValue = this.entity['_embedded'][controlName];
                      }

                      if (
                          entityValue !== undefined &&
                          entityValue[controlName] === undefined &&
                          entityValue['_embedded'] !== undefined &&
                          entityValue['_embedded'][controlName] !== undefined
                      ) {
                          entityValue = entityValue['_embedded'][controlName];
                      }

                      if (entityValue !== undefined && entityValue[controlName] !== undefined) {
                          entityValue = entityValue[controlName];
                      }
                  });

                  if (typeof (entityValue) === 'object') {
                      entityValue = undefined;
                  }

                  replaceWith = entityValue || '';

                  if (this.entity[entityKey] && replaceWith === '') {
                      replaceWith = this.entity[entityKey];
                  }
              }

              templateText = templateText.replace(`[[${entityKey}]]`, replaceWith);

          }else if(entityKey && this.element.isCollectiveAgreementFromLocalStorage){
            let collectiveAgreement;
            if(entityKey == Constants.COLLECTIVE_AGREEMENT_EMPLOYEES){
              collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES);
            }else if(entityKey == Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER){
              collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER);
            }else{
              collectiveAgreement = null;
            }
            if(typeof (collectiveAgreement) === 'object'){
              collectiveAgreement = collectiveAgreement['name'];
            }
            templateText = templateText.replace(`[[${entityKey}]]`, collectiveAgreement);
          }
      });
    }

    return templateText;
  }
}
